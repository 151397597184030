import styled from '@emotion/styled';
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';

export const NavBarContainer = styled.div`
  width: 100%;
  display: 'flex';
  align-items: center;
  @media (max-width: 1000px) {
    .menu-hidden {
      display: none;
    }
  }
`

export const NavBarHeader = styled.div`
  padding: 0 ${tokens.spacing.xs};
  display: none;
  @media (max-width: 1000px) {
    display: flex;
  }
`

export const NavBarHeaderContent = styled.div`
  flex-grow: 1;
  display: flex;
`

export const NavBarMenu = styled.div`
  & > ul {
    display: flex;
    padding: 0;
    @media (max-width: 1000px) {
      flex-direction: column;
      background-color: white;
      border-top: 1px solid black;
    }
  };
  & > ul > li {
    list-style-type: none;
  }
  .expanded {
    display: flex;
  }
`

export const DropdownMenu = styled.div`
  & > ul {
    display: flex;
    flex-direction: column;
    padding: 10px;
  };
  & > ul > li {
    list-style-type: none;
  }
  .expanded {
    display: flex;
  }
`

export const SelectedNavItemWrapper = styled.div`
  color: ${tokens.colors.blackBase};
  border-bottom: 4px solid ${tokens.colors.brandBase};
  line-height: 2.5;
  margin-right: ${tokens.spacing.l};
  text-decoration: none;
  white-space: nowrap;
`

export const NavListItem = styled.li`
  & > a {
    color: ${tokens.colors.blackBase};
    line-height: 2.5;
    padding: ${tokens.spacing.xxs} 0;
    margin-right: ${tokens.spacing.l};
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    @media (max-width: 1000px) {
      padding-left: ${tokens.spacing.xxs};
      margin-left: 4px;
    }
  }
  & > .match {
    border-bottom: 4px solid ${tokens.colors.brandBase};
    @media (max-width: 1000px) {
      border-bottom: none;
      border-left: 4px solid ${tokens.colors.brandBase};
      margin-left: 0px;
    }
  }
`

export const DropdownItem = styled.li`
& > a {
  color: ${tokens.colors.blackBase};
  line-height: 2.5;
  padding: ${tokens.spacing.xxs} 0;
  // margin-right: ${tokens.spacing.l};
  // margin-left: ${tokens.spacing.l};
  padding-left: ${tokens.spacing.xxs};
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}
& > .match {
  border-left: 4px solid ${tokens.colors.brandBase};
  margin-left: 0px;
}
`