import React, { Dispatch, SetStateAction } from "react";
import Modal from "react-modal";
import { Button, Heading, suomifiDesignTokens as tokens } from "suomifi-ui-components";
import {
  cloneDocument,
  ensureElementAndUpdate,
  queryFirstText,
  updateElement
} from "../../utils/xmlUtils";
import {FlexColTight} from "../common/CommonStyles";
import {Input} from "../common/StyledInputComponents";

interface User {
  username: string,
  firstName?: string,
  lastName?: string,
  superuser?: boolean
}


interface Props {
  isOpen: boolean,
  user: Document,
  setUser: Dispatch<SetStateAction<Document>>,
  onClickSave: () => void,
  onClickCancel: () => void,
}

const EditUserModal: React.FC<Props> = ({isOpen, user, setUser, onClickSave, onClickCancel}) => {
  const element = user.documentElement;

  const username = queryFirstText(element, "username");
  const password = queryFirstText(element, "password");
  const firstName = queryFirstText(element, "firstName");
  const lastName = queryFirstText(element, "lastName");

  function updateUsername(newValue: string) {
    setUser((prevUser) => updateElement(
        cloneDocument(prevUser), "/user/username", (el) => el.textContent = newValue)
    );
  }

  function updatePassword(newValue: string) {
    setUser((prevUser) => ensureElementAndUpdate(
        cloneDocument(prevUser), "/user", "password", ["firstName", "lastName", "properties"],
        (el) => el.textContent = newValue)
    );
  }

  function updateFirstName(newValue: string) {
    setUser((prevUser) => updateElement(
        cloneDocument(prevUser), "/user/firstName", (el) => el.textContent = newValue)
    );
  }

  function updateLastName(newValue: string) {
    setUser((prevUser) => updateElement(
        cloneDocument(prevUser), "/user/lastName", (el) => el.textContent = newValue)
    );
  }

  return (
      <Modal isOpen={isOpen} contentLabel="Lisää uusi käyttäjä" style={{
        content: {
          height: "80%",
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: 800,
          padding: tokens.spacing.l,
        }
      }}>
        <FlexColTight style={{height: "100%"}}>
          <Heading variant="h1">
            Käyttäjä
          </Heading>

          <hr/>

          <label>
            Etunimi
            <Input value={firstName} onChange={(e) => updateFirstName(e.currentTarget.value)}/>
          </label>

          <label>
            Sukunimi
            <Input value={lastName} onChange={(e) => updateLastName(e.currentTarget.value)}/>
          </label>

          <label>
            Käyttäjätunnus
            <Input value={username} onChange={(e) => updateUsername(e.currentTarget.value)}/>
          </label>

          <label>
            Salasana
            <Input type="password"
                   value={password}
                   onChange={(e) => updatePassword(e.currentTarget.value)}/>
          </label>

          <div style={{marginTop: "auto"}}>
            <Button icon={"save"}
                    onClick={onClickSave}
                    style={{marginRight: tokens.spacing.s}}>
              Tallenna
            </Button>
            <Button variant="secondaryNoBorder"
                icon={"close"}
                onClick={onClickCancel}>
              Peruuta
            </Button>
          </div>

        </FlexColTight>
      </Modal>
  );
};

export default EditUserModal;
