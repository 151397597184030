import React from "react";
import { Message } from "../../common/DomainTypes";
import { FlexCol, FlexRow, TableSmall } from "../../common/CommonStyles";
import { DeathReport } from "../../forms/reportDeath/model/ReportDeathFormModel";
import { toDateString } from "../../../utils/dateUtils";

type Props = {
  msg: Message
}

const DeathReportEvent: React.FC<Props> = ({msg}: Props) => {
  const data = msg.source === "EventGrid" ? msg.jsonData.Data as DeathReport : msg.jsonData as DeathReport;
  return (
    <FlexCol>
      <FlexRow css={{
        "& > *": {
          boxSizing: "border-box",
          minHeight: "300px"
        }
      }}>
        <TableSmall>
          <tbody>
            <tr>
              <th>Nimi</th>
              <td className="right">{data.Person.givenName} {data.Person.familyName}</td>
            </tr>
            <tr>
              <th>Henkilötunnus</th>
              <td className="right">{data.Person.personalIdentityCode}</td>
            </tr>
            <tr>
              <th>Kuolinaika</th>
              <td className="right">
                {toDateString(data.Person.dateOfDeath)}
                {data.ChangeEvent.kuolinaikaarvioitu ? " (arvio)" : ""}
              </td>
            </tr>
            <tr>
              <th>Lisätiedot</th>
              <td className="right">{data.ChangeEvent.description}</td>
            </tr>
          </tbody>
        </TableSmall>
      </FlexRow>
    </FlexCol>
  );
}

export default DeathReportEvent;