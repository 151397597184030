import React from "react";
import { TextField, Typography, Select, MenuItem, FormControl, FormHelperText } from "@mui/material";
import {Button, suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { FlexCol, FlexRow } from "../common/CommonStyles";
import Card from "../common/Card";
import { FormikProps } from "formik";
import * as Yup from "yup";
import GenericForm from "./GenericForm";
import { sendGenericForm } from "../../api/omaelama-functions";
import { GenericEventPayload, GenericFormType, NotifierType, Notifier, GenericFormTypeTranslations, NotifierTypeTranslations, CustomizedGenericFormTypes } from "./GenericFormTypes";

// Edit form specific data model
interface FormModel {
  eventType?: GenericFormType | "";
  customEvent?: string;
  firstName?: string;
  lastName?: string;
  personalIdentityCode?: string;

  eventTime?: Date;
  description?: string;
  tapahtumatunnus?: string;
  viranomaiskoodi?: string;
  arkistonumero?: string;

  notifierId?: string;
  notifierType?: NotifierType | "";
}

// Update default values to meet FormModel
const defaultValues: FormModel = {
  eventType: "",
  customEvent: "",
  firstName: "",
  lastName: "",
  personalIdentityCode: "",

  eventTime: undefined,
  description: "",
  tapahtumatunnus: "",
  viranomaiskoodi: "",
  arkistonumero: "",

  notifierId: "",
  notifierType: ""
}

// Update validationSchema to meet FormModel
const validationSchema: Yup.ObjectSchema<FormModel> = Yup.object({
  eventType: Yup.mixed<GenericFormType>()
    .oneOf(Object.values(GenericFormType))
    .required("Pakollinen tieto"),
  customEvent: Yup.string()
    .when("eventType", {
      is: GenericFormType.OTHER,
      then: event => event
        .min(3, "Tapahtumatyypin oltava vähintään 3 merkkiä pitkä")
        .test("is-valid", "Ei sallittu", value => {
          return Object.values(GenericFormType)
          .find(val => val === value) === undefined;
        })
        .required("Pakollinen tieto")
    }),
  firstName: Yup.string()
    .min(2, "Etunimen pituus oltava vähintään 2 merkkiä")
    .max(50, "Etunimen pituus voi olla enintään 50 merkkiä"),
  lastName: Yup.string()
    .min(2, "Sukunimen pituus oltava vähintään 2 merkkiä")
    .max(50, "Sukunimen pituus voi olla enintään 50 merkkiä"),
  personalIdentityCode: Yup.string()
    .length(11, "Henkilötunnuksen pituus oltava 11 merkkiä")
    .required("Pakollinen tieto"),
  eventTime: Yup.date()
    .required("Pakollinen tieto"),
  description: Yup.string()
    .min(3, "Tekstin pituus oltava vähintään 3 merkkiä")
    .max(500, "Tekstin pituus voi olla enintään 256 merkkiä"),
  tapahtumatunnus: Yup.string(),
  viranomaiskoodi: Yup.string(),
  arkistonumero: Yup.string(),
  notifierId: Yup.string()
    .min(2, "Tunnisteen pituus oltava vähintään 2 merkkiä")
    .max(50, "Tunnisteen pituus voi olla enintään 11 merkkiä"),
  notifierType: Yup.mixed<NotifierType>()
    .oneOf(Object.values(NotifierType))
    .required("Pakollinen tieto"),
});

export const Form: React.FC<FormikProps<any>> = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  touched,
  values,
}) => {
  const availableGenericFormTypes = () => {
    return Object.entries(GenericFormType)
      .filter(([key, value]) => !CustomizedGenericFormTypes.includes(value))
      .map(([key, value]) => (
        <MenuItem key={"eventType-" + key} value={value}>
          {GenericFormTypeTranslations[key] ? GenericFormTypeTranslations[key].fi : key}
        </MenuItem>
        )
      );
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Card title="Muu tapahtuma">
        <FlexCol>
          <FlexCol>
            <FlexRow>
              <FormControl error={touched.eventType && Boolean(errors.eventType)}>
                <Typography>Valitse tapahtuman tyyppi</Typography>
                <Select 
                  name="eventType" 
                  value={values.eventType} 
                  onChange={handleChange} 
                  onBlur={handleBlur} 
                  variant="outlined" 
                  required 
                >
                  {
                    availableGenericFormTypes()
                  }
                </Select>
                { touched.eventType && Boolean(errors.eventType) && 
                  <FormHelperText>{errors.eventType as any}</FormHelperText> 
                }
              </FormControl>
            </FlexRow>
            { values.eventType === GenericFormType.OTHER && 
              <FlexRow>
                <TextField 
                  name="customEvent" 
                  label="Tapahtuman tyyppi" 
                  value={values.customEvent} 
                  onChange={handleChange} 
                  onBlur={handleBlur} 
                  error={touched.customEvent && Boolean(errors.customEvent)} 
                  helperText={touched.customEvent && errors.customEvent as any} 
                  variant="outlined" 
                  required 
                />
              </FlexRow>
            }
          </FlexCol>
          <br/>
          <Typography>Tapahtuman kohteena oleva henkilö</Typography>
          <FlexCol>
            <FlexRow>
              <TextField 
                name="firstName" 
                label="Etunimi" 
                value={values.firstName} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.firstName && Boolean(errors.firstName)} 
                helperText={touched.firstName && errors.firstName as any} 
                variant="outlined" 
              />
              <TextField 
                name="lastName" 
                label="Sukunimi" 
                value={values.lastName} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.lastName && Boolean(errors.lastName)} 
                helperText={touched.lastName && errors.lastName as any} 
                variant="outlined" 
              />
              <TextField 
                name="personalIdentityCode" 
                label="Henkilötunnus" 
                value={values.personalIdentityCode} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.personalIdentityCode && Boolean(errors.personalIdentityCode)} 
                helperText={touched.personalIdentityCode && errors.personalIdentityCode as any} 
                variant="outlined" 
                required 
              />
            </FlexRow>
          </FlexCol>
          <br/>
          <Typography>Tapahtuman tiedot</Typography>
          <FlexCol>
          <FlexRow>
              <TextField 
                type="datetime-local" 
                InputLabelProps={{ shrink: true }} 
                name="eventTime" 
                label="Tapahtuma-aika" 
                value={values.eventTime} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.eventTime && Boolean(errors.eventTime)} 
                helperText={touched.eventTime && errors.eventTime as any} 
                variant="outlined" 
                required 
              />
            </FlexRow>
            <FlexRow>
              <TextField 
                rows={3} 
                name="description" 
                label="Lisätiedot" 
                value={values.description} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.description && Boolean(errors.description)} 
                helperText={touched.description && errors.description as any} 
                variant="outlined" 
              />
            </FlexRow>
            <FlexRow>
              <TextField 
                name="tapahtumatunnus" 
                label="Tapahtumatunnus" 
                value={values.tapahtumatunnus} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.tapahtumatunnus && Boolean(errors.tapahtumatunnus)} 
                helperText={touched.tapahtumatunnus && errors.tapahtumatunnus as any} 
                variant="outlined" 
              />
              <TextField 
                name="viranomaiskoodi" 
                label="Viranomaiskoodi" 
                value={values.viranomaiskoodi} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.viranomaiskoodi && Boolean(errors.viranomaiskoodi)} 
                helperText={touched.viranomaiskoodi && errors.viranomaiskoodi as any} 
                variant="outlined" 
              />
              <TextField 
                name="arkistonumero" 
                label="Arkistonumero" 
                value={values.arkistonumero} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.arkistonumero && Boolean(errors.arkistonumero)} 
                helperText={touched.arkistonumero && errors.arkistonumero as any} 
                variant="outlined" 
              />
            </FlexRow>
          </FlexCol>
          <br/>
          <Typography>Tapahtuman ilmoittaja</Typography>
          <FlexCol>
            <FlexRow>
              <TextField 
                name="notifierId" 
                label="Ilmoittajan tunniste" 
                value={values.notifierId} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.notifierId && Boolean(errors.notifierId)} 
                helperText={touched.notifierId && errors.notifierId as any} 
                variant="outlined" 
              />
            </FlexRow>
            <FlexRow>
              <FormControl error={touched.notifierType && Boolean(errors.notifierType)}>
                <Typography>Ilmoittajan tyyppi</Typography>
                <Select 
                  name="notifierType" 
                  value={values.notifierType} 
                  onChange={handleChange} 
                  onBlur={handleBlur}  
                  variant="outlined" 
                  required 
                >
                  {
                    Object.entries(NotifierType).map(([key, value]) => (
                      <MenuItem key={"notifierType-" + key} value={value}>
                        {NotifierTypeTranslations[key] ? NotifierTypeTranslations[key].fi : key}
                      </MenuItem>
                    ))
                  }
                </Select>
                { touched.notifierType && Boolean(errors.notifierType) && 
                  <FormHelperText>{errors.notifierType as any}</FormHelperText> 
                }
              </FormControl>
            </FlexRow>
          </FlexCol>
          <Button type="submit" style={{margin: `${tokens.spacing.m} 0`}}>
            Lähetä
          </Button>
        </FlexCol>
      </Card>
    </form>
  )
}

const DefaultGenericForm: React.FC = () => {

  // Update handleSubmit to create postData from customized FormModel
  const handleSubmit = async (values: FormModel) => {
    const eventType = values.eventType === GenericFormType.OTHER ? values.customEvent! : values.eventType!;
    // As an example, if you need to use custom object e.g. HospitalNotifier instead of basic Notifier
    // instantiate custom object and add it to postData.
    const notifier: Notifier = {
      id: values.notifierId,
      type: values.notifierType
    }
    const postData: GenericEventPayload = {
      person: {
        firstName: values.firstName,
        lastName: values.lastName,
        personalIdentityCode: values.personalIdentityCode
      },
      eventData: {
        eventTime: values.eventTime,
        description: values.description,
        tapahtumatunnus: values.tapahtumatunnus,
        viranomaiskoodi: values.viranomaiskoodi,
        arkistonumero: values.arkistonumero
      },
      notifier
    }
    return sendGenericForm({ type: eventType, payload: postData});
  }

  return (
    <GenericForm 
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      form={Form}
      validationSchema={validationSchema}
    />
  );
}

export default DefaultGenericForm;