import React, { useContext } from "react";
import {AppContext} from "../../App";
import Layout from "../common/Layout";
import { Heading } from "suomifi-ui-components";
import { FlexCol, FlexRow, TableSmall } from "../common/CommonStyles";
import { Relationship } from "../common/DomainTypes";

const Home: React.FC = () => {
  const [appState] = useContext(AppContext);
  const person = appState.person;
  console.log("Person: ", person);
  const spouse = person.personRelations ? person.personRelations.find(i => i.role === Relationship.SPOUSE) : null;
  return (
    <Layout title="Etusivu">
      <Heading variant="h2">{person.firstName} {person.lastName}</Heading>
      <FlexCol>
        <FlexRow css={{
          "& > *": {
            boxSizing: "border-box",
            minHeight: "300px"
          }
        }}>
          <TableSmall>
            <tbody>
              <tr>
                <th>Henkilötunnus</th>
                <td className="right">{person.ssn}</td>
              </tr>
              <tr>
                <th>Syntymäaika</th>
                <td className="right">{person.birthday?.toLocaleDateString("fi", { year: "numeric", month: "long", day: "numeric"})}</td>
              </tr>
              <tr>
                <th>Osoite</th>
                <td className="right">{person.address?.street}, {person.address?.zip} {person.address?.city}</td>
              </tr>
              <tr>
                <th>Ensisijainen kieli</th>
                <td className="right">{person.primaryLanguage}</td>
              </tr>
              <tr>
                <th>Kansalaisuus</th>
                <td className="right">{person.nationality}</td>
              </tr>
              { person.dateOfDeath && !person.deathInfo?.cancellationOfDeath && <tr>
                <th>Kuolinaika</th>
                <td className="right">{person.dateOfDeath?.toLocaleDateString("fi", { year: "numeric", month: "long", day: "numeric"})}</td>
              </tr>}
              { person.dateOfDeath && person.deathInfo?.cancellationOfDeath && <tr>
                <th>Kuolinaika</th>
                <td className="right"><del>{person.dateOfDeath?.toLocaleDateString("fi", { year: "numeric", month: "long", day: "numeric"})}</del></td>
              </tr>}
              { spouse && <tr>
                <th>Puoliso</th>
                <td className="right">{spouse.relatedPerson ? spouse.relatedPerson.fullName : spouse.targetPersonSsn}</td>
              </tr>}
            </tbody>
          </TableSmall>
        </FlexRow>
      </FlexCol>
    </Layout>
  );
};

export default Home;
