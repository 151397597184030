/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import {FlexCol, FlexRow } from "../common/CommonStyles";
import { TextField } from "@mui/material";
import { address, abroadAddress, baseAddress } from "./model/address";
import _ from 'lodash';

class AddressEditor extends React.Component<any, baseAddress> {
  constructor(props: any, data : any) {
      super(props);
      this.state = props.data;
      this.internationalAddress = props.data instanceof abroadAddress;
  }

  internationalAddress : boolean = false

  handleChange(e: any) {
    let change = this.state;
    _.set(change, e.target.name, e.target.value)
    this.setState(change)
    this.props.refreshProperties(this.state, this.props.entity);
  }

  render() {
    
      return (
        <div>
          { !this.internationalAddress && 
          <div>
          <FlexRow>
              <TextField name='streetName' label="Kadun nimi tai postilokero" value={(this.state as address).streetName} onChange={this.handleChange.bind(this)} variant="outlined" />
              <TextField name='houseNumber' label="Talon numero" value={(this.state as address).houseNumber} onChange={this.handleChange.bind(this)} variant="outlined" />
              <TextField name='entranceIdentifier' label="Porrastunnus" value={(this.state as address).entranceIdentifier} onChange={this.handleChange.bind(this)} variant="outlined" />
              <TextField name='apartmentNumber' label="Asuntonumero" value={(this.state as address).apartmentNumber} onChange={this.handleChange.bind(this)} variant="outlined" />
              <TextField name='sectionLetter' label="Huoneiston jakokirjain" value={(this.state as address).sectionLetter} onChange={this.handleChange.bind(this)} variant="outlined" />
            </FlexRow>
            <br/>
            <FlexRow>
              <TextField name='postalCode' label="Postinumero" value={this.state.postalCode} onChange={this.handleChange.bind(this)} variant="outlined" />
              <TextField name='city' label="Kaupunki" value={(this.state as address).city} onChange={this.handleChange.bind(this)} variant="outlined" />
            </FlexRow> </div>}
            {this.internationalAddress &&
            <FlexRow >
              <FlexCol>
                <TextField style = {{width: 600 }} name='address' multiline rows={5} label="Osoite ulkomailla" value={(this.state as abroadAddress).address} onChange={this.handleChange.bind(this)} variant="outlined" />
              </FlexCol>
              <FlexCol>
              <TextField name='postalCode' label="Postitoimipaikka ulkomailla" value={this.state.postalCode} onChange={this.handleChange.bind(this)} variant="outlined" />
              </FlexCol>
            </FlexRow>
            }
            </div>
      );
  }
}

export default AddressEditor;
