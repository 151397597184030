import React, { useContext } from "react";
import { AppContext } from "../../App";
import { suomifiDesignTokens as sdt, Text, ExpanderGroup } from "suomifi-ui-components";
import Card from "../common/Card"
import { FlexCol, FlexRow, Heading1WithBottomBorder } from "../common/CommonStyles";
import MessageListItem from "./MessageListItem";

const Messages: React.FC = () => {
  const [appState] = useContext(AppContext);
  const person = appState.person;
  const messages = appState.messages.sort((m1, m2) => (new Date(m2.createdOn)).getTime() - (new Date(m1.createdOn)).getTime());

  return (
    <FlexCol>
      <div>
        <Heading1WithBottomBorder variant="h1" style={{ margin: `${sdt.spacing.l} 0 ${sdt.spacing.s}` }}>
          Viestit
          </Heading1WithBottomBorder>
        <Text variant="bold">{person.firstName} {person.lastName}</Text>
        <Text> - {person.address?.street}, {person.address?.zip} {person.address?.city}</Text>
      </div>
      { messages && <FlexRow css={{
        "& > *": {
          boxSizing: "border-box",
          minHeight: "150px"
        }
      }}>
        <Card
          title="Viestit"
        >
          <ExpanderGroup
            openAllText="Avaa kaikki"
            ariaOpenAllText="Avaa kaikki viestit"
            closeAllText="Sulje kaikki"
            ariaCloseAllText="Sulje kaikki viestit"
          >
            { messages.map( msg => <MessageListItem key={msg.id} msg={msg} />) }
          </ExpanderGroup>
        </Card>
      </FlexRow> }
    </FlexCol>
  );
}

export default Messages;