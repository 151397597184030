/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { FlexCol, FlexRow } from "../common/CommonStyles";
import { Button, FormControl, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import * as _ from "lodash";

import "react-datepicker/dist/react-datepicker.css";
import { advancement } from './model/advancement';

// import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import MultiValuePicker from './multiValuePicker';

class AdvancementCtrl extends React.Component<any, advancement> {
  constructor(props: any, data: advancement) {
    super(props);
    this.state = props.data;

  }

  handleChange(e: any) {
    let change = this.state;
    _.set(change, e.target.name, e.target.value)
    this.setState(change)
    this.props.refresh(this.state, this.props.array, this.props.index);
  }

  removeItem() {
    this.props.removeItem(this.props.index, this.props.array);
  }
  render() {

    return (
      <div>
        <FlexRow style={{ width: 600 }}>
          <TextField name='personalId' label="Saajan henkilötunnus" value={this.state.personalId} onChange={this.handleChange.bind(this)} variant="outlined" />
        </FlexRow>
        <FlexRow>
          <br /><br />
          <label>Ennakkoperinnön antaja</label>
          <MultiValuePicker name='advancementGiver' label="Ennakkoperinnön antaja" onChange={this.handleChange.bind(this)} data={this.state.advancementGiver}
            items={[{ value: 1, name: 'Vainaja' }, { value: 2, name: 'Leski' }]}
          ></MultiValuePicker>
          <br />   <br />
        </FlexRow>

        <FlexRow>
          <br /><br />
          <label>Ennakkoperintö ja avio-oikeus</label>
          <MultiValuePicker name='maritialRight' label="Ennakkoperintö ja avio-oikeus" onChange={this.handleChange.bind(this)} data={this.state.maritialRight}
            items={[{ value: 1, name: 'Avio-oikeuden alaisesta varallisuudesta' }, { value: 2, name: 'Avio-oikeudesta vapaasta varallisuudesta' }]}
          ></MultiValuePicker>
          <br />   <br />
        </FlexRow>

        <FlexRow>
          <TextField name='date' type="date" InputLabelProps={{ shrink: true }} label="Antamispäivä" value={this.state.date} onChange={this.handleChange.bind(this)} variant="outlined" />
        </FlexRow>
        <FlexRow>
          <FormControl fullWidth sx={{ my: 1 }}>
            <InputLabel htmlFor="outlined-adornment-amount">Ennakkoperinnön arvo</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              startAdornment={<InputAdornment position="start">€</InputAdornment>}
              label="Ennakkoperinnön arvo"
              name="value"
              value={this.state.value}
              onChange={this.handleChange.bind(this)}
            />
          </FormControl>
          {/* <CurrencyTextField
            label="Ennakkoperinnön arvo"
            variant="standard"
            name='value'
            value={this.state.value}
            currencySymbol="€"
            outputFormat="string"
            decimalCharacter=","
            digitGroupSeparator=" "
            onChange={this.handleChange.bind(this)} /> */}
          <FlexCol> <Button color="primary" style={{ width: 60 }} onClick={this.removeItem.bind(this)}>Poista</Button></FlexCol>
        </FlexRow>
      </div>
    );
  }
}

export default AdvancementCtrl;
