/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useContext, useState } from "react";
import { FlexCol, Heading1WithBottomBorder, PanelWithShadow } from "../common/CommonStyles";
import { Button, Heading, suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { AppContext } from "../../App";
import { NULL_PERSON } from "../common/DefaultValues";
import UserList from "../users/UserList";
import EmploymentCertificateForm from "../employment-certificate/EmploymentCertificateForm";
import DataModal from "../common/DataModal";

const Dashboard: React.FC = () => {
  const [appState] = useContext(AppContext)
  const person = appState.person;
  const [modals, setModals] = useState({ employmentCertificate: false })

  return (
    <FlexCol>
      <div>
        <Heading1WithBottomBorder variant="h1" style={{ margin: `${tokens.spacing.l} 0 ${tokens.spacing.s}` }}>
          Asetukset
        </Heading1WithBottomBorder>
      </div>
      {
        person !== NULL_PERSON && (
          <React.Fragment>
            <PanelWithShadow>
              <UserList />
            </PanelWithShadow>

            <PanelWithShadow>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Heading variant="h2" style={{ marginBottom: tokens.spacing.m }}>
                  Työtodistukset
                </Heading>

                <Button variant="secondaryNoBorder" icon={"plus"} onClick={() => setModals({ ...modals, employmentCertificate: true })}>
                  Luo työtodistus
                </Button>
                <DataModal 
                  title="Luo työtodistus"
                  isOpen={modals.employmentCertificate}
                >
                  <EmploymentCertificateForm
                    onSubmit={() => {
                      setModals({ ...modals, employmentCertificate: false })
                    }}
                    onCancel={() => {
                      setModals({ ...modals, employmentCertificate: false })
                    }}
                    initialValues={{
                      name: "Anna-Liisa Keskitalo",
                      company: "Digivero Oy (1234567-8)",
                      workRole: "Talousjohtaja",
                      startDate: "2015-01-01",
                      endDate: new Date().toISOString().replace(/T.+$/, "")
                    }}
                  />
                </DataModal>
              </div>
            </PanelWithShadow>
          </React.Fragment>
        )
      }

      <br />
    </FlexCol>
  );
};

export default Dashboard;
