import React from "react";
import { createRoot } from "react-dom/client";
import ReactModal from "react-modal";
import "./fonts.css";
import App from "./App"

ReactModal.setAppElement("#root");

const root = createRoot(document.getElementById("root")!);
root.render(<App/>);
