/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, suomifiDesignTokens as tokens } from "suomifi-ui-components";

export const inputCss = css`
  border: 1px solid ${tokens.colors.depthLight3};
  border-radius: 2px;
  box-sizing: border-box;
  font-family: ${tokens.values.typography.bodyText.fontFamily};
  font-size: ${tokens.values.typography.bodyText.fontSize.value}${tokens.values.typography.bodyText.fontSize.unit};
  font-weight: ${tokens.values.typography.bodyText.fontWeight};
  line-height: ${tokens.values.typography.bodyText.lineHeight.value};
  margin: ${tokens.spacing.xxs} 0;
  padding: ${tokens.spacing.s};
  width: 100%;
`;

const selectCss = css`
  ${inputCss}
  display: block;
  background: ${tokens.colors.whiteBase};
  -moz-appearance: none;
  -webkit-appearance: none;
`

export const Input = styled.input`
  ${inputCss}
`;

export const InputSmall = styled.input`
  ${inputCss}
  font-family: ${tokens.values.typography.bodyTextSmall.fontFamily};
  font-size: ${tokens.values.typography.bodyTextSmall.fontSize.value}${tokens.values.typography.bodyTextSmall.fontSize.unit};
  font-weight: ${tokens.values.typography.bodyTextSmall.fontWeight};
  line-height: 1;
`;

export const TextArea = styled.textarea`
  ${inputCss}
`;

export const InputNumber = styled(Input)`
  width: 3em;
  text-align: right;
  border: 1px solid ${tokens.colors.depthLight2};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

InputNumber.defaultProps = {type: 'number'};

export const InputRadio = styled.input`
  font-family: ${tokens.values.typography.bodyText.fontFamily};
  font-size: ${tokens.values.typography.bodyText.fontSize.value}${tokens.values.typography.bodyText.fontSize.unit};
  font-weight: ${tokens.values.typography.bodyText.fontWeight};
  line-height: ${tokens.values.typography.bodyText.lineHeight.value};
`;

InputRadio.defaultProps = {type: 'radio'};

export const Select = styled.select`
  ${selectCss}
`;

export const ButtonInverted = styled(Button)`
  background: none;
  color: ${tokens.colors.whiteBase} !important;
  border: 1px solid ${tokens.colors.whiteBase} !important;
  & > svg {
    fill: ${tokens.colors.whiteBase};
  }
`;

interface ButtonLinkProps {
  active?: boolean
}

export const ButtonLink = styled.button<ButtonLinkProps>`
  background: none;
  border: none;
  color: ${(props: ButtonLinkProps) => props.active ? tokens.colors.blackBase : tokens.colors.highlightBase};
  cursor: pointer;
  font-family: ${tokens.values.typography.bodyText.fontFamily};
  font-size: ${tokens.values.typography.bodyText.fontSize.value}${tokens.values.typography.bodyText.fontSize.unit};
  font-weight: ${tokens.values.typography.bodyText.fontWeight};
  line-height: ${tokens.values.typography.bodyText.lineHeight.value};
  padding: 0;
`;

export const ButtonLinkSmall = styled(ButtonLink)`
  font-family: ${tokens.values.typography.bodyTextSmall.fontFamily};
  font-size: ${tokens.values.typography.bodyTextSmall.fontSize.value}${tokens.values.typography.bodyTextSmall.fontSize.unit};
  font-weight: ${tokens.values.typography.bodyTextSmall.fontWeight};
  line-height: ${tokens.values.typography.bodyTextSmall.lineHeight.value};
`;

export const ButtonIconOnly = styled(Button)`
  & > svg {
    margin: 0 !important;
  };
  @media (max-width: 800px) {
    padding: ${tokens.spacing.s};
  };
`;

export const ButtonSmallIconOnly = styled(Button)`
  & > svg {
    margin: 0 !important;
  };
  background-color: transparent !important;
  padding: ${tokens.spacing.xs};
  min-height: 0;
`;

export const FormStyles = styled.div`
  form {
    .error {
      display: flex;
      font-weight: bold;
      color: #800;
      flex-flow: row nowrap;
      justify-content: center;
    }
    .Select {
      width: 100%;
    }
    pre {
      border: 1px solid #ccc;
      background: rgba(0, 0, 0, 0.1);
      box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
      padding: 20px;
    }
    input, textarea {
      ${inputCss}
    }
    select {
      ${selectCss}
    }
  }
`