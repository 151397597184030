/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useContext } from "react";
import { suomifiDesignTokens as sdt, Text } from "suomifi-ui-components";
import { FlexCol, FlexRow, Heading1WithBottomBorder } from "../common/CommonStyles";
import FamilyTreeWidget from "../family/FamilyTreeWidget";
import AssetsWidget from "../assets/AssetsWidget";
import { AppContext } from "../../App";
import WillWidget from "../will/WillWidget";
import PartiesWidget from "../parties/PartiesWidget";
import PrenuptialWidget from "../prenuptial/PrenuptialWidget";
import { useSpouse } from "../family/useSpouse";

const Dashboard: React.FC = () => {
  const [appState] = useContext(AppContext);
  const {spouse} = useSpouse();
  const person = appState.person;

  return (
      <FlexCol>
        <div>
          <Heading1WithBottomBorder variant="h1" style={{margin: `${sdt.spacing.l} 0 ${sdt.spacing.s}`}}>
            Perukirja
          </Heading1WithBottomBorder>
          <Text variant="bold">{person.firstName} {person.lastName}</Text>
          <Text> - {person.address?.street}, {person.address?.zip} {person.address?.city}</Text>
        </div>

        <FlexRow css={{
          '& > *': {
            boxSizing: 'border-box',
            minHeight: "300px"
          }
        }}>
          <AssetsWidget/>
          <FamilyTreeWidget/>
        </FlexRow>

        <FlexRow css={{
          '& > *': {
            boxSizing: 'border-box',
            minHeight: "300px"
          }
        }}>
          <PartiesWidget/>
          <WillWidget/>
        </FlexRow>

        <FlexRow css={{
          '& > *': {
            boxSizing: 'border-box',
            minHeight: "300px"
          }
        }}>
          {spouse ? <PrenuptialWidget/> : <div/>}
          <div/>
        </FlexRow>

        <br/>
      </FlexCol>
  );
};

export default Dashboard;
