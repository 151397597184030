import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { getSpouse } from "./familyTreeApi";
import { Person } from "../common/DomainTypes";

export function useSpouse() {
  const [appState] = useContext(AppContext);
  const person = appState.person;

  const [spouse, setSpouse] = useState<null | Person>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getSpouse(person.id)
    .then(spouse => setSpouse(spouse))
    .finally(() => setLoading(false));
  }, [person]);

  return {spouse, loadingSpouse: loading};
}
