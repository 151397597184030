/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { CSSProperties } from "react";
import { suomifiDesignTokens as tokens } from "suomifi-design-tokens";
import { IconRefresh } from "suomifi-icons";

const Spinner: React.FC<Props> = ({style}) => {
  return <div css={css`
    @keyframes spin {
      from {
        transform:rotate(0deg);
      }
      to {
        transform:rotate(360deg);
      }
    }
    animation: spin 2s linear infinite;
    color: ${tokens.colors.depthDark1};
    font-size: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
  `} style={{...style}}>
    <IconRefresh icon='refresh' />
  </div>;
};

interface Props {
  style?: CSSProperties
}

export default Spinner;
