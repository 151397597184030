import React, { useContext, useState } from 'react';
import { Link, useLocation, useMatch } from "react-router-dom";
import { IconButton, Paper, Popper, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { DropdownItem, DropdownMenu, NavBarContainer, NavBarHeader, NavBarHeaderContent, NavBarMenu, NavListItem, SelectedNavItemWrapper } from './NavBarStyles';
import { RoutePathProps, RoutePaths } from '../../App';
import { matchPath } from 'react-router';
import { AppContext } from "../../App";
import { NULL_PERSON } from './DefaultValues';

const NavItem: React.FC<{to: string, label: string, onClick: () => void}> = ({ to, label, onClick }) => {
  let match = useMatch(to);
  return (
    <Link to={to} 
    className={match ? "match" : ""}
    onClick={onClick}>
      {label}
    </Link>
  );
};

const createDropdownItems = (keyPrefix: string, routePaths: RoutePathProps[], isExpanded: boolean, onSelect: () => void) => {
  return routePaths.filter(it => it.visibleInNav).map((it, i) => {
    if (it.dropdownItems) {
      return <Dropdown key={`${keyPrefix}-${i}`} keyPrefix={`${keyPrefix}-${i}`} label={it.label} dropdownItems={it.dropdownItems} isExpanded={isExpanded} collapse={onSelect} />;
    }
    return <DropdownItem key={`${keyPrefix}-${i}`}>
      <NavItem to={it.to} label={it.label} onClick={onSelect} />
    </DropdownItem>
  });
}

const Dropdown: React.FC<{keyPrefix: string, label: string, dropdownItems: RoutePathProps[], isExpanded: boolean, collapse: () => void}> = ({keyPrefix, label, dropdownItems, isExpanded, collapse}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const isMobile = useMediaQuery("(max-width: 1000px)");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onSelect = () => {
    setAnchorEl(() => null);
    collapse();
  }

  return (
    <NavListItem>
      <a onClick={handleClick} style={{display: "flex", alignItems: "center", padding: 0}}>
        {label}
        { Boolean(anchorEl) ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
      </a>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        sx={{zIndex: 1}}
        hidden={isMobile}
      >
        <Paper>
          <DropdownMenu>
            <ul>
              { createDropdownItems(keyPrefix, dropdownItems, isExpanded, onSelect) }
            </ul>
          </DropdownMenu>
        </Paper>
      </Popper>
      <DropdownMenu hidden={!isMobile || !Boolean(anchorEl)}>
        <ul>
          { createDropdownItems(keyPrefix, dropdownItems, isExpanded, onSelect) }
        </ul>
      </DropdownMenu>
    </NavListItem>
  );
}

const getPathLabelKey = (route: RoutePathProps, pathname: string) => {
  const match = matchPath(route.to, pathname);
  return match ? route.label : null;
}

const SelectedNavItemLabel: React.FC = () => {
  let location = useLocation();
  if (location.pathname === "/kirjaudu") {
    return <span>{"Kirjaudu"}</span>;
  }
  const paths = RoutePaths.flatMap(it => {
    return it.dropdownItems ?? [it];
  }).map(it => getPathLabelKey(it, location.pathname)).filter(it => it !== null);
  return paths.length > 0 ? <span>{paths[0]}</span> : <span></span>
}

const NavBar: React.FC = () => {
  const [appState] = useContext(AppContext);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const collapseMenu = () => {
    setIsExpanded(old => false);
  }

  return (
    <NavBarContainer>
      <NavBarHeader>
        <NavBarHeaderContent>
          <SelectedNavItemWrapper>
            <SelectedNavItemLabel/>
          </SelectedNavItemWrapper>
        </NavBarHeaderContent>
        <IconButton title='Expand navigation' onClick={() => setIsExpanded(old => !old)}>
          <MenuIcon/>
        </IconButton>
      </NavBarHeader>
      <NavBarMenu className={isExpanded ? "": "menu-hidden"}>
        <ul>
          {!appState.isLoadingPerson && appState.person === NULL_PERSON ? 
            <NavListItem key={`navlistitem-login`}>
              <NavItem to={"/login"} label={"Kirjaudu"} onClick={collapseMenu} />
            </NavListItem> :
            RoutePaths.filter(it => it.visibleInNav).map((it, i) => 
              it.dropdownItems ?
              <Dropdown key={`navlistitem-${i}`} keyPrefix={`navlistitem-${i}`} label={it.label} dropdownItems={it.dropdownItems} isExpanded={isExpanded} collapse={collapseMenu} />
              :
              <NavListItem key={`navlistitem-${i}`}>
                <NavItem to={it.to} label={it.label} onClick={collapseMenu} />
              </NavListItem>
            )
          }
        </ul>
      </NavBarMenu>
    </NavBarContainer>
  )
}

export default NavBar;