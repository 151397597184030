import axios from "axios";
import { TreeNode } from "../common/Tree";
import { Person, Relationship } from "../common/DomainTypes";

export const getFamilyTree = (personId: string): Promise<TreeNode[]> => {
  return axios(`${process.env.REACT_APP_API_URL}/henkilo/${personId}/sukupuu`)
  .then(res => {
    const personsById = res.data.Henkilo.reduce((map: any, person: any) => {
      map[person.Id] = person;
      return map;
    }, {});

    const findChildren = (id: string) => {
      if (!personsById[id]) {
        return [];
      }

      return personsById[id].Osapuoli
      .filter((o: any) => o.Suhdelaji === Relationship.CHILD)
      .map((o: any) => {
        return {name: o.Etunimi, children: findChildren(o.Id)}
      });
    };

    return findChildren(personId);
  });
};

export const getSpouse = (personId: string): Promise<null | Person> => {
  return axios(`${process.env.REACT_APP_API_URL}/henkilo/${personId}/sukupuu`)
  .then(res => {

    const spouseArray =
        (res.data.Henkilo || [])
        .filter((person: any) => person.Id === personId)
        .flatMap((person: any) => person.Osapuoli)
        .filter((party: any) => party.Suhdelaji === Relationship.SPOUSE);

    return spouseArray && spouseArray.length > 0 ? mapPerson(spouseArray[0]) : null;
  });
};

const mapPerson = (data: any): Person => {
  return {
    id: data.Id,
    ssn: data.Henkilotunnus,
    firstName: data.Etunimi,
    lastName: data.Sukunimi,
    fullName: `${data.Etunimi} ${data.Sukunimi}`,
    primaryLanguage: "fi",
    nationality: "FIN",
    childCount: 0,
  };
};
