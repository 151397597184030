import React from "react";
import { EventGridEvent } from "../../common/DomainTypes";
import { FlexCol, FlexRow, TableSmall } from "../../common/CommonStyles";
import { toDateString } from "../../../utils/dateUtils";
import { GenericEventPayload } from "../../forms/GenericFormTypes";
import { TerminationOfEmploymentPayload } from "../../forms/TerminationOfEmployment/TerminationOfEmployment";

type Props = {
  event: EventGridEvent<GenericEventPayload>
}

const TerminationOfEmploymentEvent: React.FC<Props> = ({event}: Props) => {
  const eventData = {
    person: event.Data.person,
    eventData: event.Data.eventData as TerminationOfEmploymentPayload,
    notifier: event.Data.notifier
  }
  const reasonText = (reason?: string) => {
    switch (reason) {
      case "maaraaikaisuus":
        return "Määräaikainen työsuhde";
      case "irtisanominen":
        return "Työnantajan päättämä irtisanominen";
      case "irtisanoutuminen":
        return "Työntekijän päättämä irtisanoutuminen";
      case "purkaminen":
        return "Työsopimuksen purkaminen";
      default:
        return "";
    }
  }

  return (
    <FlexCol>
      <FlexRow css={{
        "& > *": {
          boxSizing: "border-box",
          minHeight: "300px"
        }
      }}>
        <TableSmall>
          <tbody>
            <tr>
              <th>Nimi</th>
              <td className="right">{eventData.person.firstName} {eventData.person.lastName}</td>
            </tr>
            <tr>
              <th>Henkilötunnus</th>
              <td className="right">{eventData.person.personalIdentityCode}</td>
            </tr>
            <tr>
              <th>Työsuhde päättyy</th>
              <td className="right">
                {toDateString(eventData.eventData.eventTime)}
              </td>
            </tr>
            <tr>
              <th>Työsuhteen päättymisen syy</th>
              <td className="right">{reasonText(eventData.eventData.reason)}
              </td>
            </tr>
            <tr>
              <th>Työnantajan takaisinottovelvollisuus</th>
              <td className="right">{eventData.eventData.readmission ? "Kyllä" : "Ei"}</td>
            </tr>
            <tr>
              <th>Lisätiedot</th>
              <td className="right">{eventData.eventData.description}</td>
            </tr>
          </tbody>
        </TableSmall>
      </FlexRow>
    </FlexCol>
  );
}

export default TerminationOfEmploymentEvent;