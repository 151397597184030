/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import Card from "../common/Card";
import { TableSmall } from "../common/CommonStyles";
import Spinner from "../common/Spinner";
import { useAssets } from "./useAssets";

const AssetsWidget: React.FC = () => {
  const {assets, loadingAssets} = useAssets();

  return (
    <Card 
      title="Omaisuus"
      additionalInfo={{ text: "Katso tarkemmat varallisuustiedot", path: "/wealth" }}
    >
      {loadingAssets ? <Spinner style={{ height: "100%" }} /> :
        <TableSmall>
          <tbody>
            {assets.map((entry, i) =>
              <tr key={i}>
                <th>{entry.name}</th>
                <td className="right">+{entry.value} €</td>
              </tr>
            )}
          </tbody>
        </TableSmall>
      }
    </Card>
  )
};

export default AssetsWidget;
