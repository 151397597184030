/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, {useContext, useEffect, useState} from "react";
import { useNavigate } from "react-router";
import Modal from "react-modal";
import { Button, Heading, suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { ApiContext, AppContext} from "../../App";
import { FlexCol, FlexRow, Heading1WithBottomBorder } from "../common/CommonStyles";
import Spinner from "../common/Spinner";
import { NULL_PERSON } from "../common/DefaultValues";
import { ButtonLink, Input } from "../common/InputStyles";
import { login } from "../../api/omaelama-functions"
import { loadAppStateAsync } from "../../state/AppContext";

const Login: React.FC = () => {
  const apiUrl = useContext(ApiContext);
  const [appState, setAppState] = useContext(AppContext);

  const navigate = useNavigate();

  const [ssn, setSsn] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  // ensure empty user when login page is viewed
  useEffect(() => {
    setAppState(appState => ({...appState, person: NULL_PERSON}));
  }, [setAppState]);

  const doLogin = (ssn: string) => {
    setLoading(true);
    login(ssn)
    .then(res => {
      return loadAppStateAsync(appState, setAppState);
    })
    .then(() => {
      setLoading(false);
      navigate("/");
    })
    .catch(() => {
      setLoading(false);
    });
  };

  return <div>
    <FlexRow>
      <FlexCol>
        <div>
          <Heading1WithBottomBorder variant="h1" style={{margin: `${tokens.spacing.l} 0 ${tokens.spacing.s}`}}>
            Kirjaudu
          </Heading1WithBottomBorder>
          Syötä henkilötunnuksesi ja paina kirjaudu.
        </div>

        <div>
          <label>
            Henkilötunnus
            <Input type="password" value={ssn} onChange={e => setSsn(e.currentTarget.value)}/>
          </label>

          <Button onClick={() => doLogin(ssn)}
                  style={{margin: `${tokens.spacing.m} 0`}}
                  icon={"login"}>
            Kirjaudu
          </Button>
        </div>

        <div style={{color: tokens.colors.depthDark1}}>
          Kokeile esimerkkihenkilöillä:
          <ul>
            <li>
              <ButtonLink onClick={() => doLogin("270400A999V")}>Erno Jacopintes</ButtonLink>
            </li>
            <li>
              <ButtonLink onClick={() => doLogin("241254-999A")}>Villehard Yrjänä Pekkala</ButtonLink>
            </li>
            <li>
              <ButtonLink onClick={() => doLogin("291156-9987")}>Moa Maj Pekkala</ButtonLink>
            </li>
            <li>
              <ButtonLink onClick={() => doLogin("080880-938H")}>Silja Juhola</ButtonLink>
            </li>
            <li>
              <ButtonLink onClick={() => doLogin("160256-995F")}>Pekko Piermontes</ButtonLink>
            </li>
            <li>
              <ButtonLink onClick={() => doLogin("290399-997U")}>Pietari Järvites</ButtonLink>
            </li>
            <li>
              <ButtonLink onClick={() => doLogin("290998-996C")}>Tuulamarianne Ollenbergtes</ButtonLink>
            </li>
            <li>
              <ButtonLink onClick={() => doLogin("010280-952L")}>Tessa Testilä</ButtonLink>
            </li>
            <li>
              <ButtonLink onClick={() => doLogin("010180-953U")}>Tehvo Testilä</ButtonLink>
            </li>
            <li>
              <ButtonLink onClick={() => doLogin("200760-9998")}>Erkka Kaj Aaltonen</ButtonLink>
            </li>
            <li>
              <ButtonLink onClick={() => doLogin("190465-998E")}>Jenny Aaltonen</ButtonLink>
            </li>
            <li>
              <ButtonLink onClick={() => doLogin('030553-998U')}>Kaisa von Kugelgentes</ButtonLink>
            </li>
          </ul>
        </div>
      </FlexCol>
      <div/>
    </FlexRow>

    <Modal isOpen={loading} style={{
      content: {
        top: "20%",
        margin: "0 auto",
        width: "200px",
        height: "200px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: tokens.spacing.xl,
        border: `1px solid ${tokens.colors.depthLight3}`,
        borderRadius: "2px",
      }
    }} contentLabel="Kirjaudutaan...">
      <Spinner/>
      <Heading variant="h3" style={{marginTop: tokens.spacing.l}}>
        Kirjaudutaan...
      </Heading>
    </Modal>

  </div>;
};

export default Login;
