import React, { Dispatch, SetStateAction, useState, useEffect, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { css, Global } from "@emotion/react"
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { ContentContainer, HeaderBackground, TopNavigationBackground } from "./components/common/CommonStyles";
import Header from "./components/common/Header";
import About from "./components/about/About";
import Dashboard from "./components/dashboard/Dashboard";
import DashboardWork from "./components/dashboard-work/DashboardWork";
import DashboardFinance from "./components/dashboard-finance/DashboardFinance"
import DashboardEducation from "./components/dashboard-education/DashboardEducation"
import EstateInventoryEditor from "./components/estateInventory/EstateInventory"
import Home from "./components/home/Home";
import Login from "./components/login/Login";
import WillView from "./components/will/WillView";
import WillEdit from "./components/will/WillEdit";
import {Person} from "./components/common/DomainTypes";
import {NULL_PERSON} from "./components/common/DefaultValues";
import PrenuptialView from "./components/prenuptial/PrenuptialView";
import PrenuptialEdit from "./components/prenuptial/PrenuptialEdit";
import SettingsDashboard from "./components/settings/SettingsDashboard"
import IncomeView from "./components/income-view/IncomeView";
import Forms from "./components/forms/FormsView";
import ReportDeathForm from "./components/forms/reportDeath/ReportDeathForm";
import { AppState, initialState, loadAppState } from "./state/AppContext";
import Messages from "./components/messages/Messages";
import ChildsBirth from "./components/forms/ChildsBirth/ChildsBirth";
import TerminationOfEmployment from "./components/forms/TerminationOfEmployment/TerminationOfEmployment";
import DefaultGenericForm from "./components/forms/DefaultGenericForm";
import MarriageForm from "./components/forms/Marriage";
import NavBar from "./components/common/NavBar";

export const AppContext = React.createContext<[AppState, Dispatch<SetStateAction<AppState>>]>([initialState(), () => null]);
export const ApiContext = React.createContext<string>("");

const App: React.FC = () => {
  const [appState, setAppState] = useState(initialState());
  useEffect(() => {
    loadAppState(appState, setAppState);
  }, []);

  return (
    <Suspense fallback="loading">
      <AppContext.Provider value={[appState, setAppState]}>
          <ApiContext.Provider
              value={process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "/example-data"}>
            <Global styles={css`
              body {
                background-color: ${tokens.colors.depthLight3};
                font-family: ${tokens.values.typography.bodyText.fontFamily};
                font-size: ${tokens.values.typography.bodyText.fontSize.value}${tokens.values.typography.bodyText.fontSize.unit};
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                line-height: ${tokens.values.typography.bodyText.lineHeight.value}${tokens.values.typography.bodyText.lineHeight.unit};
                margin: 0;
              }
              a {
                color: ${tokens.colors.highlightBase};
                text-decoration: none; 
              }
              a:visited {
                color: ${tokens.colors.accentTertiaryDark1}
              }
              code {
                white-space: pre-wrap !important;
              }
              label {
                font-weight: ${tokens.values.typography.bodySemiBold.fontWeight}
              } 
              hr {
                border: 0;
                border-bottom: 1px solid ${tokens.colors.depthLight3};
                margin: ${tokens.spacing.m} 0;
              }
              ul, ol {
                margin: 0;
                padding: 0;
                @media (max-width: 1200px) {
                  list-style-position: inside;              
                }
              }
              li {
                margin: 0;
                padding: 0;            
              }
              p {
                margin: ${tokens.spacing.m} 0;
              }
            `}
            />

            <Router>
              <HeaderBackground>
                <ContentContainer>
                  <Header/>
                </ContentContainer>
              </HeaderBackground>

              <TopNavigationBackground>
                <ContentContainer>
                  <NavBar/>
                </ContentContainer>
              </TopNavigationBackground>

              <ContentContainer>
                <AppContext.Consumer>
                  {state => !state[0].isLoadingPerson && state[0].person === NULL_PERSON ?
                      <Routes>
                        <Route path="/kirjaudu" element={<Login/>}/>
                        <Route path="*" element={<Navigate to={"/kirjaudu"}/>}/>
                      </Routes> :
                      <Routes>
                        { RoutePaths.flatMap((it, i) => {
                          if (it.dropdownItems) { 
                            return it.dropdownItems.map((it2, i2) => 
                              <Route key={`route-${i}-${i2}`} path={it2.to} element={it2.element}/>
                            );
                          }
                          return [<Route key={`route-${i}`} path={it.to} element={it.element}/>];
                        })}
                        <Route path="/kirjaudu" element={<Navigate to={"/"}/>}/>
                      </Routes>
                  }
                </AppContext.Consumer>
              </ContentContainer>
            </Router>
          </ApiContext.Provider>
      </AppContext.Provider>
    </Suspense>
  );
};

export interface RoutePathProps {
  to: string;
  label: string;
  element: React.ReactNode | null;
  visibleInNav: boolean;
  dropdownItems?: RoutePathProps[];
}

export const RoutePaths: RoutePathProps[] = [
  { to: "/", label: "Kirjaudu", element: <Home/>, visibleInNav: true},
  { to: "/viestit", label: "Viestit", element: <Messages/>, visibleInNav: true},
  { to: "/osaaminen", label: "Osaaminen", element: <DashboardEducation/>, visibleInNav: true},
  { to: "/tyoelama", label: "Työelämä", element: <DashboardWork/>, visibleInNav: true},
  { to: "/talous", label: "Talous", element: <DashboardFinance/>, visibleInNav: true},
  { to: "/perukirja", label: "Perukirja", element: <Dashboard/>, visibleInNav: true},
  { to: "/perintoverotus", label: "Perintöverotus", element: <EstateInventoryEditor/>, visibleInNav: true},
  { to: "/ohjeet-ja-tuki", label: "Ohjeet ja tuki", element: <About/>, visibleInNav: true},
  { to: "/lomakkeet", label: "Lomakkeet", element: <Forms/>, visibleInNav: true, dropdownItems: [
    { to: "/kuolemanraportointi", label: "Kuoleman raportointi", element: <ReportDeathForm/>, visibleInNav: true},
    { to: "/lapsensyntyma", label: "Lapsen syntymä", element: <ChildsBirth/>, visibleInNav: true},
    { to: "/tyosuhteenpaattyminen", label: "Työsuhteen päättyminen", element: <TerminationOfEmployment/>, visibleInNav: true},
    { to: "/avioliitto", label: "Avioliitto", element: <MarriageForm/>, visibleInNav: true},
    { to: "/muutapahtuma", label: "Muu tapahtuma", element: <DefaultGenericForm/>, visibleInNav: true}
  ]},
  { to: "/testamentti/muokkaa", label: "presentations", element: <WillEdit/>, visibleInNav: false},
  { to: "/testamentti", label: "presentation", element: <WillView/>, visibleInNav: false},
  { to: "/avioehto/muokkaa", label: "new-presentation", element: <PrenuptialEdit/>, visibleInNav: false},
  { to: "/avioehto", label: "activity", element: <PrenuptialView/>, visibleInNav: false},
  { to: "/asetukset", label: "activity", element: <SettingsDashboard/>, visibleInNav: false},
  { to: "/tulot", label: "activity", element: <IncomeView/>, visibleInNav: false}
]

export default App;
