/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { Button, TextField } from "@mui/material";
import { companyValueDetails, valueDetails, totalValueDetails } from "./model/valueDetails";
import _ from "lodash";
// import CurrencyTextField from "@unicef/material-ui-currency-textfield"

class ValueDetailsEditor extends React.Component<any, valueDetails> {
  constructor(props: any, data : any) {
      super(props);
      this.state = props.data;
      this.showBusinessId = props.data instanceof companyValueDetails;
      this.showNumberOfItems = props.data instanceof totalValueDetails;
  }

  showBusinessId : boolean = false;
  showNumberOfItems: boolean = false;

  handleChange(e: any) {
    let change = this.state;
    _.set(change, e.target.name, e.target.value)
    this.setState(change)
    this.props.refresh(this.state, this.props.array, this.props.index);
  }

  removeItem(){
    this.props.removeItem(this.props.index, this.props.array);
  }

  render() {
    
      return (
        <tr>
              {this.showBusinessId &&
              <td style={{padding: 10}}>
              <TextField style={{width: 200}} name="businessId" label="Y-tunnus" value={(this.state as companyValueDetails).businessId} onChange={this.handleChange.bind(this)} variant="outlined" />
              </td>
              }
              {this.showNumberOfItems &&
              <td style={{padding: 10}}>
              <TextField style={{width: 200}} name="numberOfItems" label="Lukumäärä" value={(this.state as totalValueDetails).numberOfItems} onChange={this.handleChange.bind(this)} variant="outlined" />
              </td>
              }

              <td style={{padding: 10}}>
              {/* <CurrencyTextField
                    label="Arvo"
                    variant="standard"
                    name="value"
                    value={this.state.value}
                    currencySymbol="€"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator=" "
                    onChange={this.handleChange.bind(this)} 
                    style={{width: 200}}
                    /> */}
              </td>
              <td style={{padding: 10}}>
              <TextField style={{width: 400}} name="details" label="Lisätiedot" value={this.state.details} onChange={this.handleChange.bind(this)} variant="outlined" />
              </td>
              <td style={{padding: 10}}>
              <Button color="primary" style={{width: 60, paddingTop: 35}} onClick={this.removeItem.bind(this)}>Poista</Button>
            </td>
            </tr>
      );
  }
}

export default ValueDetailsEditor;
