/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Button, Checkbox, Divider, TextField, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { FlexCol, FlexRow, panelWithShadowCss } from "../common/CommonStyles";
import Card from "../common/Card";
import * as _ from "lodash";
import { v4 } from "uuid";
import "react-datepicker/dist/react-datepicker.css";
import { EstateInventory } from "./model/estateInventory";
import AddressEditor from  "./addressEditor";
import PersonEditor from "./personEditor";
import GiftEditor from "./giftEditor";
import InsurancePaymentEditor from "./insurancePaymentEditor";
import BeneficiaryEditor from "./beneficiaryEditor";
import AdvancementCtrl from "./advancementCtrl";
import AssetEditor from "./assetEditorCtrl";
import ValueDetailsEditor from "./valueDetailsEditor";

class EstateInventoryEditor extends React.Component<any, EstateInventory> {
  constructor(props: any) {
      super(props);
      this.state = new EstateInventory()
  }

  handleChange(e: any) {
    let change = this.state;
    _.set(change, e.target.name, e.target.value)
    this.setState(change)
  }

  handleCheckbox(e: any) {
    let change = this.state;
    _.set(change, e.target.name, e.target.checked)
    this.setState(change)
  }

  addNewRow(arrayName: string){
    var list = _.get(this.state, arrayName);

    if (list === null || list === undefined){
      let change = this.state;
      _.set(change, arrayName, []);
    }
    
    list = _.get(this.state, arrayName);
    var item = {} as any;
    item.id = v4();
    list.push(item);
    var change = {} as any
    _.set(change, arrayName, list);
    this.setState(change);
  }

  setChildState(item: any, array: any, index: number){
    var list = _.get(this.state, array);
    list[index] = item;
    var change = {};
    _.set(change, array, list)
    this.setState(change)
  }

  
  refreshProperties(item: any, entity: any){
    _.set(this.state, entity, item);
  }

  removeItem (item: number, array: string) {
        var list = _.get(this.state, array);
        list.splice(item, 1);
        var change = {} as any
        _.set(change, array, list);
        this.setState(change);
  }

  advancementSum(): string {
    var sum = 0;
    for (var i = 0; i < this.state.advancements.length; i++)
    {
      var amount = this.state.advancements[i].value as unknown as string;
      if (amount !== undefined && amount !== null){
        sum += Number.parseFloat(amount.split(" ").join(""));
    }
    }

    return sum.toString();
  }

  giftsSum(): string {
    var sum = 0;
    for (var i = 0; i < this.state.gifts.length; i++)
    {
      var amount = this.state.gifts[i].value as unknown as string;
      if (amount !== undefined && amount !== null){
        sum += Number.parseFloat(amount.split(" ").join(""));
    }
    }

    return sum.toString();
  }

  insurancesSum(): string {
    var sum = 0;
    for (var i = 0; i < this.state.insurancePayments.length; i++)
    {
      var amount = this.state.insurancePayments[i].value as unknown as string;
      if (amount !== undefined && amount !== null){
        sum += Number.parseFloat(amount.split(" ").join(""));
    }
    }

    return sum.toString();
  }

  render() {
    
    return (
        <Card title="Perukirja">
        
          <FlexCol>
          <Typography>Yhteyshenkilö</Typography>
            <PersonEditor data={this.state.contactPerson} refreshProperties={this.refreshProperties.bind(this)} entity="contactPerson"></PersonEditor>

            <Typography>Osoite suomessa</Typography>
            <AddressEditor data={this.state.address} refreshProperties={this.refreshProperties.bind(this)} entity="address"></AddressEditor>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography>Osoite ulkomailla</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AddressEditor data={this.state.addressAbroad} refreshProperties={this.refreshProperties.bind(this)} entity="overseasAddress"></AddressEditor>
              </AccordionDetails>
            </Accordion>

            <br></br>

            <Typography>Perinnönjättäjä</Typography>
            <PersonEditor data={this.state.decedent} refreshProperties={this.refreshProperties.bind(this)} entity="decedent"></PersonEditor>

            <Typography>Leski</Typography>
            <PersonEditor data={this.state.widow} refreshProperties={this.refreshProperties.bind(this)} entity="widow"></PersonEditor>

            <Divider />

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Perilliset</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <div style={{padding: 10 }}>
                  {
                    this.state.beneficiaries.map(item => (
                      <FlexCol css={panelWithShadowCss}>
                      <BeneficiaryEditor
                      removeItem={this.removeItem.bind(this)} 
                      key={item.id}
                      data={item} 
                      refresh={this.setChildState.bind(this)}
                      index={this.state.beneficiaries.indexOf(item)}
                      array="beneficiaries"
                      ></BeneficiaryEditor>
                      </FlexCol>
                    ))
                  }
                  <Button color="primary" onClick={() => this.addNewRow("beneficiaries")} style={{width: 100}}>Lisää uusi perillinen</Button>
              </div>
              </AccordionDetails>
            </Accordion>

            <br></br>
            <Divider />

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Ennakkoperinnöt, {this.state.advancements.length} kpl, {this.advancementSum()} € </Typography>
              </AccordionSummary>
              <AccordionDetails>
              <div style={{padding: 10 }}>
                  {
                    this.state.advancements.map(item => (
                      <FlexCol css={panelWithShadowCss}>
                      <AdvancementCtrl
                      removeItem={this.removeItem.bind(this)} 
                      key={item.id}
                      data={item} 
                      refresh={this.setChildState.bind(this)}
                      index={this.state.advancements.indexOf(item)}
                      array="advancements"
                      ></AdvancementCtrl>
                      </FlexCol>
                    ))
                  }
                  <Button color="primary" onClick={() => this.addNewRow("advancements")} style={{width: 100}}>Lisää uusi ennakkoperintö</Button>
              </div>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Lahjat, {this.state.gifts.length} kpl, {this.giftsSum()} € </Typography>
              </AccordionSummary>
              <AccordionDetails>
              <div style={{padding: 10 }}>
                  {
                    this.state.gifts.map(item => (
                      <FlexCol css={panelWithShadowCss}>
                      <GiftEditor
                      removeItem={this.removeItem.bind(this)} 
                      key={item.id}
                      data={item} 
                      refresh={this.setChildState.bind(this)}
                      index={this.state.gifts.indexOf(item)}
                      array="gifts"
                      ></GiftEditor>
                      </FlexCol>
                    ))
                  }
                  <Button color="primary" onClick={() => this.addNewRow("gifts")} style={{width: 100}}>Lisää uusi lahja</Button>
              </div>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Vakuutuskorvaukset, {this.state.insurancePayments.length} kpl, {this.insurancesSum()} € </Typography>
              </AccordionSummary>
              <AccordionDetails>
              <div style={{padding: 10 }}>
                  {
                    this.state.insurancePayments.map(item => (
                      <FlexCol css={panelWithShadowCss}>
                      <InsurancePaymentEditor
                      removeItem={this.removeItem.bind(this)} 
                      key={item.id}
                      data={item} 
                      refresh={this.setChildState.bind(this)}
                      index={this.state.insurancePayments.indexOf(item)}
                      array="insurancePayments"
                      ></InsurancePaymentEditor>
                      </FlexCol>
                    ))
                  }
                  <Button color="primary" onClick={() => this.addNewRow("insurancePayments")} style={{width: 100}}>Lisää uusi vakuutuskorvaus</Button>
              </div>
              </AccordionDetails>
            </Accordion>

            <FlexRow>
              <TextField type="number" name="decedentAssets" label="Perinnönjättäjän varat yhteensä" value={this.state.decedentAssets} onChange={this.handleChange.bind(this)} variant="outlined" />
              <TextField type="number" name="decedentNonMaritalRightAssets" label="Perinnönjäöttäjän varat, joihin leskellä ei ole avio-oikeutta" value={this.state.decedentNonMaritalRightAssets} onChange={this.handleChange.bind(this)} variant="outlined" />
            </FlexRow>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Perinnönjättäjän varallisuus</Typography>
              </AccordionSummary>
              <AccordionDetails>
                  {
                      <AssetEditor data={this.state.decedentAssets} ></AssetEditor>        
                  }
              </AccordionDetails>
            </Accordion>

            <FlexRow>
              <TextField type="number" name="decedentTotalLiabilities" label="Perinnönjättäjän velat yhteensä" value={this.state.decedentTotalLiabilities} onChange={this.handleChange.bind(this)} variant="outlined" />
            </FlexRow>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Perinnönjättäjän velkojen erittely</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <div style={{padding: 10 }}>
                  {
                    this.state.decedentLiabilities.map(item => (
                      <FlexCol css={panelWithShadowCss}>
                      <ValueDetailsEditor
                      removeItem={this.removeItem.bind(this)} 
                      key={item.id}
                      data={item} 
                      refresh={this.setChildState.bind(this)}
                      index={this.state.decedentLiabilities.indexOf(item)}
                      array="decedentLiabilities"
                      ></ValueDetailsEditor>
                      </FlexCol>
                    ))
                  }
                  <Button color="primary" onClick={() => this.addNewRow("decedentLiabilities")} style={{width: 100}}>Lisää uusi</Button>
              </div>
              </AccordionDetails>
            </Accordion>

            <FlexRow>
              <TextField type="number" name="widowTotalAssets" label="Lesken tai aiemmin kuolleen puolison avio-oikeuden alaiset varat yhteensä, €" value={this.state.widowTotalAssets} onChange={this.handleChange.bind(this)} variant="outlined" />
            </FlexRow>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Lesken tai aiemmin kuolleen puolison avio-oikeuden alainen varallisuus</Typography>
              </AccordionSummary>
              <AccordionDetails>
                  {
                      <AssetEditor data={this.state.widowAssets} ></AssetEditor>        
                  }
              </AccordionDetails>
            </Accordion>

          <FlexRow>
            <Typography>Vaatimus, että perintöveroa huojennetaan (Perintö ja lahjaverolaki 55§)</Typography>
            <Checkbox name="reliefRequested" checked={this.state.reliefRequested} onChange={this.handleCheckbox.bind(this)}></Checkbox>
          </FlexRow>
          <FlexRow>
            <Typography>Vaatimus, että perintöveron maksuaikaa pidennetään (Perintö ja lahjaverolaki 56§)</Typography>
            <Checkbox name="extensionRequested" checked={this.state.extensionRequested} onChange={this.handleCheckbox.bind(this)}></Checkbox>
          </FlexRow>
          <FlexRow>
            <Typography>Leski vetoaa AL 103.2§</Typography>
            <Checkbox name="widowRefusesAdjustment" checked={this.state.widowRefusesAdjustment} onChange={this.handleCheckbox.bind(this)}></Checkbox>
          </FlexRow>
          <FlexRow>
            <Typography>Perinnönjättäjällä on testamentti</Typography>
            <Checkbox name="lastWill" checked={this.state.lastWill} onChange={this.handleCheckbox.bind(this)}></Checkbox>
          </FlexRow>
          <FlexRow>
            <Typography>Lesken hallinta vakituiseen asuntoon</Typography>
            <Checkbox name="widowRightToHome" checked={this.state.widowRightToHome} onChange={this.handleCheckbox.bind(this)}></Checkbox>
          </FlexRow>
          <FlexRow>
            <Typography>Perinnönjättäjän varallisuuteen sisältyy kahden vuoden sisällä perittyä varallisuutta</Typography>
            <Checkbox name="assetInherited" checked={this.state.assetInherited} onChange={this.handleCheckbox.bind(this)}></Checkbox>
          </FlexRow>
          <FlexRow>
            <Typography>Lesken varallisuuteen sisältyy kahden vuoden sisällä perittyä varallisuutta</Typography>
            <Checkbox name="widowAssetInherited" checked={this.state.widowAssetInherited} onChange={this.handleCheckbox.bind(this)}></Checkbox>
          </FlexRow>
          <FlexRow>
              <TextField type="number" name="decedentTotalLiabilities" label="Vaatimus ulkomaille maksetun veron hyvityksestä, euromäärä" value={this.state.decedentTotalLiabilities} onChange={this.handleChange.bind(this)} variant="outlined" />
            </FlexRow>
        </FlexCol>
      </Card>
      
      );
  }
}

export default EstateInventoryEditor;
