/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { Heading, suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { FlexColTight, panelWithShadowCss } from "./CommonStyles";


const Card: React.FC<PropsWithChildren<Props>> = props => {
  return (
    <FlexColTight css={panelWithShadowCss}>
      <Heading variant="h2" smallScreen>
        {props.title}
      </Heading>

      {props.children}

      { 
        !props.additionalInfo 
          ? null
          : (<div style={{ marginTop: "auto" }}>
              <Link to={props.additionalInfo.path}>
                <span style={{ color: tokens.colors.accentBase }}>&rsaquo; </span>
                  {props.additionalInfo.text}
                </Link>
            </div>)
      }
    </FlexColTight>
  );
};

type Props = {
  title: string,
  additionalInfo?: {
    text: string,
    path: string
  }
}

// Card.propTypes = {
//   title: PropTypes.string.isRequired,
//   addditionalInfo: PropTypes.shape({
//     text: PropTypes.string.isRequired,
//     path: PropTypes.string.isRequired
//   })
// }

export default Card;
