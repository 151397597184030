import { Person, Prenuptial } from "../common/DomainTypes";
import axios from "axios";

export const getPrenuptial = (personId: string): Promise<null | Prenuptial> => {
  return axios(`${process.env.REACT_APP_API_URL}/henkilo/${personId}/dokumentit/avioehto`)
  .then(res => res.data.Avioehto ? mapPrenuptial(res.data) : null);
};

export const postPrenuptial = (person: Person, prenup: Prenuptial): Promise<any> => {
  return axios.post(`${process.env.REACT_APP_API_URL}/henkilo/${person.id}/dokumentit/avioehto`, reverseMapPrenuptial(prenup));
};

const mapPrenuptial = (data: any): Prenuptial => {
  return {
    id: data.Avioehto.id,
    clause: data.Avioehto.Klausuuli,
    type: data.Avioehto.Avioehtolaji,
    date: data.Avioehto.Laatimispaivamaara,
    parties: data.Avioehto.Osapuoli
        ? data.Avioehto.Osapuoli.map(mapPerson)
        : []
  };
};

const mapPerson = (data: any): Person => {
  return {
    id: data.Id,
    ssn: data.Henkilotunnus,
    firstName: data.Etunimi,
    lastName: data.Sukunimi,
    fullName: `${data.Etunimi} ${data.Sukunimi}`,
    primaryLanguage: "fi",
    nationality: "FIN",
    childCount: 0,
  };
};

const reverseMapPrenuptial = (prenup: Prenuptial): any => {
  return {
    Avioehto: {
      id: prenup.id,
      Avioehtolaji: prenup.type.valueOf(),
      Klausuuli: prenup.clause,
      Laatimispaivamaara: prenup.date,
      Osapuoli: prenup.parties.map(reverseMapPerson)
    }
  };
};

const reverseMapPerson = (person: Person): any => {
  return {
    Id: person.id,
    Henkilotunnus: person.ssn,
    Etunimi: person.firstName,
    Sukunimi: person.lastName,
  };
};
