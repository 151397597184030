import axios from "axios";
import { OwnerAddress, TaxAdministrationBuilding } from "../model/assets";

const apiRootUrl = process.env.REACT_APP_TAX_ADMINISTRATION_API || 'http://localhost:7071/api'

const taxAdministrationApi = axios.create({
  baseURL: apiRootUrl
});

export async function GetBuildingData(permanentBuildingId: string): Promise<TaxAdministrationBuilding> {
  return taxAdministrationApi.get(`${apiRootUrl}/${permanentBuildingId}/TaxAdministrationInfo`)
                              .then(resp => resp.data)
                              .catch(err => console.log(`No building info found by ID ${permanentBuildingId}: ${err}`));
}

export async function GetAddress(permanentBuildingId: string): Promise<OwnerAddress> {
  return taxAdministrationApi.get(`${apiRootUrl}/${permanentBuildingId}/OwnerAddress`)
                             .then(resp => resp.data)
                              .catch(err => console.log(`No building info found by ID ${permanentBuildingId}: ${err}`));
}