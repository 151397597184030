/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { panelWithShadowCss } from "./CommonStyles";
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { IconErrorFilled, IconSuccess } from 'suomifi-icons';

interface Props {
  message: string,
  level?: string,
  closable?: boolean,
  onClose?: Function
}

const NotificationBox: React.FC<Props> = props => {
  const determineIcon = () => {
    switch(props.level) {
      case "success":
        return <IconSuccess/>;
      default:
        return <IconErrorFilled/>;
      
    }
  }

  const determineColor = () => {
    switch(props.level) {
      case "success":
        return "blackBase";
      default:
        return "whiteBase";
      
    }
  }

  return (
    <NotificationContainer css={panelWithShadowCss} className={props.level}>
      {determineIcon()}
      <span>{props.message}</span>
    </NotificationContainer>
  );
}

NotificationBox.propTypes = {
  message: PropTypes.string.isRequired,
  level: PropTypes.string,
  closable: PropTypes.bool,
  onClose: PropTypes.func
}

NotificationBox.defaultProps = {
  level: "alert",
  closable: true
}

export default NotificationBox;

export const NotificationContainer = styled.div`
  align-items: center;
  &.success {
    background-color: ${tokens.colors.successSecondary};
  }
  &.warning {
    background-color: ${tokens.colors.warningBase};
  }
  &.error {
    background-color: ${tokens.colors.alertBase};
    color: ${tokens.colors.whiteBase};
  }
  & > * {
    margin-left: ${tokens.spacing.l};
  }
`;