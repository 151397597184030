import { address, abroadAddress } from "./address";
import { advancement } from "./advancement";
import { gift } from "./gift";
import { beneficiary } from "./beneficiary";
import { InsurancePayment } from "./insurancePayment";
import { contactPerson, decedentDetails, person } from "./person";
import { assets } from "./assets";
import { valueDetails } from "./valueDetails";

export class EstateInventory{
    id: string = "";
    attachmentFile: string = ""
    contactPerson: contactPerson = new contactPerson();
    address: address = new address();
    addressAbroad: abroadAddress = new abroadAddress();
    decedent: decedentDetails = new decedentDetails();
    widow: person = new person();
    beneficiaries: beneficiary[] = []
    
    advancements: advancement[] = [];
    gifts: gift[] = [];
    insurancePayments: InsurancePayment[]  = [];
    decedentTotalAssets: number = 0;
    decedentNonMaritalRightAssets: number = 0;
    decedentAssets: assets = new assets();
    decedentTotalLiabilities: number = 0;
    decedentLiabilities: valueDetails[]  = [];
    
    widowTotalAssets: number = 0
    widowAssets: assets = new assets();

    // Lesken tai aiemmin kuolleen puolison avio-oikeuden alaiset velat?

    reliefRequested: boolean = false;
    extensionRequested: boolean = false;
    widowRefusesAdjustment: boolean = false;
    lastWill: boolean = false;
    widowRightToHome: boolean = false;
    otherPossession: boolean = false;
    assetInherited: boolean = false;
    widowAssetInherited: boolean = false;
    taxPaidToOtherCountry: boolean = false;

}