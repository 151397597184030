import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, {useContext, useEffect, useState} from "react";
import { Heading } from "suomifi-ui-components";
import {AppContext} from "../../App";
import { AssetsAppData, BuildingAppData, PersonPropertyShare } from "../../model/assets";
import Card from '../common/Card';
import {FlexCol, FlexRow, TableSmall} from "../common/CommonStyles";
import Spinner from "../common/Spinner";
import * as PropertyApi from "../../api/propertyApi";
import { getPersonsAssetsAppData } from "./assetsApi";

const PersonAssets: React.FC = () => {
  const [appState] = useContext(AppContext);
  const person = appState.person;

  const [assets, setAssets] = useState<AssetsAppData>({ownerId: "", buildings: []});
  const [properties, setProperties] = useState<PersonPropertyShare[]>([]);
  const [loading, loadingAssets] = useState<boolean>(false);

  useEffect(() => {
    loadingAssets(true);
    var assets = getPersonsAssetsAppData(person.id);
    var properties = PropertyApi.GetPropertyData(person.ssn as string);

    Promise.all([assets, properties]).then(r => {
      setAssets(r[0]);
      setProperties(r[1]);
    }).finally(() => loadingAssets(false));
  }, [person]);

  const buildingSummary = (entry: BuildingAppData) => {
    if (entry.address["rhr"]) {
      return entry.buildingData["rhr"].PermanentBuildingID + " - " +
        entry.address["rhr"]?.Address + " " + entry.address["rhr"]?.PostalCode + " " + entry.address["rhr"]?.PostalDistrict;
    }
    return entry.buildingData["rhr"].PermanentBuildingID;
  }

  const getAddress = (entry: PersonPropertyShare) => {
    return `${entry.address} ${entry.stair !== undefined ? entry.stair : ""}${entry.number !== undefined ? entry.number : ""} ${entry.postalCode} ${entry.postalDistrict}`
  }
  
  return (
    <Card 
      title="Omaisuus"
    >
      {loading ? <Spinner style={{ height: "100%" }} /> :
        <div>
        { assets?.buildings && assets?.buildings.length > 0 &&
        <FlexCol>
          <Heading variant="h3" smallScreen>
            {"Rakennukset"}
          </Heading>
          {
            assets?.buildings.map((entry, i) =>
              <Accordion defaultExpanded={i === 0}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                  <Typography><b>{buildingSummary(entry)}</b></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FlexRow css={{
                          '& > *': {
                            boxSizing: 'border-box',
                            paddingLeft: '10px'
                          }
                        }}>
                    <FlexCol>
                      <Accordion defaultExpanded>
                        <AccordionDetails>
                          <TableSmall css={{marginTop: 0}}>
                            <tbody>
                              <tr>
                                <th>Rekisteri</th>
                                <td className="right">Rakennus- ja huoneistotietorekisteri RHR</td>
                              </tr>
                              <tr>
                                <th>Pysyvä rakennustunnus</th>
                                <td className="right">{entry.buildingData["rhr"].PermanentBuildingID}</td>
                              </tr>
                              <tr>
                                <th>Osoite</th>
                                <td className="right">{entry.address["rhr"]?.Address} {entry.address["rhr"]?.PostalCode} {entry.address["rhr"]?.PostalDistrict}</td>
                              </tr>
                              <tr>
                                <th>Omistaja</th>
                                <td className="right">{entry.owner["rhr"].FirstNames} {entry.owner["rhr"].LastName}</td>
                              </tr>
                            </tbody>
                          </TableSmall>
                        </AccordionDetails>
                      </Accordion>
                    </FlexCol>
                    <FlexCol>
                      <Accordion defaultExpanded>
                        <AccordionDetails>
                          <TableSmall css={{marginTop: 0}}>
                            <tbody>
                              <tr>
                                <th>Rekisteri</th>
                                <td className="right">Vero</td>
                              </tr>
                              <tr>
                                <th>Pysyvä rakennustunnus</th>
                                <td className="right">{entry.buildingData["rhr"].PermanentBuildingID}</td>
                              </tr>
                              <tr>
                                <th>Osoite</th>
                                <td className="right">{entry.address["tax"]?.Address} {entry.address["tax"]?.PostalCode} {entry.address["tax"]?.PostalDistrict}</td>
                              </tr>
                              <tr>
                                <th>Omistaja</th>
                                <td className="right">{entry.owner.FirstNames} {entry.owner.LastName}</td>
                              </tr>
                            </tbody>
                          </TableSmall>
                        </AccordionDetails>
                      </Accordion>
                    </FlexCol>
                  </FlexRow>
                </AccordionDetails>
              </Accordion>
              )}
        </FlexCol>}             
      { properties?.length > 0 &&
        <FlexCol>
          <Heading variant="h3" smallScreen>
            {"Huoneistot"}
          </Heading>
          {properties?.map((entry, i) =>
              <Accordion defaultExpanded={i === 0}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                  <Typography><b>Asunto-osakkeet, yht. {entry.shareEnd - entry.shareStart + 1} kappaletta, {entry.address} {entry.postalDistrict}</b></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FlexRow css={{
                          '& > *': {
                            boxSizing: 'border-box',
                            paddingLeft: '10px'
                          }
                        }}>
                    <FlexCol>
                      <Accordion defaultExpanded>
                        <AccordionDetails>
                          <TableSmall css={{marginTop: 0}}>
                            <tbody>
                              <tr>
                                <th>Osakkeet</th>
                                <td className="right">{entry.shareStart} - {entry.shareEnd}, {entry.shareEnd - entry.shareStart + 1} kappaletta</td>
                              </tr>
                              <tr>
                                <th>Osoite</th>
                                <td className="right">{getAddress(entry)}</td>
                              </tr>
                              <tr>
                                <th>Pinta-ala, m2</th>
                                <td className="right">{entry.area}</td>
                              </tr>
                              <tr>
                                <th>Kerros</th>
                                <td className="right">{entry.floor}</td>
                              </tr>
                              <tr>
                                <th>Käyttötarkoitus</th>
                                <td className="right">{entry.intendedUse}</td>
                              </tr>
                              <tr>
                                <th>Selite</th>
                                <td className="right">{entry.specifier}</td>
                              </tr>
                            </tbody>
                          </TableSmall>
                        </AccordionDetails>
                      </Accordion>
                    </FlexCol>
                  </FlexRow>
                </AccordionDetails>
              </Accordion>
          )}
        </FlexCol>
        }
      </div>
      }
    </Card>
  )
};

export default PersonAssets;
