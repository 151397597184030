import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { EstateParty } from "../common/DomainTypes";
import { getParties } from "./partiesApi";

export function useParties() {
  const [appState] = useContext(AppContext)
  const person = appState.person;

  const [parties, setParties] = useState<EstateParty[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getParties(person.id)
    .then(parties => setParties(parties))
    .finally(() => setLoading(false));
  }, [person]);

  return {parties, loadingParties: loading};
}
