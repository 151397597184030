export class baseAddress {
  postalCode: string = ""
}

export class address extends baseAddress {
    streetName: string = ""
    houseNumber?: number
    entranceIdentifier: string = ""
    apartmentNumber: string = ""
    sectionLetter: string = ""
    city: string = ""
  }

  export class abroadAddress extends baseAddress {
    address: string = ""
  }