/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { Button, TextField } from "@mui/material";
import * as _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";
// import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { gift } from "./model/gift";

class GiftEditor extends React.Component<any, gift> {
  constructor(props: any) {
      super(props);
      this.state = props.data;
  }

  handleChange(e: any) {
    let change = this.state;
    _.set(change, e.target.name, e.target.value)
    this.setState(change)
    this.props.refresh(this.state, this.props.array, this.props.index);
  }

  removeItem(){
    this.props.removeItem(this.props.index, this.props.array);
  }
  render() {
    
      return (
        <tr>
            <td>
                <TextField name="receiverSsn" label="3v. lahjan saajan henkilötunnus" value={this.state.personalId } onChange={this.handleChange.bind(this)} variant="outlined" />
            </td>
            <td>
                <TextField name="date" type="date" InputLabelProps={{ shrink: true }} label="3v. lahjan saantopäivä" value={this.state.date } onChange={this.handleChange.bind(this)} variant="outlined" />
            </td>
                <td>
                    {/* <CurrencyTextField
                    label="3v. lahjan arvo"
                    variant="standard"
                    name="value"
                    value={this.state.value}
                    currencySymbol="€"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator=" "
                    onChange={this.handleChange.bind(this)} /> */}
                </td>
                <td> <Button color="primary" style={{width: 60}} onClick={this.removeItem.bind(this)}>Poista</Button></td>
        </tr>
      );
  }
}

export default GiftEditor;
