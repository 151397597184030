export class basePerson {
    id: string = ""
    name: string = ""
}

export class person extends basePerson {
    personalId: string = ""
}

export class contactPerson extends basePerson {
    phoneNumber: string = ""
}
export class decedentDetails extends person {
    estateInventoryDate: Date = new Date()
    dateOfDeath: Date = new Date()
}