/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import Card from "../common/Card";
import { TableSmall } from "../common/CommonStyles";
import Spinner from "../common/Spinner";

const EmployersCard: React.FC = () => {
  const isLoading = false
  return (
    <Card
      title="Luotot ja lainat"
      additionalInfo={{ text: "Katso tarkemmat tiedot", path: "/credit-and-loan" }}
    >
      {
        isLoading
          ? <Spinner style={{ height: "100%" }} />
          : (
            <TableSmall>
              <tbody>
                <tr>
                  <th>Voimassa oleva asuntolaina</th>
                  <td className="right">128 345 €</td>
                </tr>
                <tr>
                  <th>Tuloihin perustuva asuntolaina-arvio</th>
                  <td className="right">330 000 €</td>
                </tr>
              </tbody>
            </TableSmall>
          )
      }
    </Card>
  )
};

export default EmployersCard;
