import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { Prenuptial, PrenuptialType } from "../common/DomainTypes";
import { getPrenuptial, postPrenuptial } from "./prenuptialApi";
import { NIL_UUID } from "../../utils/uuidUtils";

export function usePrenuptial() {
  const [appState] = useContext(AppContext)
  const person = appState.person;

  const [prenuptial, setPrenuptial] = useState<Prenuptial>({
    id: NIL_UUID,
    type: PrenuptialType.MUTUALLY_EXCLUSIVE,
    clause: "",
    date: "0000-00-00",
    parties: [],
  });

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getPrenuptial(person.id)
    .then(p => p && setPrenuptial(p))
    .finally(() => setLoading(false));
  }, [person]);


  const savePrenuptial = (): Promise<any> => {
    return postPrenuptial(person, prenuptial);
  };

  return {prenuptial, setPrenuptial, loadingPrenuptial: loading, savePrenuptial};
}
