import { useContext, useEffect, useState } from "react";
import { suomifiDesignTokens as tokens } from "suomifi-ui-components"
import axios from "axios"
import { AppContext } from "../../App";

interface IObjectKeys {
  [key: string]: string;
}

const getColorByTransactionCode = (transactionCode: any) => {
  switch(String(transactionCode)) {
    case "201": return tokens.colors.successBase
    case "213": return tokens.colors.successSecondary
    case "402": return tokens.colors.alertBase
    case "413": return tokens.colors.warningBase
    case "414": return tokens.colors.accentBase
  }
}

const createIncomeDatasetsForChart = (transactions: any, transactionCodes: Array<any>) => {
  const aggregates = groupTransactionsIntoCodes(transactions)
  let data = {
    labels: aggregates.months,
    datasets: Object.entries(aggregates.codes).reduce((result: object[], [code, values]) => {
      return [
        ...result,
        {
          stack: "income",
          label: transactionCodes.find(x => String(x.codeValue) === String(code))?.prefLabel?.fi || code,
          backgroundColor: getColorByTransactionCode(code),
          data: aggregates.months.map(month => {
            return values.find(x => x.month === month)?.value || 0
          })
        }
      ]
    }, [])
    // datasets: Object.entries(monthlyTotals).reduce((result, [month, totals]) => {
    //   Object.entries(totals).map(([transactionCode, value]) => {
    //     return {
    //       stack: month,
    //       backgroundColor: getColorByTransactionCode(transactionCode)
    //     }
    //   })
    // }, [])
    // datasets: [
      
    //   {
    //     stack: "stack1",
    //     backgroundColor: tokens.colors.alertBase,
    //     label: "data1",
    //     data: [-1.7, -2, -3, -3.6, -2.8]
    //   },
    //   {
    //     stack: "stack1",
    //     backgroundColor: tokens.colors.brandBase,
    //     label: "data2",
    //     data: [5, 4, 3, 2, 1]
    //   }
    // ]
  }

  return data
}



const groupTransactionsIntoCodes = (transactions: any) => {
  return {
      months: [
        "2020/1",
        "2020/2",
        "2020/3",
        "2020/4",
        "2020/5",
        "2020/6",
        "2020/7",
        "2020/8",
        "2020/9",
        "2020/10",
        "2020/11",
        "2020/12"
      ],
      codes: {
        201: [
          { month: "2020/1", value: 5300 },
          { month: "2020/2", value: 5300 },
          { month: "2020/3", value: 5300 },
          { month: "2020/4", value: 5300 },
          { month: "2020/5", value: 5300 },
          { month: "2020/6", value: 5300 },
          { month: "2020/7", value: 5300 },
          { month: "2020/8", value: 5300 },
          { month: "2020/9", value: 5300 },
          { month: "2020/10", value: 5300 },
          { month: "2020/11", value: 5300 },
          { month: "2020/12", value: 5300 }
        ],
        213: [
          { month: "2020/7", value: 2500 }
        ],
        402: [
          { month: "2020/1", value: -1219 },
          { month: "2020/2", value: -1219 },
          { month: "2020/3", value: -1219 },
          { month: "2020/4", value: -1219 },
          { month: "2020/5", value: -1219 },
          { month: "2020/6", value: -1219 },
          { month: "2020/7", value: -1219 },
          { month: "2020/8", value: -1219 },
          { month: "2020/9", value: -1219 },
          { month: "2020/10", value: -1219 },
          { month: "2020/11", value: -1219 },
          { month: "2020/12", value: -1219 }
        ],
        413: [
          { month: "2020/1", value: -389.55 },
          { month: "2020/2", value: -389.55 },
          { month: "2020/3", value: -389.55 },
          { month: "2020/4", value: -389.55 },
          { month: "2020/5", value: -389.55 },
          { month: "2020/6", value: -389.55 },
          { month: "2020/7", value: -389.55 },
          { month: "2020/8", value: -389.55 },
          { month: "2020/9", value: -389.55 },
          { month: "2020/10", value: -389.55 },
          { month: "2020/11", value: -389.55 },
          { month: "2020/12", value: -389.55 }
        ],
        414: [
          { month: "2020/1", value: -67.84 },
          { month: "2020/2", value: -67.84 },
          { month: "2020/3", value: -67.84 },
          { month: "2020/4", value: -67.84 },
          { month: "2020/5", value: -67.84 },
          { month: "2020/6", value: -67.84 },
          { month: "2020/7", value: -67.84 },
          { month: "2020/8", value: -67.84 },
          { month: "2020/9", value: -67.84 },
          { month: "2020/10", value: -67.84 },
          { month: "2020/11", value: -67.84 },
          { month: "2020/12", value: -67.84 }
        ]
    }
  }
  // transactions.reduce((result, item) => {
  //   const endDate = new Date(item.EarningPeriodEnd)
  //   const monthKey = `${endDate.getFullYear()}/${endDate.getMonth()+1}`
    
  // }, {})
}

export function useIncome() {
  const [appState] = useContext(AppContext)
  const person = appState.person;

  const [transactions, setTransactions] = useState<any>()
  const [transactionCodes, setTransactionCodes] = useState<Array<any>>([])
  const [isLoading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    Promise.all([
      // fetchData(`IncomeData/Persons/${person.ssn}/Transactions?timespanInMonths=12`),
      axios.get("/codelists/Tulolajit.json")
    ])
      .then(
        ([
          // transactionsResult, 
          transactionCodesResult
        ]) => {
          // setTransactions(transactionsResult.value?.GroupedByEndMonth)
          setTransactionCodes(transactionCodesResult.data.codes)
        }
      )
      .finally(() => setLoading(false));
  }, [person])

  return {
    transactions, 
    isLoading,
    incomeDatasets: createIncomeDatasetsForChart(transactions, transactionCodes)
  }
}
