/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useContext } from "react";
import { AppContext } from "../../App";
import { Message, MessageType, EventGridEvent } from "../common/DomainTypes";
import { suomifiDesignTokens as tokens, Expander, ExpanderContent, ExpanderTitleButton } from "suomifi-ui-components";
import { IconRemove } from 'suomifi-icons';
import { FlexCol, FlexRow } from "../common/CommonStyles";
import { ButtonLinkSmall } from "../common/InputStyles";
import DeathReportEvent from "./events/DeathReportEvent";
import { postReadMessage, postUnReadMessage, postDeletedMessage, Status } from "../../api/omaelama-functions";
import { toDateString } from "../../utils/dateUtils";
import { GenericEventPayload, GenericFormType, GenericFormTypeByValue } from "../forms/GenericFormTypes";
import DefaultGenericFormEvent from "./events/DefaultGenericFormEvent";
import TerminationOfEmploymentEvent from "./events/TerminationOfEmploymentEvent";
import MarriageEvent from "./events/MarriageEvent";


interface Family {
  lapsilisän_määrä_perustelut: Record<string, string>;
  [key: string]: Record<string, string>;
}

function takeOpenFiscaValue(input: Record<string, string>) {
  return (Object.values(input || {})[0] as string) || "";
}

const EventSubject = (event: EventGridEvent<any>) => {
  switch(event.EventType) {
    case GenericFormType.DEATH_REPORT:
    case "Kuolintieto":
      return `Henkilön ${event.Data.Person.givenName} ${event.Data.Person.familyName} kuolintieto vastaanotettu`;
    default:
      const genericEvent: EventGridEvent<GenericEventPayload> = { ...event };
      return `${GenericFormTypeByValue(event.EventType)}`;
  }
}

const XRoadSubject = (msg: Message) => {
  switch(msg.subType) {
    case "Kuolintieto":
      return `Henkilön ${msg.jsonData.Person.givenName} ${msg.jsonData.Person.familyName} kuolintieto vastaanotettu`;
    default:
      return msg.subject;
  }
}

const EventContent = ({msg}: {msg: Message}) => {
  switch(msg.source) {
    case "EventGrid":
      return <GenericEventContent msg={msg}/>;
    case "Openfisca API":
      if (msg.subject === "Leskeneläke") {
        // Take out needed variables from OpenFisca output JSON
        const widowData = msg.jsonData.henkilöt?.leski;
        const isGranted = !!Object.values(
          widowData.oikeutettu_leskeneläkkeeseen || {}
        )[0];
        const decision = `Oikeutesi leskeneläkkeeseen on tarkistettu ja ${
          isGranted
            ? "sinulle on myönnetty leskeneläke."
            : "sinulla ei ole oikeutta leskeneläkkeeseen."
        }`;
        const argument = takeOpenFiscaValue(
          widowData.oikeutettu_leskeneläkkeeseen_perustelut
        );
        return (
          <OpenFiscaContent
            decision={decision}
            argument={
              argument
                ? `Leskeneläkkeeseen on oikeus, mikäli\n\n${argument}`
                : ""
            }
          />
        );
      }
      if (msg.subject === "Lapsilisä") {
        // Take out needed variables from OpenFisca output JSON
        const familyData = Object.values(msg.jsonData.perheet)?.[0] as Family;
        const amount = takeOpenFiscaValue(familyData.lapsilisän_määrä);
        const decision = `Lapsilisän määrä tässä kuussa on ${amount} euroa.`;
        const argument = takeOpenFiscaValue(
          familyData.lapsilisän_määrä_perustelut
        );
        return <OpenFiscaContent decision={decision} argument={argument} />;
      }
      return <StringEventContent message={`Tuntematon Openfisca APIn tapahtuma ${msg.subject}: ${JSON.stringify(msg.jsonData)}`}/>;
    case "X-Road":
      return <XRoadContent msg={msg} />
    default:
      return <StringEventContent message={"Tuntematon tapahtuma: " + JSON.stringify(msg.jsonData)}/>;
  }
}

const GenericEventContent = ({msg}: {msg: Message}) => {
  switch(msg.jsonData.EventType) {
    case GenericFormType.DEATH_REPORT:
    case "Kuolintieto":
      return <DeathReportEvent msg={msg} />;
    case GenericFormType.CHILDS_BIRTH:
      return <DefaultGenericFormEvent event={msg.jsonData} />;
      case GenericFormType.MARRIAGE:
        return <MarriageEvent event={msg.jsonData} />;
    case GenericFormType.TERMINATION_OF_EMPLOYMENT:
      return <TerminationOfEmploymentEvent event={msg.jsonData} />;
    default:
      return <DefaultGenericFormEvent event={msg.jsonData} />;
  }
}

const XRoadContent = ({msg}: {msg: Message}) => {
  switch (msg.subType) {
    case "Kuolintieto":
      return <DeathReportEvent msg={msg} />;
    default:
      return <StringEventContent message={"Tuntematon tapahtuma: " + JSON.stringify(msg.jsonData)}/>;
  }
}

const StringEventContent = ({message}: {message: string}) => {
  return (
    <FlexCol>
      <FlexRow css={{
        "& > *": {
          boxSizing: "border-box",
          minHeight: "300px"
        }
      }}>
        {message}
      </FlexRow>
    </FlexCol>
  )
}

const OpenFiscaContent = ({
  decision,
  argument,
}: {
  decision: string;
  argument: string;
}) => {
  return (
    <div>
      <p>
        <b>Päätös:</b>
        <br />
        {decision}
      </p>
      {!!argument && (
        <p>
          <b>Perustelut:</b>
          <br />
          <span
            css={{
              whiteSpace: "pre-wrap",
            }}
          >
            {argument}
          </span>
        </p>
      )}
    </div>
  );
};

const MessageListItem: React.FC<Props> = ({msg}: Props) => {
  const [appState, setAppState] = useContext(AppContext);

  const readMessage = (message: Message, open: boolean) => {
    if (open && !message.isRead && appState.person.id === message.receiverId) {
      postReadMessage(message.id)
      .then(res => {
        if (res.data.status !== Status.SUCCESS) {
          console.log(`Error while reading message ${message.id}`, res.data.message);
        }
        else {
          var messages = appState.messages;
          messages.filter(m => m.id === message.id).forEach(m => m.isRead = true);
          setAppState(appState => ({...appState, messages}));
        }
      })
      .catch(error => {
        console.log(`Error while reading message ${message.id}`, error.response.data);
      });
    }
  }

  const unReadMessage = (message: Message) => {
    if (message.isRead && appState.person.id === message.receiverId) {
      postUnReadMessage(message.id)
      .then(res => {
        if (res.data.status !== Status.SUCCESS) {
          console.log(`Error while reading message ${message.id}`, res.data.message);
        }
        else {
          var messages = appState.messages;
          messages.filter(m => m.id === message.id).forEach(m => m.isRead = false);
          setAppState(appState => ({...appState, messages}));
        }
      })
      .catch(error => {
        console.log(`Error while reading message ${message.id}`, error.response.data);
      });
    }
  }

  const deleteMessage = (message: Message) => {
    if (appState.person.id === message.receiverId) {
      postDeletedMessage(message.id)
      .then(res => {
        if (res.data.status !== Status.SUCCESS) {
          console.log(`Error while deleting a message ${message.id}`, res.data.message);
        }
        else {
          var messages = appState.messages.filter(m => m.id !== message.id);
          setAppState(appState => ({...appState, messages}));
        }
      })
    }
  }

  return (
    <Expander onOpenChange={(open: boolean) => readMessage(msg, open)}>
      <ExpanderTitleButton>
        <FlexRow
          css= {{
            color: msg.isRead ? tokens.colors.blackBase : tokens.colors.highlightDark1
          }}
        >
          <div>
            {toDateString(msg.createdOn)}
          </div>
          <div>
            {msg.type === MessageType.EVENT && msg.source === "EventGrid" && EventSubject(msg.jsonData)}
            {msg.type === MessageType.EVENT && msg.source === "X-Road" && XRoadSubject(msg)}
            {(msg.type !== MessageType.EVENT || (msg.source !== "EventGrid" && msg.source !== "X-Road")) && msg.subject}
          </div>
          <div>
            {msg.source}
          </div>
        </FlexRow>
      </ExpanderTitleButton>
      <ExpanderContent>
        {msg.type === MessageType.MESSAGE ? msg.jsonData.body : <EventContent msg={msg}/>}
        <ButtonLinkSmall onClick={() => unReadMessage(msg)}>
          Merkitse lukemattomaksi
        </ButtonLinkSmall>
        <ButtonLinkSmall className={"right"} onClick={() => deleteMessage(msg)}>
          <IconRemove icon="remove" />
          Poista viesti
        </ButtonLinkSmall>
      </ExpanderContent>
    </Expander>
  );
}

type Props = {
  msg: Message
}

export default MessageListItem;