/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { FlexColTight, panelWithShadowCss, Placeholder, TableSmall } from "../common/CommonStyles";
import { Button, Heading, Text } from "suomifi-ui-components";
import { suomifiDesignTokens as sdt } from "suomifi-design-tokens";
import Spinner from "../common/Spinner";
import { usePrenuptial } from "./usePrenuptial";
import { Link, useNavigate } from "react-router-dom";
import { NIL_UUID } from "../../utils/uuidUtils";
import { Prenuptial } from "../common/DomainTypes";
import { fiLabelForPrenuptialType } from "../common/DefaultValues";

const PrenuptialWidget: React.FC = () => {
  const navigate = useNavigate();
  const {prenuptial, loadingPrenuptial} = usePrenuptial();

  const renderPrenuptial = (prenuptial: Prenuptial) => (
      <div>
        <Text variant="bold">{fiLabelForPrenuptialType(prenuptial.type)}:</Text><br/>
        {prenuptial.clause}
        <TableSmall>
          <tbody>
          {prenuptial.parties.map((party, i) => (
              <tr key={i}>
                <th>{party.firstName} {party.lastName}</th>
                <td className={"right"}>osapuoli</td>
              </tr>)
          )}
          </tbody>
        </TableSmall>
      </div>
  );

  const renderCallToPrenuptial = () => (
      <Placeholder>
        <div style={{marginBottom: sdt.spacing.s}}>
          Avioehtoa ei ole vielä laadittu
        </div>
        <Button variant="secondaryNoBorder"
            icon={"edit"}
            onClick={() => navigate("/avioehto/muokkaa")}>
          Laadi avioehto
        </Button>
      </Placeholder>
  );

  return (
      <FlexColTight css={panelWithShadowCss}>
        <Heading variant="h2" smallScreen>
          Avioehto
        </Heading>

        {loadingPrenuptial
            ? <Spinner style={{height: "100%"}}/>
            : prenuptial.id !== NIL_UUID
                ? renderPrenuptial(prenuptial)
                : renderCallToPrenuptial()}

        <div style={{marginTop: "auto"}}>
          <Link to={"/avioehto"}>
            <span style={{color: sdt.colors.accentBase}}>&rsaquo; </span>
            Tee tai päivitä avioehtoa
          </Link>
        </div>
      </FlexColTight>
  );
};

export default PrenuptialWidget;
