import { EstateParty, Person } from "../common/DomainTypes";
import axios from "axios";

export const getParties = (personId: string): Promise<EstateParty[]> => {
  return axios(`${process.env.REACT_APP_API_URL}/henkilo/${personId}/kuolinpesaosakkaat`)
  .then(res => {
    return (res.data.Henkilo || [])
    .map(mapParty)
    .filter((party: EstateParty) => party.person.id !== personId);
  });
};

const mapParty = (data: any): EstateParty => {
  return {
    relationship: data.Suhde ? data.Suhde.Roolilaji : 0,
    person: mapPerson(data)
  }
};

const mapPerson = (data: any): Person => {
  return {
    id: data.Id,
    ssn: data.Henkilotunnus,
    firstName: data.Etunimi,
    lastName: data.Sukunimi,
    fullName: `${data.Etunimi} ${data.Sukunimi}`,
    primaryLanguage: "fi",
    nationality: "FIN",
    childCount: 0,
    address: {
      street: `${data.Kadunnimi} ${data.Osoitenumero}`,
      zip: data.Postinumero,
      city: data.Postitoimipaikka,
    }
  }
};
