export const currentIsoDate = (): string => {
  return new Date().toISOString().substring(0, 10);
};

export const currentTimestamp = (): string => {
  return Date.now().toString(10);
};

export const toFiDateString = (isoDate: string) => {
  return new Date(isoDate).toLocaleDateString("fi-FI");
};

export const toDateString = (date?: Date | string) => {
  if (date instanceof Date) {
    date.toLocaleDateString("fi-FI");
  }
  else if (date) {
    return new Date(date).toLocaleDateString("fi-FI");
  }
}