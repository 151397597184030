import React from "react";
import { TextField, Typography, Checkbox, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { Button, suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { FlexCol, FlexRow } from "../../common/CommonStyles";
import Card from "../../common/Card";
import { FormikProps } from "formik";
import * as Yup from 'yup';
import GenericForm from "../GenericForm";
import { sendGenericForm } from "../../../api/omaelama-functions";
import { GenericEvent, GenericEventPayload, GenericFormType, NotifierType } from "../GenericFormTypes";

export enum ReasonOfTermination {
  FIXED_TERM = "maaraaikaisuus",
  TERMINATION = "irtisanominen",
  RESIGNATION = "irtisanoutuminen",
  CANCELLATION = "purkaminen"
}

// This form uses a custom event
export interface TerminationOfEmploymentPayload extends GenericEvent {
  reason?: ReasonOfTermination;
  description?: string;
  readmission: boolean;
}

interface FormModel {
  firstName: string;
  lastName: string;
  personalIdentityCode: string;

  eventTime?: Date;
  reason?: ReasonOfTermination;
  description?: string;
  readmission: boolean;

  employerIdentifier: string;
}

const defaultValues: FormModel = {
  firstName: "",
  lastName: "",
  personalIdentityCode: "",

  eventTime: undefined,
  reason: undefined,
  description: undefined,
  readmission: false,

  employerIdentifier: ""
}

const validationSchema: Yup.ObjectSchema<FormModel> = Yup.object({
  firstName: Yup.string()
    .min(2, 'Etunimen pituus oltava vähintään 2 merkkiä')
    .max(50, 'Etunimen pituus voi olla enintään 50 merkkiä')
    .required('Pakollinen tieto'),
  lastName: Yup.string()
    .min(2, 'Sukunimen pituus oltava vähintään 2 merkkiä')
    .max(50, 'Sukunimen pituus voi olla enintään 50 merkkiä')
    .required('Pakollinen tieto'),
  personalIdentityCode: Yup.string()
    .length(11, 'Henkilötunnuksen pituus oltava 11 merkkiä')
    .required('Pakollinen tieto'),
  eventTime: Yup.date()
    .required('Pakollinen tieto'),
  reason: Yup.mixed<ReasonOfTermination>()
    .oneOf(Object.values(ReasonOfTermination))
    .required('Pakollinen tieto'),
  description: Yup.string()
    .min(3, 'Tekstin pituus oltava vähintään 3 merkkiä')
    .max(500, 'Tekstin pituus voi olla enintään 256 merkkiä'),
  readmission: Yup.boolean().defined(),
  employerIdentifier: Yup.string()
    .min(2, 'Tunnisteen pituus oltava vähintään 2 merkkiä')
    .max(50, 'Tunnisteen pituus voi olla enintään 11 merkkiä')
    .required('Pakollinen tieto'),
});

export const Form: React.FC<FormikProps<any>> = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  touched,
  values,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Card title="Työsuhteen päättyminen">
      <FlexCol>
          <Typography>Työntekijän tiedot</Typography>
          <FlexCol>
            <FlexRow>
              <TextField 
                name="firstName" 
                label="Etunimi" 
                value={values.firstName} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.firstName && Boolean(errors.firstName)} 
                helperText={touched.firstName && errors.firstName as any} 
                variant="outlined" 
                required 
              />
              <TextField 
                name="lastName" 
                label="Sukunimi" 
                value={values.lastName} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.lastName && Boolean(errors.lastName)} 
                helperText={touched.lastName && errors.lastName as any} 
                variant="outlined" 
                required 
              />
              <TextField 
                name="personalIdentityCode" 
                label="Henkilötunnus" 
                value={values.personalIdentityCode} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.personalIdentityCode && Boolean(errors.personalIdentityCode)} 
                helperText={touched.personalIdentityCode && errors.personalIdentityCode as any} 
                variant="outlined" 
                required 
              />
            </FlexRow>
          </FlexCol>
          <br/>
          <Typography>Työsuhteen päättymiseen liittyvät tiedot</Typography>
          <FlexCol>
            <FlexRow>
              <TextField 
                type="datetime-local" 
                InputLabelProps={{ shrink: true }} 
                name="eventTime" 
                label="Työsuhteen päättymispäivä" 
                value={values.eventTime} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.eventTime && Boolean(errors.eventTime)} 
                helperText={touched.eventTime && errors.eventTime as any} 
                variant="outlined" 
                required 
              />
            </FlexRow>
            <FlexRow>
              <Typography>Työsuhteen päättymisen syy</Typography>
              <RadioGroup
                name="reason"
                onChange={handleChange} 
                onBlur={handleBlur}
              >
                <FormControlLabel value={ReasonOfTermination.FIXED_TERM} control={<Radio />} label="Määräaikaisuus" />
                <FormControlLabel value={ReasonOfTermination.RESIGNATION} control={<Radio />} label="Työntekijän päättämä irtisanoutuminen" />
                <FormControlLabel value={ReasonOfTermination.TERMINATION} control={<Radio />} label="Työnantajan päättämä irtisanominen" />
                <FormControlLabel value={ReasonOfTermination.CANCELLATION} control={<Radio />} label="Työsopimuksen purkaminen" />
              </RadioGroup>
            </FlexRow>
            <FlexRow>
              <TextField 
                rows={3} 
                name="description" 
                label="Työsuhteen päättymisen perustelut" 
                value={values.description} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.description && Boolean(errors.description)} 
                helperText={touched.description && errors.description as any} 
                variant="outlined" 
              />
            </FlexRow>
            <FlexRow>
              <Typography>Työnantajan takaisinottovelvollisuus</Typography>
              <Checkbox 
                name="readmission" 
                checked={values.readmission} 
                onChange={handleChange} 
                onBlur={handleBlur} 
              />
            </FlexRow>
          </FlexCol>
          <br/>
          <Typography>Työnantajan tiedot</Typography>
          <FlexCol>
            <FlexRow>
              <TextField 
                name="employerIdentifier" 
                label="Työnantajan tunnus" 
                value={values.employerIdentifier} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.employerIdentifier && Boolean(errors.employerIdentifier)} 
                helperText={touched.employerIdentifier && errors.employerIdentifier as any} 
                variant="outlined" 
              />
            </FlexRow>
          </FlexCol>
          <Button type="submit" style={{margin: `${tokens.spacing.m} 0`}}>
            Lähetä
          </Button>
        </FlexCol>
      </Card>
    </form>
  )
}

const TerminationOfEmployment: React.FC = () => {

  const handleSubmit = async (values: FormModel) => {
    const eventData: TerminationOfEmploymentPayload = {
      eventTime: values.eventTime,
      reason: values.reason,
      description: values.description,
      readmission : values.readmission
    }
    const postData: GenericEventPayload = {
      person: {
        firstName: values.firstName,
        lastName: values.lastName,
        personalIdentityCode: values.personalIdentityCode,
      },
      eventData,
      notifier: {
        id: values.employerIdentifier,
        type: NotifierType.ORGANIZATION
      }
    }
    return sendGenericForm({ type: GenericFormType.TERMINATION_OF_EMPLOYMENT, payload: postData});
  }

  return (
    <GenericForm 
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      form={Form}
      validationSchema={validationSchema}
    />
  );
}

export default TerminationOfEmployment;