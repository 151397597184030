/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Button, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import * as _ from "lodash";
import { v4 } from "uuid";
import "react-datepicker/dist/react-datepicker.css";
import { assets } from "./model/assets";
import { FlexCol, panelWithShadowCss } from "../common/CommonStyles";
import ValueDetailsEditor from "./valueDetailsEditor";
import { companyValueDetails, valueDetails, totalValueDetails } from "./model/valueDetails";

class AssetEditor extends React.Component<any, assets> {
  constructor(props: any) {
      super(props);
      this.state = props.data as assets;
  }

  handleChange(e: any) {
    let change = this.state;
    _.set(change, e.target.name, e.target.value)
    this.setState(change)
  }

  addNewItem(itemName: string, item: any){
    var list = _.get(this.state, itemName);

    if (list === null || list === undefined){
      let change = this.state;
      _.set(change, itemName, []);
    }
    
    list = _.get(this.state, itemName);
    //var item = {} as any;

    item.id = v4();
    list.push(item);
    var change = {} as any
    _.set(change, itemName, list);
    this.setState(change);
  }

  setChildState(item: any, array: any, index: number){
    var list = _.get(this.state, array);
    list[index] = item;
    var change = {};
    _.set(change, array, list)
    this.setState(change)
  }

  
  refreshProperties(item: any, entity: any){
    _.set(this.state, entity, item);
  }

  removeItem (item: number, array: string) {
        var list = _.get(this.state, array);
        list.splice(item, 1);
        var change = {} as any
        //change.set.advancements = list;
        _.set(change, array, list);
        this.setState(change);
  }

  render() {
    return (
            <div>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Julkisesti noteeratut arvopaperit</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{padding: 10 }}>
                  {
                    this.state.publiclyTradedShares.map(item => (
                      <div css={panelWithShadowCss} style={{width: 1000}}>
                      <ValueDetailsEditor
                      removeItem={this.removeItem.bind(this)} 
                      key={item.id}
                      data={item} 
                      refresh={this.setChildState.bind(this)}
                      index={this.state.publiclyTradedShares.indexOf(item)}
                      array="publiclyTradedShares"
                      ></ValueDetailsEditor>
                      </div>
                    ))
                  }
                  <Button color="primary" onClick={() => this.addNewItem("publiclyTradedShares", new totalValueDetails() as any)} style={{width: 100}}>Lisää uusi</Button>
              </div>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Asunto-osakkeet</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <div style={{padding: 10 }}>
                  {
                    this.state.housingAssociationShares.map(item => (
                      <FlexCol css={panelWithShadowCss}>
                      <ValueDetailsEditor
                      removeItem={this.removeItem.bind(this)} 
                      key={item.id}
                      data={item} 
                      refresh={this.setChildState.bind(this)}
                      index={this.state.housingAssociationShares.indexOf(item)}
                      array="housingAssociationShares"
                      ></ValueDetailsEditor>
                      </FlexCol>
                    ))
                  }
                  <Button color="primary" onClick={() => this.addNewItem("housingAssociationShares", new companyValueDetails())} style={{width: 100}}>Lisää uusi</Button>
                </div>
              </AccordionDetails>
            </Accordion>


            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Kiinteistöt</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <div style={{padding: 10 }}>
                  {
                    this.state.realEstates.map(item => (
                      <FlexCol css={panelWithShadowCss}>
                      <ValueDetailsEditor
                      removeItem={this.removeItem.bind(this)} 
                      key={item.id}
                      data={item} 
                      refresh={this.setChildState.bind(this)}
                      index={this.state.realEstates.indexOf(item)}
                      array="realEstates"
                      ></ValueDetailsEditor>
                      </FlexCol>
                    ))
                  }
                  <Button color="primary" onClick={() => this.addNewItem("realEstates", new valueDetails())} style={{width: 100}}>Lisää uusi</Button>
              </div>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Vapaa-ajan kiinteistöt</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <div style={{padding: 10 }}>
                  {
                    this.state.leisureRealEstates.map(item => (
                      <FlexCol css={panelWithShadowCss}>
                      <ValueDetailsEditor
                      removeItem={this.removeItem.bind(this)} 
                      key={item.id}
                      data={item} 
                      refresh={this.setChildState.bind(this)}
                      index={this.state.leisureRealEstates.indexOf(item)}
                      array="leisureRealEstates"
                      ></ValueDetailsEditor>
                      </FlexCol>
                    ))
                  }
                  <Button color="primary" onClick={() => this.addNewItem("leisureRealEstates", new valueDetails())} style={{width: 100}}>Lisää uusi</Button>
                  </div>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Noteeraamattomat osakkeet</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <div style={{padding: 10 }}>
                  {
                    this.state.nonListedCompanyShares.map(item => (
                      <FlexCol css={panelWithShadowCss}>
                      <ValueDetailsEditor
                      removeItem={this.removeItem.bind(this)} 
                      key={item.id}
                      data={item} 
                      refresh={this.setChildState.bind(this)}
                      index={this.state.nonListedCompanyShares.indexOf(item)}
                      array="nonListedCompanyShares"
                      ></ValueDetailsEditor>
                      </FlexCol>
                    ))
                  }
                  <Button color="primary" onClick={() => this.addNewItem("nonListedCompanyShares", new valueDetails())} style={{width: 100}}>Lisää uusi</Button>
              </div>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Henkilöyhtiöt</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <div style={{padding: 10 }}>
                  {
                    this.state.partnerships.map(item => (
                      <FlexCol css={panelWithShadowCss}>
                      <ValueDetailsEditor
                      removeItem={this.removeItem.bind(this)} 
                      key={item.id}
                      data={item} 
                      refresh={this.setChildState.bind(this)}
                      index={this.state.partnerships.indexOf(item)}
                      array="partnerships"
                      ></ValueDetailsEditor>
                      </FlexCol>
                    ))
                  }
                  <Button color="primary" onClick={() => this.addNewItem("partnerships", new valueDetails())} style={{width: 100}}>Lisää uusi</Button>
              </div>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Kapitalisaatiosopimus</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <div style={{padding: 10 }}>
                  {
                    this.state.capitalRedemptionPolicy.map(item => (
                      <FlexCol css={panelWithShadowCss}>
                      <ValueDetailsEditor
                      removeItem={this.removeItem.bind(this)} 
                      key={item.id}
                      data={item} 
                      refresh={this.setChildState.bind(this)}
                      index={this.state.capitalRedemptionPolicy.indexOf(item)}
                      array="capitalRedemptionPolicy"
                      ></ValueDetailsEditor>
                      </FlexCol>
                    ))
                  }
                  <Button color="primary" onClick={() => this.addNewItem("capitalRedemptionPolicy", new valueDetails())} style={{width: 100}}>Lisää uusi</Button>
              </div>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Tilivarat</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <div style={{padding: 10 }}>
                  {
                    this.state.bankAccounts.map(item => (
                      <FlexCol css={panelWithShadowCss}>
                      <ValueDetailsEditor
                      removeItem={this.removeItem.bind(this)} 
                      key={item.id}
                      data={item} 
                      refresh={this.setChildState.bind(this)}
                      index={this.state.bankAccounts.indexOf(item)}
                      array="bankAccounts"
                      ></ValueDetailsEditor>
                      </FlexCol>
                    ))
                  }
                  <Button color="primary" onClick={() => this.addNewItem("bankAccounts", new valueDetails())} style={{width: 100}}>Lisää uusi</Button>
              </div>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Osuus kuolinpesässä</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <div style={{padding: 10 }}>
                  {
                    this.state.partInAnEstate.map(item => (
                      <FlexCol css={panelWithShadowCss}>
                      <ValueDetailsEditor
                      removeItem={this.removeItem.bind(this)} 
                      key={item.id}
                      data={item} 
                      refresh={this.setChildState.bind(this)}
                      index={this.state.partInAnEstate.indexOf(item)}
                      array="partInAnEstate"
                      ></ValueDetailsEditor>
                      </FlexCol>
                    ))
                  }
                  <Button color="primary" onClick={() => this.addNewItem("partInAnEstate", new valueDetails())} style={{width: 100}}>Lisää uusi</Button>
              </div>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Ulkomailla sijaitseva varallisuus</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <div style={{padding: 10 }}>
                  {
                    this.state.assetsOutsideOfFinland.map(item => (
                      <FlexCol css={panelWithShadowCss}>
                      <ValueDetailsEditor
                      removeItem={this.removeItem.bind(this)} 
                      key={item.id}
                      data={item} 
                      refresh={this.setChildState.bind(this)}
                      index={this.state.assetsOutsideOfFinland.indexOf(item)}
                      array="assetsOutsideOfFinland"
                      ></ValueDetailsEditor>
                      </FlexCol>
                    ))
                  }
                  <Button color="primary" onClick={() => this.addNewItem("assetsOutsideOfFinland", new valueDetails())} style={{width: 100}}>Lisää uusi</Button>
              </div>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Rekisteröidyt ajoneuvot</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <div style={{padding: 10 }}>
                  {
                    this.state.vehicles.map(item => (
                      <FlexCol css={panelWithShadowCss}>
                      <ValueDetailsEditor
                      removeItem={this.removeItem.bind(this)} 
                      key={item.id}
                      data={item} 
                      refresh={this.setChildState.bind(this)}
                      index={this.state.vehicles.indexOf(item)}
                      array="vehicles"
                      ></ValueDetailsEditor>
                      </FlexCol>
                    ))
                  }
                  <Button color="primary" onClick={() => this.addNewItem("vehicles", new valueDetails())} style={{width: 100}}>Lisää uusi</Button>
              </div>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography>Muu varallisuus</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <div style={{padding: 10 }}>
                  {
                    this.state.otherAssets.map(item => (
                      <FlexCol css={panelWithShadowCss}>
                      <ValueDetailsEditor
                      removeItem={this.removeItem.bind(this)} 
                      key={item.id}
                      data={item} 
                      refresh={this.setChildState.bind(this)}
                      index={this.state.otherAssets.indexOf(item)}
                      array="otherAssets"
                      ></ValueDetailsEditor>
                      </FlexCol>
                    ))
                  }
                  <Button color="primary" onClick={() => this.addNewItem("otherAssets", new valueDetails())} style={{width: 100}}>Lisää uusi</Button>
              </div>
              </AccordionDetails>
            </Accordion>

            </div>
      );
  }
}

export default AssetEditor;
