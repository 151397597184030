import React from "react";
import { EventGridEvent } from "../../common/DomainTypes";
import { FlexCol, FlexRow, TableSmall } from "../../common/CommonStyles";
import { toDateString } from "../../../utils/dateUtils";
import { GenericEventPayload } from "../../forms/GenericFormTypes";

type Props = {
  event: EventGridEvent<GenericEventPayload>
}

const DefaultGenericFormEvent: React.FC<Props> = ({event}: Props) => {
  return (
    <FlexCol>
      <FlexRow css={{
        "& > *": {
          boxSizing: "border-box",
          minHeight: "300px"
        }
      }}>
        <TableSmall>
          <tbody>
            { event.Data.person.firstName && event.Data.person.lastName && 
              <tr>
                <th>Nimi</th>
                <td className="right">{event.Data.person.firstName} {event.Data.person.lastName}</td>
              </tr>
            }
            { event.Data.person.personalIdentityCode && 
              <tr>
                <th>Henkilötunnus</th>
                <td className="right">{event.Data.person.personalIdentityCode}</td>
              </tr>
            }
            { event.Data.eventData.eventTime && 
              <tr>
                <th>Tapahtuma-aika</th>
                <td className="right">
                  {toDateString(event.Data.eventData.eventTime)}
                </td>
              </tr>
            }
            { event.Data.eventData.description &&
              <tr>
                <th>Lisätiedot</th>
                <td className="right">{event.Data.eventData.description}</td>
              </tr>
            }
          </tbody>
        </TableSmall>
      </FlexRow>
    </FlexCol>
  );
}

export default DefaultGenericFormEvent;