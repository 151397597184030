/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { FlexColTight, panelWithShadowCss, TableSmall } from "../common/CommonStyles";
import { Heading, Link } from "suomifi-ui-components";
import { suomifiDesignTokens as sdt } from "suomifi-design-tokens";
import Spinner from "../common/Spinner";
import { fiLabelForRelationship } from "../common/DefaultValues";
import { useParties } from "./useParties";

const PartiesWidget: React.FC = () => {
  const {parties, loadingParties} = useParties();

  return (
      <FlexColTight css={panelWithShadowCss}>
        <Heading variant="h2" smallScreen>
          Osakkaat
        </Heading>

        {loadingParties ? <Spinner style={{height: "100%"}}/> :
            <TableSmall>
              <tbody>
              {parties.map((entry, i) => {
                return <tr key={i}>
                  <th>{entry.person.firstName} {entry.person.lastName}</th>
                  <td className="right">{fiLabelForRelationship(entry.relationship)}</td>
                </tr>
              })}
              </tbody>
            </TableSmall>
        }

        <div style={{marginTop: "auto"}}>
          <Link href="/will">
            <span style={{color: sdt.colors.accentBase}}>&rsaquo; </span>
            Tee tai päivitä testamenttia
          </Link>
        </div>
      </FlexColTight>
  );
};

export default PartiesWidget;
