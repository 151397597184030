/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { Button, Heading, suomifiDesignTokens as sdt } from "suomifi-ui-components";
import { FlexColTight, panelWithShadowCss, Placeholder, TableSmall } from "../common/CommonStyles";
import Spinner from "../common/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { Party, Will, WillAsset } from "../common/DomainTypes";
import { useWill } from "./useWill";
import { NIL_UUID } from "../../utils/uuidUtils";
import { toFiDateString } from "../../utils/dateUtils";

const WillWidget: React.FC = () => {
  const navigate = useNavigate();
  const {will, loadingWill} = useWill();

  const renderWill = (will: Will) => (
      <div>
        {will.clause}
        <TableSmall>
          <tbody>
          {will.assets.map((asset, i) => renderAsset(asset, i))}
          </tbody>
        </TableSmall>
      </div>
  );

  const renderAsset = (asset: WillAsset, i: number) => (
      <tr key={i}>
        <td style={{verticalAlign: "top"}}>
          {asset.name}<br/>
          +{asset.value} €
        </td>
        <td className={"right"} style={{verticalAlign: "top"}}>
          <ul style={{listStyleType: "none"}}>
            {asset.parties.map((party, j) => renderParty(party, j))}
          </ul>
        </td>
      </tr>
  );

  const renderParty = (party: Party, i: number) => (
      <li key={i}>
        <strong>{party.person.firstName} {party.person.lastName}</strong>
        &nbsp;
        {party.share} osaa
      </li>
  );

  const renderCallToWill = () => (
      <Placeholder>
        <div style={{marginBottom: sdt.spacing.s}}>
          Testamenttia ei ole vielä laadittu
        </div>
        <Button variant="secondaryNoBorder"
            icon={"edit"}
            onClick={() => navigate("/testamentti/muokkaa")}>
          Laadi testamentti
        </Button>
      </Placeholder>
  );

  return (
      <FlexColTight css={panelWithShadowCss}>
        <Heading variant="h2" smallScreen>
          Testamentti
        </Heading>

        <div style={{marginBottom: sdt.spacing.l, height: "100%"}}>
          {loadingWill
              ? <Spinner style={{height: "100%"}}/>
              : will.id !== NIL_UUID
                  ? renderWill(will)
                  : renderCallToWill()}
        </div>

        <div style={{marginTop: "auto"}}>
          <Link to={"/testamentti"}>
            <span style={{color: sdt.colors.accentBase}}>&rsaquo; </span>
            Katso koko testamentti
            &nbsp;
            {will.id !== NIL_UUID
                ? <span style={{color: sdt.colors.depthBase}}>
                  (päivitetty {toFiDateString(will.date)})</span>
                : ""}
          </Link>
        </div>
      </FlexColTight>
  );
};

export default WillWidget;
