import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { Message } from '../components/common/DomainTypes';
import { GenericFormPayload } from '../components/forms/GenericFormTypes';
import { DeathReport } from "../components/forms/reportDeath/model/ReportDeathFormModel";
import { Assets } from '../model/assets';

const apiRootUrl = process.env.REACT_APP_API_ROOT_URL || 'http://localhost:7071/api'
const apiKey = process.env.REACT_APP_API_KEY
const tokenKey = "x-omaelama-access-token";

const requestInterceptor = (config: InternalAxiosRequestConfig<any>) => {
  const token = sessionStorage.getItem(tokenKey);
  if (token && config.headers) {
    config.headers[tokenKey] = token;
  }
  return config;
}

const responseInterceptor = (response: AxiosResponse<any>) => {
  const token = response.headers[tokenKey];
  if (token) {
    sessionStorage.setItem(tokenKey, token);
  }
  return response;
}

const api = axios.create({
  baseURL: apiRootUrl,
  headers: {
    "x-functions-key": apiKey
  },
  withCredentials: true
});

api.interceptors.request.use(requestInterceptor);
api.interceptors.response.use(responseInterceptor);

export interface JsonResponse {
  status: Status;
  message: string;
}

export enum Status {
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

export default api;

export async function login(ssn: string): Promise<AxiosResponse<any>> {
  return api.get("/LoginUser", {params: {ssn}});
}

export async function logout(): Promise<any> {
  sessionStorage.removeItem(tokenKey);
  return new Promise(resolve => resolve("Logged out"));
}

export async function fetchCurrentUser(): Promise<any> {
  const token = sessionStorage.getItem(tokenKey);
  if (token) {
    return api.get("/GetCurrentUser");
  }
  return new Promise((resolve, reject) => reject("Not logged in"));
}

export async function GetUserMessages(): Promise<AxiosResponse<Message[]>> {
  const token = sessionStorage.getItem(tokenKey);
  if (token) {
    return api.get<Message[]>("/Messages/Search/User");
  }
  return new Promise((resolve, reject) => reject("Not logged in"));
}

export async function postReadMessage(msg: string): Promise<AxiosResponse<JsonResponse>> {
  return api.post<JsonResponse>("/Messages/Read", null, {params: {msg}});
}

export async function postUnReadMessage(msg: string): Promise<AxiosResponse<JsonResponse>> {
  return api.post<JsonResponse>("/Messages/UnRead", null, {params: {msg}});
}

export async function postDeletedMessage(msg: string): Promise<AxiosResponse<JsonResponse>> {
  return api.post<JsonResponse>("/Messages/Delete", null, {params: {msg}});
}

export async function sendGenericForm(data: GenericFormPayload): Promise<AxiosResponse<JsonResponse>> {
  return api.post<JsonResponse>("/PostGenericForm", data);
}

export async function GetPersonAssets(personId: string): Promise<AxiosResponse<Assets>> {
  return api.get<Assets>(`/Persons/${personId}/Assets`);
}