import React, { useState } from "react";
import NotificationBox from "../common/NotificationBox";
import { panelWithShadowCss } from "../common/CommonStyles";
import { withFormik } from "formik";
import { GenericFormProps, GenericFormParams } from "./GenericFormTypes";

const createGenericFormikForm = (props: GenericFormProps) => {
  return withFormik<GenericFormParams, any>({
    mapPropsToValues: ({ initialValues }) => {
      return {
        ...initialValues
      };
    },
    validationSchema: ((props: GenericFormParams) => props.validationSchema),
    handleSubmit: async (values, formikBag) => {
      formikBag.props.onSubmit(values)
      .then(resp => {
        formikBag.props.setNotificationMessage(resp.data.message);
        formikBag.props.setNotificationLevel(resp.data.status);
      })
      .catch(error => {
        console.log("Error resp: ", error);
        formikBag.props.setNotificationMessage(error.response.data.message);
        formikBag.props.setNotificationLevel(error.response.data.status);
      })
    }
  })(props.form)
};

const GenericForm: React.FC<GenericFormProps> = (props: GenericFormProps) => {
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationLevel, setNotificationLevel] = useState("error");

  // const formModel = props.items.reduce((obj, item)  => ({...obj, [item.fieldName]: item.defaultValue}), {});

  const FormikForm = createGenericFormikForm(props);

  return (
    <>
      {
        notificationMessage && 
        <NotificationBox 
          message={notificationMessage} 
          level={notificationLevel} 
          css={panelWithShadowCss}/>
      }
      <FormikForm 
        initialValues={props.defaultValues}
        validationSchema={props.validationSchema}
        onSubmit={props.onSubmit}
        setNotificationMessage={setNotificationMessage}
        setNotificationLevel={setNotificationLevel}
      />
    </>
  )
};

export default GenericForm;