import { Dispatch, SetStateAction } from "react";
import { NULL_PERSON } from "../components/common/DefaultValues";
import { Message, Person } from "../components/common/DomainTypes";
import { fetchCurrentUser, fetchUserMessages } from "./actions";
import { DecodedToken } from "../utils/tokenUtil";

export interface AppState {
  person: Person;
  isLoadingPerson: boolean;
  decodedToken?: DecodedToken;
  messages: Message[];
}

export function initialState(): AppState {
  return {
    person: NULL_PERSON,
    isLoadingPerson: true,
    messages: [],
  };
}

export function loadAppState(appState: AppState, setAppState: Dispatch<SetStateAction<AppState>>) {
  loadAppStateAsync(appState, setAppState);
}

export async function loadAppStateAsync(appState: AppState, setAppState: Dispatch<SetStateAction<AppState>>): Promise<any> {
  // decodeToken()
  // .then(decodedToken => {
  //   setState(state => ({...state, decodedToken}));
  //   return fetchCurrentUser();
  // })
  return fetchCurrentUser()
  .then(data => {
    const person = parsePerson(data);
    person.personRelations?.forEach(pr => {
      if (pr.relatedPerson) {
        pr.relatedPerson = parsePerson(pr.relatedPerson);
      }
    });
    setAppState(appState => ({...appState, person: parsePerson(data), isLoadingPerson: false}));
    return fetchUserMessages();
  })
  .then(messages => {
    setAppState(appState => ({...appState, messages}));
  })
  .catch(err => {
    console.log("Error", err);
    setAppState(appState => ({...appState, decodedToken: undefined, person: NULL_PERSON, isLoadingPerson: false}));
  });
}

const parsePerson = (data: any): Person => {
  return {
    id: data.id,
    ssn: data.code,
    firstName: data.firstNames,
    lastName: data.lastName,
    fullName: `${data.firstNames} ${data.lastName}`,
    birthday: new Date(Date.parse(data.birthday)),
    primaryLanguage: data.primaryLanguage,
    nationality: data.nationality,
    childCount: data.childCount,
    dateOfDeath: data.dateOfDeath ? new Date(Date.parse(data.dateOfDeath)) : undefined,
    deathInfo: data.deathInfo,
    address: data.addresses ? {
      street: data.addresses[0]?.address,
      zip: data.addresses[0]?.postalCode,
      city: data.addresses[0]?.postalDistrict
    } : undefined,
    personRelations: data.personRelations
    //   ...data.personRelations,
    //   relatedPerson: data.personRelations.relatedPerson ? parsePerson(data.personRelations.relatedPerson) : null
    // } : null
  };
}