import { Asset } from "../common/DomainTypes";
import axios from "axios";
import { GetPersonAssets } from "../../api/omaelama-functions";
import * as RhrApi from "../../api/RhrApi";
import * as TaxApi from "../../api/TaxAdministrationApi";
import { AssetsAppData, BuildingAppData, BuildingAsset } from "../../model/assets";

// This fetches data from old test api. Not in use anymore.
export const getAssets = (personId: string): Promise<Asset[]> => {
  return axios(`${process.env.REACT_APP_API_URL}/henkilo/${personId}/varallisuus`)
  .then(res => {
    return (res.data.Varallisuusera || []).map(mapAsset);
  });
};

const mapAsset = (data: any): Asset => {
  return {
    id: data.Id,
    name: data.Nimi,
    value: data.Arvo,
    count: data.Lukumaara,
  };
};

export const getPersonsAssetsAppData = (personId: string): Promise<AssetsAppData> => {
  return GetPersonAssets(personId)
  .then(res => {
    if (res.data) {
      return createBuildingAppData(res.data.buildings);
    }
  })
  .then(res => {
    return {ownerId: personId, buildings: res} as AssetsAppData;
  });
};

const createBuildingAppData = (buildings: BuildingAsset[]) => {
  return Promise.all(buildings.map(async b => {
    var buildingData = await RhrApi.GetBuildingById(b.permanentBuildingID);
    var rhrAddress = await RhrApi.GetAddress(b.permanentBuildingID)
    var taxAddress = await TaxApi.GetAddress(b.permanentBuildingID)
    var owner = await RhrApi.GetInstanceByID(b.ownerID);
    return { 
      buildingData: {
        "rhr": buildingData
      }, 
      address: {
        "rhr": rhrAddress, 
        "tax": taxAddress
      }, 
      owner: {
        "rhr": owner
      } 
    } as BuildingAppData;
  }));
}