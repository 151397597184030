export interface Person {
  id: string,
  ssn?: string,
  firstName: string,
  lastName: string,
  fullName: string,
  birthday?: Date,
  primaryLanguage: string,
  nationality: string,
  childCount: number,
  dateOfDeath?: Date,
  deathInfo?: PersonDeathInfo,
  address?: Address,
  personRelations?: PersonRelation[],
}

export enum Relationship {
  CHILD = 1,
  SPOUSE = 2,
  PARENT = 3,
  SIBLING = 4,
}

export interface PersonDeathInfo {
  id: string,
  personID: string,
  code: string,
  authorityCode: string,
  archiveNumber: string,
  deathTimeEstimated: boolean,
  cancellationOfDeath: boolean,
  notifierType: string,
  notifierCode: string,
  description?: string,
}

export interface Address {
  street: string,
  zip: string,
  city: string,
}

export interface PersonRelation {
  id: string,
  ownerID: string,
  ownerPersonSsn: string,
  targetPersonSsn: string,
  relatedPerson: Person,
  role: Relationship
}

export interface Asset {
  id: string,
  name: string,
  value: number,
  count: number,
}

export interface EstateParty {
  relationship: EstateRole,
  person: Person,
}

export enum EstateRole {
  WIDOW = 2,
  HEIR = 3,
  WILL_BENEFICIARY = 4,
}

export interface Will {
  id: string,
  clause: string,
  date: string,
  assets: WillAsset[],
}

export interface WillAsset extends Asset {
  parties: Party[],
}

export interface Party {
  share: string,
  person: Person,
}

export interface Prenuptial {
  id: string,
  type: PrenuptialType,
  clause: string,
  date: string,
  parties: Person[],
}

export enum PrenuptialType {
  MUTUALLY_EXCLUSIVE = 1,
  MUTUALLY_PARTIALLY_EXCLUSIVE = 2,
  ONE_SIDED_EXCLUSIVE = 3,
  ONE_SIDED_PARTIALLY_EXCLUSIVE = 4,
}

export interface Transaction {
  amount: number,
  type: {
    label: string,
    code: string
  },
  companyName: string,
  companyBusinessId: string,
  time: Date
}

interface TransactionTotalsByCode {
  code: string,
  total: number
}
export interface TransactionsTotals {
  gross: number,
  net: number,
  monthlyAverage: number,
  monthlyMinimum: number,
  monthlyMaximum: number,
  groupedByCode: Array<TransactionTotalsByCode>
}

export interface Message {
  id: string,
  createdOn: Date,
  subject: string,
  jsonData: any,
  type: MessageType,
  subType?: string,
  senderId?: string,
  receiverId?: string,
  source?: string,
  isRead: boolean
}

export enum MessageType {
  MESSAGE = "message",
  EVENT = "event",
}

export interface SimpleMessage {
  body: string
}

export interface EventGridEvent<T> {
  Id: string,
  Subject: string,
  Data: T,
  EventType: string,
  DataVersion: string,
  MetadataVersion: string,
  EventTime: Date,
  Topic: string
}