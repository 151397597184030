/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { Button, Text } from "suomifi-ui-components";
import { usePrenuptial } from "./usePrenuptial";
import { Heading1, Heading2, Main, Section, Toolbar } from "../common/DocumentStyles";
import { ButtonSecondaryDark } from "../common/InputStyles";
import { useNavigate } from "react-router";
import { fiLabelForPrenuptialType } from "../common/DefaultValues";

const PrenuptialView: React.FC = () => {
  const navigate = useNavigate();
  const {prenuptial} = usePrenuptial();

  return (
      <div>
        <Toolbar>
          <ButtonSecondaryDark variant="secondary" icon={"chevronLeft"} onClick={() => navigate("/perukirja")}>
            Perukirja
          </ButtonSecondaryDark>
          <Button icon={"edit"} onClick={() => navigate("/avioehto/muokkaa")}>
            Muokkaa
          </Button>
        </Toolbar>

        <Main>
          <Heading1 variant="h1hero">
            Avioehto
          </Heading1>

          <Section>
            <Heading2 variant="h2">
              {fiLabelForPrenuptialType(prenuptial.type)}
            </Heading2>
            <Text variant="lead">{prenuptial.clause}</Text>
          </Section>
          <Section>
            <Heading2 variant="h2">
              Osapuolet
            </Heading2>
            {prenuptial.parties.map((party, i) =>
                <span key={i}>{party.firstName} {party.lastName}<br/></span>
            )}
          </Section>
        </Main>
        <br/>
      </div>
  );
};

export default PrenuptialView;
