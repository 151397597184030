import axios from "axios";

interface IObjectKeys {
    [key: string]: string;
}

const apiRootUrl = process.env.API_ROOT_URL || "/example-data"

export const fetchData = async (resourcePath: string): Promise<any> => {
    const normalizedPath = resourcePath.replace(/^\//, "")
    const response = await axios(`${apiRootUrl}/${normalizedPath}`)

    return toCamel(response.data)
}

function toCamel(obj: any) {
    // SO copypaste ¯\_(ツ)_/¯
    if (obj instanceof Array) {
        return obj.map(function (value) {
            if (typeof value === "object") {
                value = toCamel(value)
            }
            return value
        })
    } else {
        var newO: IObjectKeys = {}
        for (let origKey in obj) {
            if (obj.hasOwnProperty(origKey)) {
                const newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
                let value = obj[origKey]
                if (value instanceof Array || (value !== null && value.constructor === Object)) {
                    value = toCamel(value)
                }
                newO[newKey] = value
            }
        }
        return newO
    }
}
