import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { Transaction } from "../common/DomainTypes";

interface GroupedTransactions {
    byMonth: Array<TransactionMonthSummary>
}
interface TransactionMonthSummary {
    month: string,
    total: number,
    items: Array<Transaction>
}

export function useTransactions() {
    const [appState] = useContext(AppContext);
    const person = appState.person;

    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [groupedTransactions, setGroupedTransactions] = useState<GroupedTransactions>()

    useEffect(() => {
        setTimeout(() => {
            setTransactions([
                {
                    companyName: 'Digispan Oy',
                    companyBusinessId: '1234567-8',
                    amount: 1234.5,
                    time: new Date(Date.parse('2020-11-01')),
                    type: {
                        code: '101',
                        label: 'Kokonaissumma'
                    }
                },
                {
                    companyName: 'Digispan Oy',
                    companyBusinessId: '1234567-8',
                    amount: 1234.5,
                    time: new Date(Date.parse('2020-10-01')),
                    type: {
                        code: '101',
                        label: 'Kokonaissumma'
                    }
                }
            ])

            const groupedByMonth = transactions
                .reduce((result: any, item) => {
                    const month = `${item.time.getMonth() + 1}/${item.time.getFullYear()}`
                    if (result[month] === undefined) result[month] = { month, items: [], total: 0 }
                    result[month] = {
                        ...result[month],
                        items: [...result[month].items, item],
                        total: result[month].total + item.amount
                    }
                    return result
                }, {})

            setGroupedTransactions({ byMonth: Object.values(groupedByMonth) })

            setLoading(false)
        }, 500)
    //     getAssets(person.id)
    //         .then(parties => setTransactions(parties))
    //         .finally(() => setLoading(false));
    }, [person, transactions]);

    return { 
        transactions, 
        groupedTransactions, 
        isLoading 
    };
}
