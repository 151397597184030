import axios from "axios";
import { Person } from "../components/common/DomainTypes";
import { Building, OwnerAddress, TaxAdministrationBuilding } from "../model/assets";
import { Company } from "../model/company";

const apiRootUrl = process.env.REACT_APP_RHR_API || 'http://localhost:7071/api'

const rhrApi = axios.create({
  baseURL: apiRootUrl
});

export async function GetBuildingData(permanentBuildingId: string): Promise<TaxAdministrationBuilding> {
  return rhrApi.get(`${apiRootUrl}/${permanentBuildingId}/TaxAdministrationInfo`)
                              .then(resp => resp.data)
                              .catch(err => console.log(`No building info found by ID ${permanentBuildingId}: ${err}`));
}

export async function GetAddress(permanentBuildingId: string): Promise<OwnerAddress> {
  return rhrApi.get(`${apiRootUrl}/${permanentBuildingId}/OwnerAddress`)
                              .then(resp => resp.data)
                              .catch(err => console.log(`No building info found by ID ${permanentBuildingId}: ${err}`));
}

export async function GetBuildingById(id: string): Promise<Building> {
  return rhrApi.get(`${apiRootUrl}/buildings/${id}`)
                              .then(resp => resp.data)
                              .catch(err => console.log(`No building found by ID ${id}: ${err}`));
}

export async function GetInstanceByID(id: string): Promise<any> {
  return rhrApi.get(`${apiRootUrl}/instance/${id}`)
                              .then(resp => resp.data)
                              .catch(err => console.log(`No instance found by ID ${id}: ${err}`));
}