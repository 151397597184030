import React from "react";
import { TextField, Typography } from "@mui/material";
import {Button, suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { FlexCol, FlexRow } from "../common/CommonStyles";
import Card from "../common/Card";
import { FormikProps } from "formik";
import * as Yup from "yup";
import GenericForm from "./GenericForm";
import { sendGenericForm } from "../../api/omaelama-functions";
import { GenericEventPayload, GenericFormType, NotifierType, OrganizationNotifier } from "./GenericFormTypes";
import { Relationship } from "../common/DomainTypes";

interface FormModel {
  dateOfMarriage?: Date;
  ssn: string;
  spouseSsn?: string;

  description?: string;
  tapahtumatunnus?: string;
  viranomaiskoodi?: string;
  arkistonumero?: string;

  notifierIdentifier?: string;
  organisationIdentifier?: string;
}

const defaultValues: FormModel = {
  dateOfMarriage: undefined,
  ssn: "",
  spouseSsn: undefined,

  description: "",
  tapahtumatunnus: undefined,
  viranomaiskoodi: undefined,
  arkistonumero: undefined,

  notifierIdentifier: "",
  organisationIdentifier: ""
}

const validationSchema: Yup.ObjectSchema<FormModel> = Yup.object({
  dateOfMarriage: Yup.date()
    .required("Pakollinen tieto"),
  ssn: Yup.string()
    .length(11, "Henkilötunnuksen pituus oltava 11 merkkiä")
    .required("Pakollinen tieto"),
  spouseSsn: Yup.string()
    .length(11, "Henkilötunnuksen pituus oltava 11 merkkiä")
    .required("Pakollinen tieto"),
  description: Yup.string()
    .min(3, "Tekstin pituus oltava vähintään 3 merkkiä")
    .max(500, "Tekstin pituus voi olla enintään 256 merkkiä"),
  tapahtumatunnus: Yup.string(),
  viranomaiskoodi: Yup.string(),
  arkistonumero: Yup.string(),
  notifierIdentifier: Yup.string()
    .min(2, "Tunnisteen pituus oltava vähintään 2 merkkiä")
    .max(50, "Tunnisteen pituus voi olla enintään 11 merkkiä"),
  organisationIdentifier: Yup.string()
    .min(2, "Tunnisteen pituus oltava vähintään 2 merkkiä")
    .max(50, "Tunnisteen pituus voi olla enintään 11 merkkiä")
});

export const Form: React.FC<FormikProps<any>> = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  touched,
  values,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Card title="Avioliitto">
      <FlexCol>
          <Typography>Avioliiton tiedot</Typography>
          <FlexCol>
            <FlexRow>
              <TextField 
                type="date" 
                InputLabelProps={{ shrink: true }} 
                name="dateOfMarriage" 
                label="Hääpäivä" 
                value={values.dateOfMarriage} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.dateOfMarriage && Boolean(errors.dateOfMarriage)} 
                helperText={touched.dateOfMarriage && errors.dateOfMarriage as any} 
                variant="outlined" 
                required 
              />
            </FlexRow>
            <FlexRow>
              <TextField 
                name="ssn" 
                label="Henkilötunnus" 
                value={values.ssn} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.ssn && Boolean(errors.ssn)} 
                helperText={touched.ssn && errors.ssn as any} 
                variant="outlined" 
                required 
              />
              <TextField 
                name="spouseSsn" 
                label="Puolison henkilötunnus" 
                value={values.spouseSsn} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.spouseSsn && Boolean(errors.spouseSsn)} 
                helperText={touched.spouseSsn && errors.spouseSsn as any} 
                variant="outlined" 
                required 
              />
            </FlexRow>
          </FlexCol>
          <br/>
          <Typography>Tapahtumaan liittyvät tiedot</Typography>
          <FlexCol>
            <FlexRow>
              <TextField 
                rows={3} 
                name="description" 
                label="Lisätiedot" 
                value={values.description} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.description && Boolean(errors.description)} 
                helperText={touched.description && errors.description as any} 
                variant="outlined" 
              />
            </FlexRow>
            <FlexRow>
              <TextField 
                name="tapahtumatunnus" 
                label="Tapahtumatunnus" 
                value={values.tapahtumatunnus} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.tapahtumatunnus && Boolean(errors.tapahtumatunnus)} 
                helperText={touched.tapahtumatunnus && errors.tapahtumatunnus as any} 
                variant="outlined" 
              />
              <TextField 
                name="viranomaiskoodi" 
                label="Viranomaiskoodi" 
                value={values.viranomaiskoodi} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.viranomaiskoodi && Boolean(errors.viranomaiskoodi)} 
                helperText={touched.viranomaiskoodi && errors.viranomaiskoodi as any} 
                variant="outlined" 
              />
              <TextField 
                name="arkistonumero" 
                label="Arkistonumero" 
                value={values.arkistonumero} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.arkistonumero && Boolean(errors.arkistonumero)} 
                helperText={touched.arkistonumero && errors.arkistonumero as any} 
                variant="outlined" 
              />
            </FlexRow>
          </FlexCol>
          <br/>
          <Typography>Ilmoittaja</Typography>
          <FlexCol>
            <FlexRow>
              <TextField 
                name="notifierIdentifier" 
                label="Ilmoittajan tunnus" 
                value={values.notifierIdentifier} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.notifierIdentifier && Boolean(errors.notifierIdentifier)} 
                helperText={touched.notifierIdentifier && errors.notifierIdentifier as any} 
                variant="outlined" 
              />
              <TextField 
                name="organisationIdentifier" 
                label="Organisaation tunnus" 
                value={values.organisationIdentifier} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.organisationIdentifier && Boolean(errors.organisationIdentifier)} 
                helperText={touched.organisationIdentifier && errors.organisationIdentifier as any} 
                variant="outlined" 
              />
            </FlexRow>
          </FlexCol>
          <Button type="submit" style={{margin: `${tokens.spacing.m} 0`}}>
            Lähetä
          </Button>
        </FlexCol>
      </Card>
    </form>
  )
}

const MarriageForm: React.FC = () => {

  const handleSubmit = async (values: FormModel) => {
    const notifier: OrganizationNotifier = {
      id: values.notifierIdentifier,
      type: NotifierType.ORGANIZATION,
      organisaationidtunnus: values.organisationIdentifier

    }
    const postData: GenericEventPayload = {
      person: {
        relations: [
          {
            personalIdentityCode: values.ssn,
            role: Relationship.SPOUSE,
            description: "spouse"
          },
          {
            personalIdentityCode: values.spouseSsn,
            role: Relationship.SPOUSE,
            description: "spouse"
          }
        ]
      },
      eventData: {
        eventTime: values.dateOfMarriage,
        description: values.description,
        tapahtumatunnus: values.tapahtumatunnus,
        viranomaiskoodi: values.viranomaiskoodi,
        arkistonumero: values.arkistonumero
      },
      notifier
    }
    return sendGenericForm({ type: GenericFormType.MARRIAGE, payload: postData});
  }

  return (
    <GenericForm 
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      form={Form}
      validationSchema={validationSchema}
    />
  );
}

export default MarriageForm;