import { EstateRole, Message, MessageType, PrenuptialType } from "./DomainTypes";

export const NULL_PERSON = {
  id: "",
  firstName: "",
  lastName: "",
  fullName: "",
  primaryLanguage: "fi",
  nationality: "FIN",
  childCount: 0,
};

export const fiLabelForRelationship = (r: EstateRole): string => {
  switch (r) {
    case EstateRole.HEIR:
      return "rintaperillinen";
    case EstateRole.WIDOW:
      return "leski";
    case EstateRole.WILL_BENEFICIARY:
      return "testamentinsaaja";
    default:
      return "";
  }
};

export const fiLabelForPrenuptialType = (t: PrenuptialType): string => {
  switch (t) {
    case PrenuptialType.MUTUALLY_EXCLUSIVE:
      return "Avio-oikeuden täydellisesti poissulkeva ehto";
    case PrenuptialType.MUTUALLY_PARTIALLY_EXCLUSIVE:
      return "Osittainen avioehto";
    case PrenuptialType.ONE_SIDED_EXCLUSIVE:
      return "Yksipuolinen täydellisesti poissulkeva avioehto";
    case PrenuptialType.ONE_SIDED_PARTIALLY_EXCLUSIVE:
      return "Yksipuolinen osittainen avioehto";
    default:
      return "";
  }
};

export const DUMMY_MESSAGES: Message[] = [
  {
    id: "1000",
    subject: "Moikka moi!",
    jsonData: { body: "Kaunis päivä tänään." },
    type: MessageType.MESSAGE,
    createdOn: new Date(),
    senderId: "aaaa",
    source: "Matti Meikäläinen",
    isRead: true
  },
  {
    id: "1001",
    subject: "Heippa hei!",
    jsonData: { body: "Seikka on saletti." },
    type: MessageType.MESSAGE,
    createdOn: new Date(),
    senderId: "bbbb",
    source: "Maija Meikäläinen",
    isRead: false
  },
  {
    id: "",
    createdOn: new Date(),
    subject: "",
    jsonData: {
      id: "d662e7a4-e0d8-4147-86bd-51fc7812cdd5",
      subject: "kuoleman-raportointi",
      data: {
        ChangeEvent: {
          description: "Selite",
          tapahtumatunnus: "Tunnus",
          viranomaiskoodi: "Koodi",
          arkistonumero: "Numero",
          kuolinaikaarvioitu: true,
          kuolemanperuutus: false
        },
        Ilmoittaja: {
          laakarintunnus: "1234",
          sairaalanidtunnus: "5678"
        },
        Person: {
          givenName: "Testi",
          familyName: "Henkilö",
          dateOfDeath: new Date(Date.parse("2021-11-01T11:30:00")),
          personalIdentityCode: "123456-7890"
        }
      },
      eventType: "Kuolintieto",
      dataVersion: "0.01",
      metadataVersion: "1",
      eventTime: "2021-11-23T09:30:21.5549516Z",
      topic: "/subscriptions/a57cfd5a-d28f-4d73-9818-db925524f274/resourceGroups/minisuomi-laku-rg/providers/Microsoft.EventGrid/topics/omaelama-kuoleman-raportointi"
    },
    type: MessageType.EVENT,
    isRead: false
  }
];
