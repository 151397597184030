/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { Button, suomifiDesignTokens as tokens, Text } from "suomifi-ui-components";
import Spinner from "../common/Spinner";
import { ButtonSecondaryDark } from "../common/InputStyles";
import { Party, Will, WillAsset } from "../common/DomainTypes";
import { Heading1, Heading2, Main, Section, Toolbar } from "../common/DocumentStyles";
import { useWill } from "./useWill";
import { AppContext } from "../../App";

const WillView: React.FC = () => {
  const navigate = useNavigate();
  const [appState] = useContext(AppContext)
  const person = appState.person;
  const {will, loadingWill} = useWill();

  const renderWill = (will: Will) => (
      <div>
        <p>
          {will.clause}
        </p>
        <ol>
          {will.assets.map(renderAsset)}
        </ol>
      </div>
  );

  const renderAsset = (asset: WillAsset, i: number) => (
      <li key={i} style={{margin: `${tokens.spacing.m} 0`}}>
        {asset.name}
        <ul style={{listStyleType: "none"}}>
          {asset.parties.map(renderParty)}
        </ul>
      </li>
  );

  const renderParty = (party: Party, i: number) => (
      <li key={i}>
        <Text variant="bold">
          {party.person.firstName} {party.person.lastName}
        </Text> ({party.person.ssn}) osuudella {party.share}
      </li>
  );

  return (
      <div>
        <Toolbar>
          <ButtonSecondaryDark variant="secondary" icon={"chevronLeft"} onClick={() => navigate("/perukirja")}>
            Perukirja
          </ButtonSecondaryDark>
          <Button icon={"edit"} onClick={() => navigate("/testamentti/muokkaa")}>
            Muokkaa
          </Button>
        </Toolbar>

        <Main>
          <Heading1 variant="h1hero">
            Testamentti
          </Heading1>

          <Section>
            <Heading2 variant="h2">Tekijä</Heading2>

            <Text variant="bold">{person.firstName} {person.lastName}</Text> ({person.ssn})
            <br/>
            {person.address?.street}, {person.address?.zip} {person.address?.city}
          </Section>

          <Section>
            <Heading2 variant="h2">Testamenttimääräys</Heading2>

            {loadingWill ? <Spinner/> : renderWill(will)}
          </Section>
        </Main>
        <br/>
      </div>
  );
};

export default WillView;
