import axios from "axios";
import { PersonPropertyShare } from "../model/assets";

const apiRootUrl = process.env.REACT_APP_PROPERTY_API || 'http://localhost:7071/api'

const taxAdministrationApi = axios.create({
  baseURL: apiRootUrl
});

export async function GetPropertyData(ssn: string): Promise<PersonPropertyShare[]> {
  return taxAdministrationApi.get(`${apiRootUrl}/Henkilo/${ssn}/Huoneistotiedot`)
                              .then(resp => resp.data)
                              .catch(err => console.log(`No apartment info found by ID ${ssn}: ${err}`));
}
