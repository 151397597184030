import { companyValueDetails, totalValueDetails, valueDetails } from "./valueDetails";

export class assets{
    publiclyTradedShares: totalValueDetails[] = []
    housingAssociationShares: companyValueDetails[] = []
    realEstates: valueDetails[] = []
    leisureRealEstates: valueDetails[] = []
    nonListedCompanyShares:valueDetails[] = []
    partnerships: valueDetails[] = []
    insurancePayments: valueDetails[] = []
    capitalRedemptionPolicy: valueDetails[] = []
    bankAccounts: valueDetails[] = []
    partInAnEstate: valueDetails[] = []
    assetsOutsideOfFinland: valueDetails[] = []
    vehicles: valueDetails[] = []
    otherAssets: valueDetails[] = []
}