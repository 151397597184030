/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { MenuItem, Select } from "@mui/material";

class MultiValuePicker extends React.Component<any, any, any> {
  constructor(props: any, data : any) {
      super(props);
      this.state = { target: {
        name: props.name,
        value: props.data
      }}

      this.selectableValues = props.items;
  }

  private selectableValues: any = []

  handleChange(e: any) {
    let change = { target: {
      name: this.props.name,
      value: e.target.value
    }};
    this.setState(change)
    this.props.onChange(change);
 }

  render() {
    
      return (
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={this.state.target.value}
          onChange={this.handleChange.bind(this)}
        >
          <MenuItem value=""><em>Valitse arvo</em></MenuItem>
          {
            this.selectableValues.map((item: any) => (
              <MenuItem value={item.value}>{item.name}</MenuItem>
            ))
          }
        </Select>
      );
  }
}

export default MultiValuePicker;
