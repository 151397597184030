import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AppContext } from "../../App";

interface EmploymentCertificateData {
  employmentEndingCodes: Array<any>,
  ratingCodes: Array<any>,
  person: object
}

export function useEmploymentCertificateData() {
  const [appState] = useContext(AppContext);
  const person = appState.person;

  const [employmentCertificateData, setEmploymentCertificateData] = useState<EmploymentCertificateData>();
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    Promise.all([
      axios.get('/codelists/KevaEmploymentEnding.json'),
      axios.get('/codelists/tyoarvio.json')
    ])
      .then(([endingResult, ratingResult]) => {
        setEmploymentCertificateData(x => ({ 
          ...x,
          person: {
            name: person.fullName,
            ssn: person.ssn
          },
          employmentEndingCodes: endingResult.data.codes,
          ratingCodes: ratingResult.data.codes
        }))
      })
      .finally(() => setLoading(false))
  }, [person]);

  return {
    data: employmentCertificateData,
    isLoading
  };
}
