/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, {useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { Button } from "suomifi-ui-components";
import { Input, InputIcon, Select, TextArea } from "../common/InputStyles";
import { suomifiDesignTokens as tokens } from "suomifi-design-tokens";
import { Heading1, Main, Toolbar } from "../common/DocumentStyles";
import { usePrenuptial } from "./usePrenuptial";
import { AppContext } from "../../App";
import { useSpouse } from "../family/useSpouse";
import { PrenuptialType } from "../common/DomainTypes";
import { fiLabelForPrenuptialType } from "../common/DefaultValues";
import { FlexColTight } from "../common/CommonStyles";

const PrenuptialEdit: React.FC = () => {
  const [appState] = useContext(AppContext)
  const person = appState.person;
  const {spouse} = useSpouse();

  const navigate = useNavigate();
  const {prenuptial, setPrenuptial, savePrenuptial} = usePrenuptial();

  useEffect(() => {
    spouse && setPrenuptial(prevPrenup => ({
      ...prevPrenup, parties: [person, spouse]
    }));
  }, [person, spouse, setPrenuptial]);

  const updateClause = (clause: string) => {
    setPrenuptial(prevPrenup => ({...prevPrenup, clause}));
  };

  const updateType = (type: PrenuptialType) => {
    setPrenuptial(prevPrenup => ({...prevPrenup, type}));
  };

  return (
      <div>
        <Toolbar>
          <Button
              icon={"save"}
              onClick={() => savePrenuptial().then(() => navigate("/avioehto"))}>
            Tallenna
          </Button>
          <Button variant="secondaryNoBorder"
              icon={"close"}
              style={{background: "none"}}
              onClick={() => navigate("/avioehto")}>
            Sulje
          </Button>
        </Toolbar>

        <Main>
          <FlexColTight>
            <Heading1 variant="h1hero">
              Avioehto
            </Heading1>

            <label>
              Avioehtolaji
              <div style={{position: "relative", width: "100%"}}>
                <Select value={prenuptial.type}
                        onChange={(e) => updateType(parseInt(e.currentTarget.value))}>
                  <option value={PrenuptialType.MUTUALLY_EXCLUSIVE}>
                    {fiLabelForPrenuptialType(PrenuptialType.MUTUALLY_EXCLUSIVE)}
                  </option>
                  <option value={PrenuptialType.MUTUALLY_PARTIALLY_EXCLUSIVE}>
                    {fiLabelForPrenuptialType(PrenuptialType.MUTUALLY_PARTIALLY_EXCLUSIVE)}
                  </option>
                  <option value={PrenuptialType.ONE_SIDED_EXCLUSIVE}>
                    {fiLabelForPrenuptialType(PrenuptialType.ONE_SIDED_EXCLUSIVE)}
                  </option>
                  <option value={PrenuptialType.ONE_SIDED_PARTIALLY_EXCLUSIVE}>
                    {fiLabelForPrenuptialType(PrenuptialType.ONE_SIDED_PARTIALLY_EXCLUSIVE)}
                  </option>
                </Select>
                <InputIcon icon={"arrowheadDown"}/>
              </div>
            </label>

            <label>
              Klausuuli
              <TextArea
                  value={prenuptial.clause}
                  onChange={(e) => updateClause(e.currentTarget.value)}
                  style={{marginBottom: tokens.spacing.m}} rows={3}/>
            </label>

            <label>
              Osapuolet

              {prenuptial.parties.map((party, i) =>
                  <Input disabled key={i} value={party.firstName + " " + party.lastName}/>
              )}
            </label>

          </FlexColTight>


        </Main>
        <br/>
      </div>
  );
};


export default PrenuptialEdit;
