/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import Card from "../common/Card";
import { TableSmall } from "../common/CommonStyles";
import Spinner from "../common/Spinner";
import { Expander, ExpanderContent, ExpanderTitleButton } from "suomifi-ui-components";

const EducationCard: React.FC = () => {
  const isLoading = false
  return (
    <Card title="Koulutus">
      {
        isLoading
          ? <Spinner style={{ height: "100%" }} />
          : (
            <React.Fragment>
              <br />
              <Expander>
                <ExpanderTitleButton>
                  Peruskoulu
                </ExpanderTitleButton>
                <ExpanderContent>
                  <TableSmall>
                    <tbody>
                      <tr>
                        <th>Lohjan peruskoulu</th>
                        <td className="right">9/1984 - 6/1993</td>
                      </tr>
                    </tbody>
                  </TableSmall>
                </ExpanderContent>
              </Expander>
              <Expander>
                <ExpanderTitleButton>
                  Ammattikoulu/lukio
                </ExpanderTitleButton>
                <ExpanderContent>
                  <TableSmall>
                    <tbody>
                      <tr>
                        <th>Lohjan lukio</th>
                        <td className="right">9/1993 - 6/1996</td>
                      </tr>
                      <tr>
                        <th>Luksia Lohja - Liiketoiminnan ammattitutkinto</th>
                        <td className="right">9/1997 - 5/2000</td>
                      </tr>
                    </tbody>
                  </TableSmall>
                </ExpanderContent>
              </Expander>
              <Expander>
                <ExpanderTitleButton>
                  Korkeakoulu/yliopisto/MBA
                </ExpanderTitleButton>
                <ExpanderContent>
                  <TableSmall>
                    <tbody>
                      <tr>
                        <th>Lohjan yliopisto</th>
                        <td className="right">9/2000 - 5/2004</td>
                      </tr>
                      <tr className="nested-row">
                        <th>Filosofian maisteri, tilastotiede</th>
                        <td className="right">300 op</td>
                      </tr>
                    </tbody>
                  </TableSmall>
                </ExpanderContent>
              </Expander>
              <Expander>
                <ExpanderTitleButton>
                  Tohtoriopinnot
                </ExpanderTitleButton>
                <ExpanderContent>
                  <TableSmall>
                    <tbody>
                      <tr>
                        <th>Lohjan yliopisto - Filosofian tohtori, matematiikka ja tilastotiede</th>
                        <td className="right">9/2004 - 5/2008</td>
                      </tr>
                      <tr className="nested-row">
                        <th><a href="https://vero.fi" target="_blank" rel="noopener noreferrer">Artikkeli ABC</a></th>
                      </tr>
                      <tr className="nested-row">
                        <th><a href="https://vero.fi" target="_blank" rel="noopener noreferrer">Artikkeli XYZ</a></th>
                      </tr>
                    </tbody>
                  </TableSmall>
                </ExpanderContent>
              </Expander>
            </React.Fragment>
          )
      }
    </Card>
  )
}

export default EducationCard;
