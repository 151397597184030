/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useContext } from "react";
import { suomifiDesignTokens as sdt, Text } from "suomifi-ui-components";
import { FlexCol, FlexRow, Heading1WithBottomBorder } from "../common/CommonStyles";
import { AppContext } from "../../App";
import IncomeTotalsCard from "../income/IncomeTotalsCard";
import CreditAndLoansCard from "./CreditAndLoansCard";
import AssetsWidget from "../assets/AssetsWidget";
import PersonAssets from "../assets/PersonAssets";

const Dashboard: React.FC = () => {
  const [appState] = useContext(AppContext);
  const person = appState.person;

  return (
      <FlexCol>
        <div>
          <Heading1WithBottomBorder variant="h1" style={{margin: `${sdt.spacing.l} 0 ${sdt.spacing.s}`}}>
            Talous
          </Heading1WithBottomBorder>
          <Text variant="bold">{person.firstName} {person.lastName}</Text>
          <Text> - {person.address?.street}, {person.address?.zip} {person.address?.city}</Text>
        </div>

        <FlexRow css={{
          '& > *': {
            boxSizing: 'border-box',
            minHeight: "300px"
          }
        }}>
          <PersonAssets />
        </FlexRow>

        <FlexRow css={{
          '& > *': {
            boxSizing: 'border-box',
            minHeight: "300px"
          }
        }}>
          <IncomeTotalsCard />
        </FlexRow>

        <FlexRow css={{
          '& > *': {
            boxSizing: 'border-box',
            minHeight: "300px"
          }
        }}>
          <CreditAndLoansCard />
        </FlexRow>

        <br/>
      </FlexCol>
  );
};

export default Dashboard;
