/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import {FlexCol } from "../common/CommonStyles";
import { Button, TextField } from "@mui/material";
import _ from "lodash";
import { InsurancePayment } from "./model/insurancePayment";
// import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import MultiValuePicker from "./multiValuePicker";

class InsurancePaymentEditor extends React.Component<any, InsurancePayment> {
  constructor(props: any, data : any) {
      super(props);
      this.state = props.data !== null && props.data !== undefined ? props.data : {};
      
  }

  handleChange(e: any) {
    let change = this.state;
    _.set(change, e.target.name, e.target.value)
    this.setState(change)
    this.props.refresh(this.state, this.props.array, this.props.index);
  }

  removeItem(){
    this.props.removeItem(this.props.index, this.props.array);
  }

  render() {
    
      return (
          <tr>
            <td>
              <TextField name="ssn" label="Edunsaajan henkilötunnus" value={this.state.personalId} onChange={this.handleChange.bind(this)} variant="outlined" />
              </td>
              <td>
              <MultiValuePicker style={{width: 400}} name="insuranceType" label="Vakuutuskorvaus, tyyppi" onChange={this.handleChange.bind(this)} data= {this.state.insuranceType}
              items={[
                {value:1, name:"Henkivakuutus"},
              {value:2, name:"Eläkevakuutuksen säästösumma"},
              {value:3, name:"Eläkevakuutuksen tuotto"},
              {value:4, name:"Muu"},
              ]}></MultiValuePicker>
              
              </td>
              <td>
              {/* <CurrencyTextField
                    label="Vakuutuskorvaus, €"
                    variant="standard"
                    name="value"
                    value={this.state.value}
                    currencySymbol="€"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator=" "
                    onChange={this.handleChange.bind(this)} /> */}
              </td>
              <td>
              <TextField name="insuranceNumber" label="Lisätiedot" value={this.state.insuranceNumber} onChange={this.handleChange.bind(this)} variant="outlined" />
              </td>
              <td>
              <FlexCol><Button color="primary" style={{width: 60}} onClick={this.removeItem.bind(this)}>Poista</Button></FlexCol>
              </td>
            </tr>
      );
  }
}

export default InsurancePaymentEditor;
