import * as OmaElamaFunctions from "../api/omaelama-functions";
import { Message, MessageType, EventGridEvent } from "../components/common/DomainTypes";
import { GenericEventPayload, GenericFormType } from "../components/forms/GenericFormTypes";
import { DeathReport } from "../components/forms/reportDeath/model/ReportDeathFormModel";
import * as tokenUtil from "../utils/tokenUtil"

export async function decodeToken(): Promise<tokenUtil.DecodedToken> {
  try {
    const {isValid, decodedToken} = await tokenUtil.isTokenValid();
    if (!isValid) {
      return Promise.reject("Invalid token");
    }
    return decodedToken;
  }
  catch(err) {
    return Promise.reject(err);
  }
}

export async function fetchCurrentUser(): Promise<any> {
  return (await OmaElamaFunctions.fetchCurrentUser()).data;
}

export async function fetchUserMessages(): Promise<Message[]> {
  const data = (await OmaElamaFunctions.GetUserMessages()).data;
  return data.map(resultsToMessages);
}

function resultsToMessages(data: any): Message {
  return {
    ...data, jsonData: jsonDataToObject(data)
  };
}

function jsonDataToObject(data: any) {
  let eventData;
  switch(data.type) {
    case MessageType.EVENT:
      switch(data.subType) {
        case GenericFormType.DEATH_REPORT:
        case "Kuolintieto":
          console.log("actions jsonDataToObject Kuolintieto", data);
          eventData = { ...data.jsonData } as EventGridEvent<DeathReport>;
          break;
        default:
          if (data.source === "EventGrid") {
            eventData = { ...data.jsonData } as EventGridEvent<GenericEventPayload>;
          }
          else {
            eventData = data.jsonData;
          }
      }
      break;
    default:
      // MessageType.MESSAGE contains a string
      console.log("actions jsonDataToObject default", data);
      eventData = data.jsonData as string;
  }
  return eventData;
}