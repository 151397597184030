import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { Will } from "../common/DomainTypes";
import { getWill, postWill } from "./willApi";
import { NIL_UUID } from "../../utils/uuidUtils";

export function useWill(initialValue?: Will) {
  const [appState] = useContext(AppContext)
  const person = appState.person;

  const [will, setWill] = useState<Will>(initialValue ? initialValue : {
    id: NIL_UUID,
    clause: "",
    date: "0000-00-00",
    assets: [],
  });

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getWill(person.id)
    .then(will => will && setWill(will))
    .finally(() => setLoading(false));
  }, [person]);

  const saveWill = (): Promise<any> => {
    return postWill(person, will);
  };

  return {will, setWill, loadingWill: loading, saveWill};
}
