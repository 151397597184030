/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useContext } from "react";
import { suomifiDesignTokens as tokens, Text } from "suomifi-ui-components";
import { Bar } from "react-chartjs-2"
import { FlexCol, FlexColTight, FlexRow, Heading1WithBottomBorder, panelWithShadowCss, TableSmall } from "../common/CommonStyles";
import { AppContext } from "../../App";
import Card from "../common/Card"
import { useIncome } from "./useIncome"

const options = {
  scales: {
    xAxes: [{
      stacked: true
    }],
    yAxes: [{
      stacked: true
    }]
  }
}

const IncomeView: React.FC = () => {
  const [appState] = useContext(AppContext)
  const person = appState.person;
  const { incomeDatasets } = useIncome()

  return (
    <FlexCol>
      <div>
        <Heading1WithBottomBorder variant="h1" style={{ margin: `${tokens.spacing.l} 0 ${tokens.spacing.s}` }}>
          Tulot
          </Heading1WithBottomBorder>
        <Text variant="bold">{person.firstName} {person.lastName}</Text>
        <Text> - {person.address?.street}, {person.address?.zip} {person.address?.city}</Text>
      </div>

      <FlexColTight css={panelWithShadowCss}>
        <Bar
          data={incomeDatasets}
          options={options}
        />
      </FlexColTight>

      <FlexRow css={{
        "& > *": {
          boxSizing: "border-box",
          minHeight: "150px"
        }
      }}>
        <Card
          title="Poissaolot"
        >
          <TableSmall>
            <tbody>
              <tr>
                <th>Vuosiloma</th>
                <td className="right">1.7.2020 - 31.7.2020</td>
              </tr>
              <tr>
                <th>Sairasloma</th>
                <td className="right">4.2.2020 - 6.2.2020</td>
              </tr>
            </tbody>
          </TableSmall>
        </Card>
      </FlexRow>
      <br />
    </FlexCol>
  );
};

export default IncomeView;
