import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { fetchData } from "../../utils/apiClient";

interface LiberalEducationCourse {
    name: string,
    infoText: string,
    status: string,
    completedOn: string
}

export function useLiberalEducation() {
    const [appState] = useContext(AppContext)
    const person = appState.person;

    const [courses, setCourses] = useState<LiberalEducationCourse[]>([])
    const [isLoading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        setLoading(true)
        fetchData(`Education/Person/${person.ssn}/oksivis`)
            .then(result => {
                if (result && result instanceof Array) {
                    setCourses(result);
                }
            })
            .finally(() => setLoading(false))
    }, [person])

    return { courses, isLoading }
}
