import React from "react";
import { TextField, Typography } from "@mui/material";
import {Button, suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { FlexCol, FlexRow } from "../../common/CommonStyles";
import Card from "../../common/Card";
import { FormikProps } from "formik";
import * as Yup from "yup";
import GenericForm from "../GenericForm";
import { sendGenericForm } from "../../../api/omaelama-functions";
import { GenericEventPayload, GenericFormType, NotifierType, HospitalNotifier } from "../GenericFormTypes";
import { Relationship } from "../../common/DomainTypes";

interface FormModel {
  dateOfBirth?: Date;
  motherSsn: string;
  fatherSsn?: string;

  description?: string;
  tapahtumatunnus?: string;
  viranomaiskoodi?: string;
  arkistonumero?: string;

  doctorIdentifier?: string;
  hospitalIdentifier?: string;
}

const defaultValues: FormModel = {
  dateOfBirth: undefined,
  motherSsn: "",
  fatherSsn: undefined,

  description: "",
  tapahtumatunnus: undefined,
  viranomaiskoodi: undefined,
  arkistonumero: undefined,

  doctorIdentifier: "",
  hospitalIdentifier: ""
}

const validationSchema: Yup.ObjectSchema<FormModel> = Yup.object({
  dateOfBirth: Yup.date()
    .required("Pakollinen tieto"),
  motherSsn: Yup.string()
    .length(11, "Henkilötunnuksen pituus oltava 11 merkkiä")
    .required("Pakollinen tieto"),
  fatherSsn: Yup.string()
    .length(11, "Henkilötunnuksen pituus oltava 11 merkkiä"),
  description: Yup.string()
    .min(3, "Tekstin pituus oltava vähintään 3 merkkiä")
    .max(500, "Tekstin pituus voi olla enintään 256 merkkiä"),
  tapahtumatunnus: Yup.string(),
  viranomaiskoodi: Yup.string(),
  arkistonumero: Yup.string(),
  doctorIdentifier: Yup.string()
    .min(2, "Tunnisteen pituus oltava vähintään 2 merkkiä")
    .max(50, "Tunnisteen pituus voi olla enintään 11 merkkiä"),
  hospitalIdentifier: Yup.string()
    .min(2, "Tunnisteen pituus oltava vähintään 2 merkkiä")
    .max(50, "Tunnisteen pituus voi olla enintään 11 merkkiä")
});

export const Form: React.FC<FormikProps<any>> = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  touched,
  values,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Card title="Lapsen syntymä">
      <FlexCol>
          <Typography>Lapsen tiedot</Typography>
          <FlexCol>
            <FlexRow>
              <TextField 
                type="datetime-local" 
                InputLabelProps={{ shrink: true }} 
                name="dateOfBirth" 
                label="Lapsen syntymäaika" 
                value={values.dateOfBirth} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.dateOfBirth && Boolean(errors.dateOfBirth)} 
                helperText={touched.dateOfBirth && errors.dateOfBirth as any} 
                variant="outlined" 
                required 
              />
            </FlexRow>
            <FlexRow>
              <TextField 
                name="motherSsn" 
                label="Äidin henkilötunnus" 
                value={values.motherSsn} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.motherSsn && Boolean(errors.motherSsn)} 
                helperText={touched.motherSsn && errors.motherSsn as any} 
                variant="outlined" 
                required 
              />
              <TextField 
                name="fatherSsn" 
                label="Isän henkilötunnus" 
                value={values.fatherSsn} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.fatherSsn && Boolean(errors.fatherSsn)} 
                helperText={touched.fatherSsn && errors.fatherSsn as any} 
                variant="outlined" 
              />
            </FlexRow>
          </FlexCol>
          <br/>
          <Typography>Syntymän ilmoittamiseen liittyvät tiedot</Typography>
          <FlexCol>
            <FlexRow>
              <TextField 
                rows={3} 
                name="description" 
                label="Lisätiedot" 
                value={values.description} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.description && Boolean(errors.description)} 
                helperText={touched.description && errors.description as any} 
                variant="outlined" 
              />
            </FlexRow>
            <FlexRow>
              <TextField 
                name="tapahtumatunnus" 
                label="Tapahtumatunnus" 
                value={values.tapahtumatunnus} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.tapahtumatunnus && Boolean(errors.tapahtumatunnus)} 
                helperText={touched.tapahtumatunnus && errors.tapahtumatunnus as any} 
                variant="outlined" 
              />
              <TextField 
                name="viranomaiskoodi" 
                label="Viranomaiskoodi" 
                value={values.viranomaiskoodi} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.viranomaiskoodi && Boolean(errors.viranomaiskoodi)} 
                helperText={touched.viranomaiskoodi && errors.viranomaiskoodi as any} 
                variant="outlined" 
              />
              <TextField 
                name="arkistonumero" 
                label="Arkistonumero" 
                value={values.arkistonumero} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.arkistonumero && Boolean(errors.arkistonumero)} 
                helperText={touched.arkistonumero && errors.arkistonumero as any} 
                variant="outlined" 
              />
            </FlexRow>
          </FlexCol>
          <br/>
          <Typography>Ilmoittaja</Typography>
          <FlexCol>
            <FlexRow>
              <TextField 
                name="doctorIdentifier" 
                label="Lääkärin tunnus" 
                value={values.doctorIdentifier} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.doctorIdentifier && Boolean(errors.doctorIdentifier)} 
                helperText={touched.doctorIdentifier && errors.doctorIdentifier as any} 
                variant="outlined" 
              />
              <TextField 
                name="hospitalIdentifier" 
                label="Sairaalan id tunnus" 
                value={values.hospitalIdentifier} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.hospitalIdentifier && Boolean(errors.hospitalIdentifier)} 
                helperText={touched.hospitalIdentifier && errors.hospitalIdentifier as any} 
                variant="outlined" 
              />
            </FlexRow>
          </FlexCol>
          <Button type="submit" style={{margin: `${tokens.spacing.m} 0`}}>
            Lähetä
          </Button>
        </FlexCol>
      </Card>
    </form>
  )
}

const ChildsBirth: React.FC = () => {

  const handleSubmit = async (values: FormModel) => {
    const notifier: HospitalNotifier = {
      id: values.doctorIdentifier,
      type: NotifierType.DOCTOR,
      sairaalanidtunnus: values.hospitalIdentifier
    }
    const postData: GenericEventPayload = {
      person: {
        relations: [
          {
            personalIdentityCode: values.motherSsn,
            role: Relationship.PARENT,
            description: "mother"
          },
          {
            personalIdentityCode: values.fatherSsn,
            role: Relationship.PARENT,
            description: "father"
          }
        ]
      },
      eventData: {
        eventTime: values.dateOfBirth,
        description: values.description,
        tapahtumatunnus: values.tapahtumatunnus,
        viranomaiskoodi: values.viranomaiskoodi,
        arkistonumero: values.arkistonumero
      },
      notifier
    }
    return sendGenericForm({ type: GenericFormType.CHILDS_BIRTH, payload: postData});
  }

  return (
    <GenericForm 
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      form={Form}
      validationSchema={validationSchema}
    />
  );
}

export default ChildsBirth;