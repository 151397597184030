import React from "react";
import { TextField, Typography, Checkbox } from "@mui/material";
import Card from "../../common/Card";
import { FlexCol, FlexRow } from "../../common/CommonStyles";
import { DeathReportFormModel } from "./model/ReportDeathFormModel";
import {Button, suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { FormikProps } from "formik";
import * as Yup from "yup";
import { sendGenericForm } from "../../../api/omaelama-functions"
import { GenericEvent, GenericEventPayload, GenericFormType, HospitalNotifier, NotifierType } from "../GenericFormTypes";
import GenericForm from "../GenericForm";

export interface DeathReportEvent extends GenericEvent {
  kuolinaikaarvioitu: boolean;
  kuolemanperuutus: boolean;
}

interface FormModel {
  firstName: string;
  lastName: string;
  personalIdentityCode: string;
  dateOfDeath?: Date;
  
  description?: string;
  tapahtumatunnus?: string;
  viranomaiskoodi?: string;
  arkistonumero?: string;
  kuolinaikaarvioitu: boolean;
  kuolemanperuutus: boolean;

  doctorIdentifier?: string;
  hospitalIdentifier?: string;
}

const defaultValues: FormModel = {
  firstName: "",
  lastName: "",
  personalIdentityCode: "",
  dateOfDeath: undefined,

  description: "",
  tapahtumatunnus: undefined,
  viranomaiskoodi: undefined,
  arkistonumero: undefined,
  kuolinaikaarvioitu: false,
  kuolemanperuutus: false,

  doctorIdentifier: "",
  hospitalIdentifier: ""
}

const validationSchema: Yup.ObjectSchema<DeathReportFormModel> = Yup.object({
  firstName: Yup.string()
    .min(2, "Etunimen pituus oltava vähintään 2 merkkiä")
    .max(50, "Etunimen pituus voi olla enintään 50 merkkiä")
    .required("Pakollinen tieto"),
  lastName: Yup.string()
    .min(2, "Sukunimen pituus oltava vähintään 2 merkkiä")
    .max(50, "Sukunimen pituus voi olla enintään 50 merkkiä")
    .required("Pakollinen tieto"),
  personalIdentityCode: Yup.string()
    .length(11, "Henkilötunnuksen pituus oltava 11 merkkiä")
    .required("Pakollinen tieto"),
  dateOfDeath: Yup.date()
    .required("Pakollinen tieto"),
  description: Yup.string()
    .min(3, "Tekstin pituus oltava vähintään 3 merkkiä")
    .max(500, "Tekstin pituus voi olla enintään 256 merkkiä"),
  tapahtumatunnus: Yup.string(),
  viranomaiskoodi: Yup.string(),
  arkistonumero: Yup.string(),
  kuolinaikaarvioitu: Yup.boolean().defined(),
  kuolemanperuutus: Yup.boolean().defined(),
  doctorIdentifier: Yup.string()
    .min(2, "Tunnisteen pituus oltava vähintään 2 merkkiä")
    .max(50, "Tunnisteen pituus voi olla enintään 11 merkkiä"),
  hospitalIdentifier: Yup.string()
    .min(2, "Tunnisteen pituus oltava vähintään 2 merkkiä")
    .max(50, "Tunnisteen pituus voi olla enintään 11 merkkiä"),
});

const Form: React.FC<FormikProps<DeathReportFormModel>> = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  touched,
  values,
}) => {

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Card title="Kuoleman raportointi">
        <FlexCol>
          <Typography>Henkilö, joka on todettu kuolleeksi</Typography>
          <FlexCol>
            <FlexRow>
              <TextField 
                name="firstName" 
                label="Etunimi" 
                value={values.firstName} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.firstName && Boolean(errors.firstName)} 
                helperText={touched.firstName && errors.firstName} 
                variant="outlined" 
                required 
              />
              <TextField 
                name="lastName" 
                label="Sukunimi" 
                value={values.lastName} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.lastName && Boolean(errors.lastName)} 
                helperText={touched.lastName && errors.lastName} 
                variant="outlined" 
                required 
              />
            </FlexRow>
            <FlexRow>
              <TextField 
                name="personalIdentityCode" 
                label="Henkilötunnus" 
                value={values.personalIdentityCode} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.personalIdentityCode && Boolean(errors.personalIdentityCode)} 
                helperText={touched.personalIdentityCode && errors.personalIdentityCode} 
                variant="outlined" 
                required 
              />
              <TextField 
                type="datetime-local" 
                InputLabelProps={{ shrink: true }} 
                name="dateOfDeath" 
                label="Kuolinaika" 
                value={values.dateOfDeath} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.dateOfDeath && Boolean(errors.dateOfDeath)} 
                helperText={touched.dateOfDeath && errors.dateOfDeath} 
                variant="outlined" 
                required 
              />
            </FlexRow>
          </FlexCol>
          <br/>
          <Typography>Kuoleman ilmoittamiseen liittyvät tiedot</Typography>
          <FlexCol>
            <FlexRow>
              <TextField 
                rows={3} 
                name="description" 
                label="Tapahtuman selite" 
                value={values.description} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.description && Boolean(errors.description)} 
                helperText={touched.description && errors.description} 
                variant="outlined" 
              />
            </FlexRow>
            <FlexRow>
              <TextField 
                name="tapahtumatunnus" 
                label="Tapahtumatunnus" 
                value={values.tapahtumatunnus} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.tapahtumatunnus && Boolean(errors.tapahtumatunnus)} 
                helperText={touched.tapahtumatunnus && errors.tapahtumatunnus} 
                variant="outlined" 
              />
              <TextField 
                name="viranomaiskoodi" 
                label="Viranomaiskoodi" 
                value={values.viranomaiskoodi} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.viranomaiskoodi && Boolean(errors.viranomaiskoodi)} 
                helperText={touched.viranomaiskoodi && errors.viranomaiskoodi} 
                variant="outlined" 
              />
              <TextField 
                name="arkistonumero" 
                label="Arkistonumero" 
                value={values.arkistonumero} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.arkistonumero && Boolean(errors.arkistonumero)} 
                helperText={touched.arkistonumero && errors.arkistonumero} 
                variant="outlined" 
              />
            </FlexRow>
            <FlexRow>
              <Typography>Kuolinaika arvioitu</Typography>
              <Checkbox 
                name="kuolinaikaarvioitu" 
                checked={values.kuolinaikaarvioitu} 
                onChange={handleChange} 
                onBlur={handleBlur} 
              />
            </FlexRow>
            <FlexRow>
              <Typography>Kuoleman peruutus</Typography>
              <Checkbox 
                name="kuolemanperuutus" 
                checked={values.kuolemanperuutus} 
                onChange={handleChange} 
                onBlur={handleBlur} 
              />
            </FlexRow>
          </FlexCol>
          <br/>
          <Typography>Ilmoittaja</Typography>
          <FlexCol>
            <FlexRow>
              <TextField 
                name="doctorIdentifier" 
                label="Lääkärin tunnus" 
                value={values.doctorIdentifier} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.doctorIdentifier && Boolean(errors.doctorIdentifier)} 
                helperText={touched.doctorIdentifier && errors.doctorIdentifier} 
                variant="outlined" 
              />
              <TextField 
                name="hospitalIdentifier" 
                label="Sairaalan id tunnus" 
                value={values.hospitalIdentifier} 
                onChange={handleChange} 
                onBlur={handleBlur} 
                error={touched.hospitalIdentifier && Boolean(errors.hospitalIdentifier)} 
                helperText={touched.hospitalIdentifier && errors.hospitalIdentifier} 
                variant="outlined" 
              />
            </FlexRow>
          </FlexCol>
          <Button type="submit" style={{margin: `${tokens.spacing.m} 0`}}>
            Lähetä
          </Button>
        </FlexCol>
      </Card>
    </form>
  )
}

const ReportDeathForm: React.FC = () => {

  const handleSubmit = async (values: FormModel) => {
    const notifier: HospitalNotifier = {
      id: values.doctorIdentifier,
      type: NotifierType.DOCTOR,
      sairaalanidtunnus: values.hospitalIdentifier
    }
    const eventData: DeathReportEvent = {
      eventTime: values.dateOfDeath,
      description: values.description,
      tapahtumatunnus: values.tapahtumatunnus,
      viranomaiskoodi: values.viranomaiskoodi,
      arkistonumero: values.arkistonumero,
      kuolinaikaarvioitu: values.kuolinaikaarvioitu,
      kuolemanperuutus: values.kuolemanperuutus
    }
    const postData: GenericEventPayload = {
      person: {
        firstName: values.firstName,
        lastName: values.lastName,
        personalIdentityCode: values.personalIdentityCode
      },
      eventData,
      notifier
    }
    return sendGenericForm({ type: GenericFormType.DEATH_REPORT, payload: postData});
  }

  return (
    <GenericForm 
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      form={Form}
      validationSchema={validationSchema}
    />
  );
}

export default ReportDeathForm;