/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { Button, TextField } from "@mui/material";
import { FlexCol, FlexRow } from "../common/CommonStyles";
import _ from "lodash";
import { beneficiary } from "./model/beneficiary";
import FractionEditor from "./fractionEditor";
import MultiValuePicker from "./multiValuePicker";

class BeneficiaryEditor extends React.Component<any, beneficiary> {
  constructor(props: any, data : any) {
      super(props);
      this.state = props.data;
      var change={};
      _.set(change, "heritageShare", {});
      _.set(change, "willShare", {});
      _.set(change, "legalShare", {});
  }

  handleChange(e: any) {
    let change = this.state;
    _.set(change, e.target.name, e.target.value)
    this.setState(change)
    this.props.refresh(this.state, this.props.array, this.props.index);
  }

  handleChildChange(e: any, entityName: string) {
    let change = this.state;
    _.set(change, entityName, e)
    this.setState(change)
    this.props.refresh(this.state, this.props.array, this.props.index);
  }

  removeItem(){
    this.props.removeItem(this.props.index, this.props.array);
  }

  render() {
    
      return (
          <div>
            <FlexCol>
            <FlexRow>
              <TextField name="firstName" label="Nimi" value={this.state.name} onChange={this.handleChange.bind(this)} variant="outlined" />
              <TextField name="personalId" label="Henkilötunnus" value={this.state.personalId} onChange={this.handleChange.bind(this)} variant="outlined" />
            </FlexRow>
            <br/>
            <FlexRow>
              <label>Perinnönsaajan veroluokka</label>
              <MultiValuePicker name="taxCategory" label="Perinnönsaajan veroluokka" onChange={this.handleChange.bind(this)} data= {this.state.taxCategory}
              items={[{value:1, name:"1 (Lähiomainen)"},{value:2, name:"2 (Ei lähiomainen)"}]}
              ></MultiValuePicker>
            </FlexRow>
            <br/>
            <FlexRow>
            <label>Perintöosuus</label>
            <FractionEditor data={this.state.inheritanceShare} refreshProperties={this.handleChildChange.bind(this)} entity="heritageShare"></FractionEditor>
            </FlexRow>

            <FlexRow>
            <label>Testamenttiosuus</label>
            <FractionEditor data={this.state.testamentShare} refreshProperties={this.handleChildChange.bind(this)} entity="willShare"></FractionEditor>
            </FlexRow>

            <FlexRow>
            <label>Lakiosa</label>
            <FractionEditor data={this.state.legalShare} refreshProperties={this.handleChildChange.bind(this)} entity="legalShare"></FractionEditor>
            </FlexRow>

            <FlexRow>
            <TextField type="number" name="legate" label="Legaatti" value={this.state.legate} onChange={this.handleChange.bind(this)} variant="outlined" />
            <TextField name="legateDescription" label="Legaatiksi saatu varallisuus, yksilöintitieto" value={this.state.legateDescription} onChange={this.handleChange.bind(this)} variant="outlined" />
            <TextField type="number" name="legateShare" label="Legaatiksi saatu varallisuus, osuus %" value={this.state.legateShare} onChange={this.handleChange.bind(this)} variant="outlined" />
            </FlexRow>

            <FlexRow>
            <TextField type="number" name="workCompensation" label="Työhyvitys" value={this.state.workCompensation} onChange={this.handleChange.bind(this)} variant="outlined" />
            <TextField type="number" name="allowance" label="Avustus" value={this.state.allowance} onChange={this.handleChange.bind(this)} variant="outlined" />
            </FlexRow>
            </FlexCol>
            <FlexCol>
              <Button color="primary" style={{width: 60, paddingTop: 35 }} onClick={this.removeItem.bind(this)}>Poista</Button>
            </FlexCol>
        </div>
      );
  }
}

export default BeneficiaryEditor;
