export class valueDetails {
    id: string = ""
    value: number = 0
    details: string = ""
}

export class companyValueDetails extends valueDetails {
    businessId: string = ""
}

export class totalValueDetails extends valueDetails {
    numberOfItems: number = 0
}