export const roundToPrecision = (number: number, precision: number) => {
  const i = Math.pow(10, precision);
  return Math.round((number + Number.EPSILON) * i) / i;
};

export const fractionToPercentage = (fraction: string): number => {
  const numeratorAndDenominator = fraction.split("/");
  return roundToPrecision(
      (parseInt(numeratorAndDenominator[0], 10) / parseInt(numeratorAndDenominator[1], 10)) * 100, 1);
};

export const percentageToFraction = (percentage: number): string => {
  const greatestCommonDivisor = (a: number, b: number): number => {
    return !b ? a : greatestCommonDivisor(b, a % b);
  };

  const precision = 3;
  const number = roundToPrecision(percentage / 100, precision);

  const denominator = Math.pow(10, precision);
  const numerator = Math.round(number * denominator);
  const divisor = greatestCommonDivisor(numerator, denominator);

  return Math.floor(numerator / divisor) + "/" + Math.floor(denominator / divisor);
};
