import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../App";
import {TransactionsTotals} from "../common/DomainTypes";
import {fetchData} from "../../utils/apiClient";

interface TotalsResult {
  sixMonths: TransactionsTotals,
  twelveMonths: TransactionsTotals
}

interface IObjectKeys {
  [key: string]: string;
}

export function useTransactionTotals() {
  const [appState] = useContext(AppContext);
  const person = appState.person;

  const [totals, setTotals] = useState<TotalsResult>();
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true)
    Promise.all([
      fetchData(`IncomeData/Persons/${person.ssn}/Transactions/Totals?timespanInMonths=6`),
      fetchData(`IncomeData/Persons/${person.ssn}/Transactions/Totals?timespanInMonths=12`)
    ])
      .then(result => setTotals({
        sixMonths: result[0],
        twelveMonths: result[1]
      }))
      .finally(() => setLoading(false));
  }, [person])

  return {totals, isLoading}
}
