import React from "react";
import { Button } from "suomifi-ui-components";
import { Form, Field } from "react-final-form";
import { FormStyles } from "../common/StyledInputComponents";
import { ButtonLink } from "../common/InputStyles";
import { useEmploymentCertificateData } from "./useEmploymentCertificateData";
import Spinner from "../common/Spinner";

interface Props {
  onSubmit?: () => void,
  onCancel?: () => void,
  hideButtons?: boolean,
  disabled?: boolean,
  initialValues?: any
}

interface Values {
  company: string
}

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

const onSubmitMock = async (values: Values) => {
  await sleep(1000)
  window.sessionStorage['cache:employmentCertificate'] = JSON.stringify(values)
}

const EmploymentCertificateForm: React.FC<Props> = ({ onSubmit, onCancel, disabled, initialValues, hideButtons }) => {
  const { data } = useEmploymentCertificateData()
  return (
    <FormStyles>
      <Form
        onSubmit={async (values: Values) => {
          await onSubmitMock(values)
          if(onSubmit) onSubmit()
        }}
        initialValues={initialValues}
        render={({ form, values, handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            {
              submitting
                ? <Spinner style={{ height: "100%" }} />
                : (
                  <fieldset disabled={disabled} style={{ border: 'none' }}>
                    <div>
                      <label>Henkilö</label>
                      <Field
                        name="name"
                        component='input'
                        type='text'
                        disabled
                      />
                    </div>
                    <div>
                      <label>Yritys</label>
                      <Field
                        name="company"
                        component='input'
                        type='text'
                        disabled
                      />
                    </div>
                    <div>
                      <label>Työtehtävä</label>
                      <Field
                        name="workRole"
                        component='input'
                        type='text'
                        disabled
                      />
                    </div>
                    <div>
                      <label>Alkamispäivämäärä</label>
                      <Field
                        name="startDate"
                        component='input'
                        type="date"
                      />
                    </div>
                    <div>
                      <label>Päättymispäivämäärä</label>
                      <Field
                        name="endDate"
                        component='input'
                        type="date"
                      />
                    </div>
                    <div>
                      <label>Työtaidon arviointi</label>
                      <Field name='competenceRating' component='select'>
                        <option />
                        {data?.ratingCodes.map((x: any) => (
                          <option value={x.codeValue}>{x.prefLabel.fi}</option>
                        ))}
                      </Field>
                    </div>
                    <div>
                      <label>Käytöksen arviointi</label>
                      <Field name='behaviorRating' component='select'>
                        <option />
                        {data?.ratingCodes.map((x: any) => (
                          <option value={x.codeValue}>{x.prefLabel.fi}</option>
                        ))}
                      </Field>
                    </div>
                    <div>
                      <label>Päättymisen syy</label>
                      <Field name='terminationReason' component='select'>
                        <option />
                        {data?.employmentEndingCodes.map((x: any) => (
                          <option value={x.codeValue}>{x.prefLabel.fi}</option>
                        ))}
                      </Field>
                    </div>
                  </fieldset>
                )
            }
            {
              hideButtons
                ? null
                : (
                  <div className="buttons">
                    <Button type="submit" disabled={submitting || pristine}>
                      Lähetä
                    </Button>
                    {' '}
                    <ButtonLink type="button" onClick={onCancel}>
                      Peruuta
                    </ButtonLink>
                  </div>
                )
            }
          </form>
        )}
      />
    </FormStyles>
  );
};

export default EmploymentCertificateForm;
