/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useContext } from "react";
import { suomifiDesignTokens as sdt, Text } from "suomifi-ui-components";
import { FlexCol, FlexRow, Heading1WithBottomBorder, TableSmall } from "../common/CommonStyles";
import { AppContext } from "../../App";
import Card from "../common/Card";
import EducationCard from "./EducationCard";
import LiberalEducationCard from "./LiberalEducationCard";

const Dashboard: React.FC = () => {
  const [appState] = useContext(AppContext);
  const person = appState.person;

  return (
    <FlexCol>
      <div>
        <Heading1WithBottomBorder variant="h1" style={{ margin: `${sdt.spacing.l} 0 ${sdt.spacing.s}` }}>
          Osaaminen
          </Heading1WithBottomBorder>
        <Text variant="bold">{person.firstName} {person.lastName}</Text>
        <Text> - {person.address?.street}, {person.address?.zip} {person.address?.city}</Text>
      </div>

      <FlexRow css={{
        '& > *': {
          boxSizing: 'border-box',
          minHeight: "300px"
        }
      }}>
        <EducationCard />
      </FlexRow>

      <FlexRow css={{
        '& > *': {
          boxSizing: 'border-box',
          minHeight: "300px"
        }
      }}>
        <LiberalEducationCard />
        <Card
          title="Sertifikaatit"
          additionalInfo={{ text: "Katso tarkemmat tiedot", path: "/certificates" }}
        >
          <TableSmall>
            <tbody>
              <tr>
                <th>Sertifikaatti XYZ</th>
                <td className="right">11.3.2020</td>
              </tr>
              <tr>
                <th>Sertifikaatti ABC</th>
                <td className="right">9.5.2019</td>
              </tr>
            </tbody>
          </TableSmall>
        </Card>
      </FlexRow>

      <FlexRow css={{
        '& > *': {
          boxSizing: 'border-box',
          minHeight: "300px"
        }
      }}>
        <Card
          title="Osaamismerkit"
          additionalInfo={{ text: "Katso tarkemmat tiedot", path: "https://www.partio.fi/lippukunnille/pestien-tuki/osaaminen/osaamismerkit" }}
        >
          <TableSmall>
            <tbody>
              <tr>
                <th>International Experience Badge</th>
                <td className="right">11.3.2020</td>
              </tr>
              <tr>
                <th>Yhdistyksen sihteerin osaamismerkki</th>
                <td className="right">6.9.2011</td>
              </tr>
            </tbody>
          </TableSmall>
        </Card>
        <Card
          title="Pätevyydet"
          additionalInfo={{ text: "Katso tarkemmat tiedot", path: "/certificates" }}
        >
          <TableSmall>
            <tbody>
              <tr>
                <th>Laillistettu sosiaalihuollon ammattihenkilö</th>
                <td className="right">11.3.2020</td>
              </tr>
            </tbody>
          </TableSmall>
        </Card>
      </FlexRow>

      <FlexRow css={{
        '& > *': {
          boxSizing: 'border-box',
          minHeight: "300px"
        }
      }}>
        <Card
          title="Luvat"
          additionalInfo={{ text: "Katso tarkemmat tiedot", path: "/certificates" }}
        >
          <TableSmall>
            <tbody>
              <tr>
                <th>Lupa XYZ</th>
                <td className="right">11.3.2020</td>
              </tr>
              <tr>
                <th>Lupa ABC</th>
                <td className="right">9.5.2019</td>
              </tr>
            </tbody>
          </TableSmall>
        </Card>
        <Card
          title="Osaamiskartoitus"
          additionalInfo={{ text: "Katso tarkemmat tiedot", path: "/certificates" }}
        >
          <TableSmall>
            <tbody>
              <tr>
                <th>Viestintä, yhteistyö ja luovuus</th>
              </tr>
            </tbody>
          </TableSmall>
        </Card>
      </FlexRow>

      <br />
    </FlexCol>
  );
};

export default Dashboard;
