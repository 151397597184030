/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { TextField } from "@mui/material";
import { fraction } from "./model/fraction";
import * as _ from "lodash";

class FractionEditor extends React.Component<any, fraction> {
  constructor(props: any, data : any) {
      super(props);
      this.setState(new fraction());
  }

  getNum(): number { 
      if (this.state == undefined){
        this.setState(new fraction());
        return 0;
      }

      return this.state.numenator;
  }

  getDenom(): number { 
    if (this.state == undefined){
      this.setState(new fraction());
      return 0;
    }

    return this.state.denominator;
}

  handleChange(e: any) {
    if (e.target.value < 1){
        e.target.value = 1;
    }
    
    let change = this.state;
    _.set(change, e.target.name, e.target.value)
    this.setState(change)
    this.props.refreshProperties(this.state, this.props.entity);
  }

  render() {
    
      return (
            <div>
              <TextField type="number" style={{width: 100}} name="numenator" label="Jaettava" value={this.getNum()} onChange={this.handleChange.bind(this)} variant="outlined" />
              <label style={{fontSize: 35}}> / </label>
              <TextField type="number" style={{width: 100}} name="denominator" label="Jakaja" value={this.getDenom()} onChange={this.handleChange.bind(this)} variant="outlined" />
            </div>
      );
  }
}

export default FractionEditor;
