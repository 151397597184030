/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState } from "react";
import { Button, Link } from "suomifi-ui-components";
import Card from "../common/Card";
import { TableSmall } from "../common/CommonStyles";
import DataModal from "../common/DataModal";
import Spinner from "../common/Spinner";
import EmploymentCertificateForm from "../employment-certificate/EmploymentCertificateForm";

const EmployersCard: React.FC = () => {
  const [isEmploymentCertificateOpen, setIsEmploymentCertificateOpen] = useState(false)
  const isLoading = false
  const employmentCertificate = window.sessionStorage['cache:employmentCertificate'] || null
  return (
    <Card
      title="Työhistoria"
      additionalInfo={{ text: "Katso tarkemmat tiedot", path: "/employers" }}
    >
      {
        isLoading
          ? <Spinner style={{ height: "100%" }} />
          : (
            <TableSmall>
              <tbody>
                <tr>
                  <th>Digivero Oy</th>
                  <td className="right">5/2014 -</td>
                </tr>
                <tr className='nested-row'>
                  <th>
                    {
                      !employmentCertificate
                        ? null
                        : <React.Fragment>
                          <Link href="#" onClick={() => setIsEmploymentCertificateOpen(true)}>Työtodistus</Link>
                          &nbsp;|&nbsp;
                        </React.Fragment>
                    }
                    <Link href="#">Referenssit</Link>
                  </th>
                </tr>
                <tr>
                  <th>Auroraview</th>
                  <td className="right">1/2013 - 5/2014</td>
                </tr>
                <tr className='nested-row'>
                  <th><Link href="#">Työtodistus</Link> | <Link href="#">Suositukset</Link> | <Link href="#">Referenssit</Link></th>
                </tr>
                <tr>
                  <th>Lohjan yliopisto</th>
                  <td className="right">1/2008 - 12/2012</td>
                </tr>
                <tr className='nested-row'>
                  <th><Link href="#">Työtodistus</Link> | <Link href="#">Suositukset</Link> | <Link href="#">Referenssit</Link></th>
                </tr>
              </tbody>
            </TableSmall>
          )
      }
      <DataModal
        title='Työtodistus'
        isOpen={isEmploymentCertificateOpen}
      >
        <EmploymentCertificateForm
          hideButtons
          disabled
          initialValues={JSON.parse(employmentCertificate)}
        />
        <Button variant="secondaryNoBorder"
          icon={"close"}
          onClick={() => setIsEmploymentCertificateOpen(false)}>
          Sulje
        </Button>
      </DataModal>
    </Card>
  )
};

export default EmployersCard;
