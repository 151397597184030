import React, { useEffect, useState } from "react";

export function useWidth(ref: React.RefObject<HTMLElement>) {
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    setWidth(ref.current?.offsetWidth || 0);
  }, [ref]);

  return width;
}
