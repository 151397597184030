import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { Asset } from "../common/DomainTypes";
import { getAssets } from "./assetsApi";

export function useAssets() {
  const [appState] = useContext(AppContext);
  const person = appState.person;

  const [assets, setAssets] = useState<Asset[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getAssets(person.id)
    .then(parties => setAssets(parties))
    .finally(() => setLoading(false));
  }, [person]);

  return {assets, loadingAssets: loading};
}
