/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import Card from "../common/Card";
import { TableSmall } from "../common/CommonStyles";
import Spinner from "../common/Spinner";
import { useTransactionTotals } from "./useTransactionsTotals";

const IncomeTotalsCard: React.FC = () => {
  const { totals, isLoading } = useTransactionTotals();

  return (
    <Card
      title="Yhteenlasketut tulot"
      additionalInfo={{ text: "Katso tarkemmat tiedot", path: "/tulot" }}
    >
      {
      isLoading 
        ? <Spinner style={{ height: "100%" }} /> 
        : (
            <TableSmall>
              <tbody>
                <tr>
                  <th>6kk</th>
                  <td className="right">{totals?.sixMonths?.gross} €</td>
                </tr>
                <tr>
                  <th>12kk</th>
                  <td className="right">{totals?.twelveMonths?.gross} €</td>
                </tr>
              </tbody>
            </TableSmall>
        )
      }
    </Card>
  )
};

export default IncomeTotalsCard;
