import { Party, Person, Will, WillAsset } from "../common/DomainTypes";
import axios from "axios";

export const getWill = (personId: string): Promise<null | Will> => {
  return axios(`${process.env.REACT_APP_API_URL}/henkilo/${personId}/dokumentit/testamentti`)
  .then(res => res.data.Testamentti ? mapWill(res.data) : null);
};

export const postWill = (person: Person, will: Will): Promise<any> => {
  return axios.post(`${process.env.REACT_APP_API_URL}/henkilo/${person.id}/dokumentit/testamentti`, reverseMapWill(person, will));
};

const mapWill = (data: any): Will => {
  return {
    id: data.Testamentti.id,
    clause: data.Testamentti.Klausuuli,
    date: data.Testamentti.Laatimispaivamaara,
    assets: data.Testamentti.Varallisuusera
        ? data.Testamentti.Varallisuusera.map(mapWillAsset)
        : []
  };
};

const mapWillAsset = (data: any): WillAsset => {
  return {
    id: data.Id,
    name: data.Nimi,
    value: data.Arvo,
    count: data.Lukumaara,
    parties: data.Osapuoli ? data.Osapuoli.map(mapParty) : []
  };
};

const mapParty = (data: any): Party => {
  return {
    share: data.Osuus,
    person: mapPerson(data.Henkilo),
  };
};

const mapPerson = (data: any): Person => {
  return {
    id: data.Id,
    ssn: data.Henkilotunnus,
    firstName: data.Etunimi,
    lastName: data.Sukunimi,
    fullName: `${data.Etunimi} ${data.Sukunimi}`,
    primaryLanguage: "fi",
    nationality: "FIN",
    childCount: 0,
  };
};

const reverseMapWill = (person: Person, will: Will): any => {
  return {
    Testamentti: {
      id: will.id,
      Klausuuli: will.clause,
      Laatimispaivamaara: will.date,
      Henkilo: reverseMapPerson(person),
      Varallisuusera: will.assets.map(reverseMapAsset)
    }
  };
};

const reverseMapAsset = (asset: WillAsset): any => {
  return {
    Id: asset.id,
    Nimi: asset.name,
    Arvo: asset.value,
    Lukumaara: asset.count,
    Osapuoli: asset.parties.map(reverseMapParty),
  }
};

const reverseMapParty = (party: Party): any => {
  return {
    Osuus: party.share,
    Henkilo: reverseMapPerson(party.person),
  }
};

const reverseMapPerson = (person: Person): any => {
  return {
    Id: person.id,
    Henkilotunnus: person.ssn,
    Etunimi: person.firstName,
    Sukunimi: person.lastName,
  }
};
