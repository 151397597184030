import React from "react";
import { EventGridEvent } from "../../common/DomainTypes";
import { FlexCol, FlexRow, TableSmall } from "../../common/CommonStyles";
import { GenericEventPayload } from "../../forms/GenericFormTypes";
import { toDateString } from "../../../utils/dateUtils";

type Props = {
  event: EventGridEvent<GenericEventPayload>
}

const MarriageEvent: React.FC<Props> = ({event}: Props) => {
  return (
    <FlexCol>
      <FlexRow css={{
        "& > *": {
          boxSizing: "border-box",
          minHeight: "300px"
        }
      }}>
        <TableSmall>
          <tbody>
            { event.Data.eventData.eventTime && 
              <tr>
                <th>Tapahtuma-aika</th>
                <td className="right">
                  {toDateString(event.Data.eventData.eventTime)}
                </td>
              </tr>
            }
            { event.Data.person.relations && event.Data.person.relations[0] && 
              <tr>
                <th>Henkilötunnus</th>
                <td className="right">{event.Data.person.relations[0].personalIdentityCode}</td>
              </tr>
            }
            { event.Data.person.relations && event.Data.person.relations[1] && 
              <tr>
                <th>Puolison henkilötunnus</th>
                <td className="right">{event.Data.person.relations[1].personalIdentityCode}</td>
              </tr>
            }
          </tbody>
        </TableSmall>
      </FlexRow>
    </FlexCol>
  );
}

export default MarriageEvent;