/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import Card from "../common/Card";
import { TableSmall } from "../common/CommonStyles";
import Spinner from "../common/Spinner";
import { useLiberalEducation } from "./useLiberalEducation";

const LiberalEducationCard: React.FC = () => {
  const {courses, isLoading} = useLiberalEducation()
  return (
    <Card
      title="Vapaa sivistys"
      additionalInfo={{ text: "Katso tarkemmat tiedot", path: "/certificates" }}
    >
      {
        isLoading
          ? <Spinner style={{ height: "100%" }} />
          : (
            <TableSmall>
              <tbody>
                {
                  courses.map(course => (
                    <tr>
                      <th>{course.name}</th>
                      <td className="right">{course.completedOn}</td>
                    </tr>
                  ))
                }
              </tbody>
            </TableSmall>
          )
      }
    </Card>
  )
}

export default LiberalEducationCard;
