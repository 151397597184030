/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useContext } from "react";
import { suomifiDesignTokens as sdt, Text } from "suomifi-ui-components";
import { FlexCol, FlexRow, Heading1WithBottomBorder, TableSmall } from "../common/CommonStyles";
import { AppContext } from "../../App";
import IncomeCard from "./IncomeCard";
import IncomeTotalsCard from "../income/IncomeTotalsCard";
import EmployersCard from "./EmployersCard";
import Card from "../common/Card";

const Dashboard: React.FC = () => {
  const [appState] = useContext(AppContext);
  const person = appState.person;

  return (
    <FlexCol>
      <div>
        <Heading1WithBottomBorder variant="h1" style={{ margin: `${sdt.spacing.l} 0 ${sdt.spacing.s}` }}>
          Työelämä
          </Heading1WithBottomBorder>
        <Text variant="bold">{person.firstName} {person.lastName}</Text>
        <Text> - {person.address?.street}, {person.address?.zip} {person.address?.city}</Text>
      </div>

      <FlexRow css={{
        '& > *': {
          boxSizing: 'border-box',
          minHeight: "150px"
        }
      }}>
        <Card
          title="Tulot"
        >
          <TableSmall>
            <tbody>
              <tr>
                <th>Veroprosentti</th>
                <td className="right">23%</td>
              </tr>
              <tr>
                <th>Veronumero</th>
                <td className="right">100012345678</td>
              </tr>
            </tbody>
          </TableSmall>
        </Card>
      </FlexRow>

      <FlexRow css={{
        '& > *': {
          boxSizing: 'border-box',
          minHeight: "300px"
        }
      }}>
        <IncomeCard />
        <IncomeTotalsCard />
      </FlexRow>

      <FlexRow css={{
        '& > *': {
          boxSizing: 'border-box',
          minHeight: "300px"
        }
      }}>
        <EmployersCard />
      </FlexRow>

      <FlexRow css={{
        '& > *': {
          boxSizing: 'border-box',
          minHeight: "300px"
        }
      }}>
        <Card
          title="Yrityssidonnaisuudet"
          additionalInfo={{ text: "Katso tarkemmat tiedot", path: "/employers" }}
        >
          <TableSmall>
            <tbody>
              <tr>
                <th>Digivero Oy (perustaja/tj)</th>
                <td className="right">5/2014 -</td>
              </tr>
              <tr>
                <th>Verocast Oy (51% osakekannasta)</th>
                <td className="right">8/2019 -</td>
              </tr>
            </tbody>
          </TableSmall>
        </Card>
        <Card
          title="Hallitustyöskentely"
          additionalInfo={{ text: "Katso tarkemmat tiedot", path: "/employers" }}
        >
          <TableSmall>
            <tbody>
              <tr>
                <th>Digivero Oy (hallituksen pj)</th>
                <td className="right">5/2014 -</td>
              </tr>
            </tbody>
          </TableSmall>
        </Card>
      </FlexRow>

      <FlexRow css={{
        '& > *': {
          boxSizing: 'border-box',
          minHeight: "300px"
        }
      }}>
        <Card
          title="Luottamustehtävät"
          additionalInfo={{ text: "Katso tarkemmat tiedot", path: "/employers" }}
        >
          <TableSmall>
            <tbody>
              <tr>
                <th>Digivero Oy (perustaja/tj)</th>
                <td className="right">5/2014 -</td>
              </tr>
              <tr>
                <th>Verocast Oy (51% osakekannasta)</th>
                <td className="right">8/2019 -</td>
              </tr>
            </tbody>
          </TableSmall>
        </Card>
        <Card
          title="Luvat"
          additionalInfo={{ text: "Katso tarkemmat tiedot", path: "/employers" }}
        >
          <TableSmall>
            <tbody>
              <tr>
                <th>Lupa XYZ</th>
                <td className="right">19.2.2018</td>
              </tr>
              <tr>
                <th>Lupa ABC</th>
                <td className="right">11.4.2014</td>
              </tr>
            </tbody>
          </TableSmall>
        </Card>
      </FlexRow>
      <br />
    </FlexCol>
  );
};

export default Dashboard;
