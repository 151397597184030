import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { TreeNode } from "../common/Tree";
import { getFamilyTree } from "./familyTreeApi";

export function useFamilyTree() {
  const [appState] = useContext(AppContext);
  const person = appState.person;

  const [familyTree, setFamilyTree] = useState<TreeNode[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getFamilyTree(person.id)
    .then(parties => setFamilyTree(parties))
    .finally(() => setLoading(false));
  }, [person]);

  return {familyTree, loadingFamilyTree: loading};
}
