/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { Button, Heading, Text } from "suomifi-ui-components";
import { FlexColTight, HideOnSmallDisplay, TableSmall } from "../common/CommonStyles";
import {
  ButtonIconOnly,
  ButtonSecondaryDark,
  Input,
  InputNumber,
  InputRadio,
  TextArea
} from "../common/InputStyles";
import { suomifiDesignTokens as tokens } from "suomifi-design-tokens";
import { fractionToPercentage, percentageToFraction } from "../../utils/mathUtils";
import { currentIsoDate } from "../../utils/dateUtils";
import Modal from "react-modal";
import { Asset, Party, Person, WillAsset } from "../common/DomainTypes";
import { Heading1, Main, Toolbar } from "../common/DocumentStyles";
import { useWill } from "./useWill";
import { useAssets } from "../assets/useAssets";
import { useParties } from "../parties/useParties";

const createNewAsset = (): WillAsset => ({
  id: uuidv4(),
  name: "Uusi omaisuus",
  value: 0,
  count: 0,
  parties: []
});

const WillEdit: React.FC = () => {
  const navigate = useNavigate();

  const {will, setWill, saveWill} = useWill({
    id: uuidv4(),
    clause: "",
    date: currentIsoDate(),
    assets: []
  });

  const [isAssetModalOpen, setAssetModalOpen] = useState<boolean>(false);
  const [isAssetModalEditMode, setAssetModalEditMode] = useState<boolean>(false);
  const [modalAsset, setModalAsset] = useState<WillAsset>(createNewAsset());

  const updateClause = (clause: string) => {
    setWill(prevWill => ({...prevWill, clause}));
  };

  const insertAsset = (asset: WillAsset) => {
    setWill(prevWill => ({
      ...prevWill,
      assets: prevWill.assets.concat(asset)
    }));
  };

  const updateAsset = (asset: WillAsset) => {
    setWill(prevWill => ({
      ...prevWill,
      assets: prevWill.assets.map(a => a.id === asset.id ? asset : a)
    }));
  };

  const deleteAsset = (assetId: string) => {
    setWill(prevWill => ({
      ...prevWill,
      assets: prevWill.assets.filter(a => a.id !== assetId)
    }));
  };

  return (
      <div>
        <Toolbar>
          <Button
              icon={"save"}
              onClick={() => saveWill().then(() => navigate("/testamentti"))}>
            Tallenna
          </Button>
          <Button variant="secondaryNoBorder"
              icon={"close"}
              style={{background: "none"}}
              onClick={() => navigate("/testamentti")}>
            Sulje
          </Button>
        </Toolbar>

        <Main>
          <Heading1 variant="h1hero">
            Testamentti
          </Heading1>

          <label>
            Klausuuli
            <TextArea
                value={will.clause}
                onChange={(e) => updateClause(e.currentTarget.value)}
                style={{marginBottom: tokens.spacing.m}} rows={3}/>
          </label>

          <ol>
            {will.assets.map((asset, i) =>
                <AssetEditRow asset={asset}
                              updateAsset={updateAsset}
                              deleteAsset={deleteAsset}
                              key={i}/>)}
          </ol>

          <ButtonSecondaryDark
              variant="secondary"
              icon={"plus"}
              style={{margin: `${tokens.spacing.s} 0 ${tokens.spacing.l}`}}
              onClick={() => setAssetModalOpen(true)}>
            Testamenttaa lisää omaisuutta
          </ButtonSecondaryDark>

          <AssetEditModal
              isOpen={isAssetModalOpen}
              isEditMode={isAssetModalEditMode}
              setEditMode={setAssetModalEditMode}
              asset={modalAsset}
              setAsset={setModalAsset}
              onClickSave={() => {
                insertAsset(modalAsset);
                setModalAsset(createNewAsset());
                setAssetModalOpen(false);
                setAssetModalEditMode(false);
              }}
              onClickCancel={() => {
                setModalAsset(createNewAsset());
                setAssetModalOpen(false);
                setAssetModalEditMode(false);
              }}/>
        </Main>
        <br/>
      </div>
  );
};

interface AssetEditModalProps {
  isOpen: boolean,
  isEditMode: boolean,
  setEditMode: (isEdit: boolean) => void,
  asset: WillAsset,
  setAsset: (asset: WillAsset) => void,
  onClickSave: () => void,
  onClickCancel: () => void,
}

const AssetEditModal: React.FC<AssetEditModalProps> = (
    {isOpen, isEditMode, setEditMode, asset, setAsset, onClickSave, onClickCancel}) => {

  // existing assets
  const {assets} = useAssets();

  const updateAssetName = (name: string) => {
    setAsset({...asset, name});
  };

  const renderEditView = () => (
      <div>
        <label>
          Nimi
          <Input value={asset.name} onChange={(e) => updateAssetName(e.currentTarget.value)}/>
        </label>
      </div>
  );

  const renderSelectViewAssetRow = (a: Asset, i: number) => (
      <tr key={i} style={{
        background: a.name === asset.name ? tokens.colors.highlightLight1 : ""
      }}>
        <td>
          <label style={{display: "block", width: "100%", color: tokens.colors.highlightBase}}>
            <InputRadio
                name={"asset"}
                onChange={() => updateAssetName(a.name)}
                checked={a.name === asset.name}/>
            &nbsp;
            {a.name}
          </label>
        </td>
        <td className={"right"}>
          <ButtonIconOnly
              variant="secondaryNoBorder"
              icon={"edit"}
              style={{background: "none"}}
              onClick={() => {
                updateAssetName(a.name);
                setEditMode(true);
              }}/>
        </td>
      </tr>
  );

  const renderSelectView = () => (
      <div>
        Valitse omaisuus tai luo uusi omaisuusmerkintä
        <TableSmall style={{tableLayout: "initial"}}>
          <tbody>
          {assets.map(renderSelectViewAssetRow)}
          <tr>
            <td colSpan={2} style={{border: "none"}} className={"right"}>
              <Button variant="secondaryNoBorder"
                  iconRight={"plus"}
                  css={css`@media (max-width: 800px) { padding: ${tokens.spacing.s} }`}
                  onClick={() => setEditMode(true)}>
                Luo uusi omaisuusmerkintä
              </Button>
            </td>
          </tr>
          </tbody>
        </TableSmall>
      </div>
  );

  return <Modal isOpen={isOpen} style={{
    content: {
      top: "60px",
      left: "20px",
      right: "20px",
      margin: "0 auto",
      maxWidth: "600px",
      height: "500px",
      padding: tokens.spacing.l,
      border: `1px solid ${tokens.colors.depthLight3}`,
      borderRadius: "2px",
    }
  }} contentLabel="Omaisuus">
    <FlexColTight style={{height: "100%"}}>
      <Heading variant="h1">
        Omaisuus
      </Heading>

      {isEditMode ?
          renderEditView() :
          renderSelectView()}

      <div style={{marginTop: "auto"}}>
        <Button icon={"save"} onClick={onClickSave} style={{marginRight: tokens.spacing.s}}>
          Tallenna
        </Button>
        <Button variant="secondaryNoBorder" icon={"close"} onClick={onClickCancel}>
          Peruuta
        </Button>
      </div>
    </FlexColTight>
  </Modal>;
};

interface AssetEditRowProps {
  asset: WillAsset,
  updateAsset: (asset: WillAsset) => void,
  deleteAsset: (assetId: string) => void,
}

const AssetEditRow: React.FC<AssetEditRowProps> = ({asset, updateAsset, deleteAsset}) => {
  const createNewPerson = (): Person => {
    return {
      id: uuidv4(),
      ssn: "",
      firstName: "",
      lastName: "",
      fullName: "",
      primaryLanguage: "fi",
      nationality: "FIN",
      childCount: 0,
    };
  };

  const [isPersonModalOpen, setPersonModalOpen] = useState<boolean>(false);
  const [modalPerson, setModalPerson] = useState<Person>(createNewPerson());

  const [isAssetModalOpen, setAssetModalOpen] = useState<boolean>(false);
  const [modalAsset, setModalAsset] = useState<WillAsset>(asset);

  useEffect(() => {
    setModalAsset(asset);
  }, [asset]);

  const insertParty = (party: Party) => {
    updateAsset({
      ...asset,
      parties: asset.parties.concat(party)
    });
  };

  const updateParty = (party: Party) => {
    updateAsset({
      ...asset,
      parties: asset.parties.map(p => p.person.id === party.person.id ? party : p)
    });
  };

  const deleteParty = (personId: string) => {
    updateAsset({
      ...asset,
      parties: asset.parties.filter(p => p.person.id !== personId)
    });
  };

  return (
      <li>
        <Toolbar style={{borderBottom: `1px solid ${tokens.colors.depthLight3}`}}>
          <Text variant="bold">{asset.name}</Text>
          <div style={{display: "flex"}}>
            <ButtonIconOnly variant="secondaryNoBorder" icon={"edit"} onClick={() => setAssetModalOpen(true)}/>
            <ButtonIconOnly variant="secondaryNoBorder" icon={"remove"} onClick={() => deleteAsset(asset.id)}/>
          </div>
        </Toolbar>
        <ul style={{listStyleType: "none"}}>
          {asset.parties.map((party, j) =>
              <PartyEditRow party={party}
                            updateParty={updateParty}
                            deleteParty={deleteParty}
                            key={j}/>)}
        </ul>
        <ButtonSecondaryDark
            variant="secondary"
            icon={"plus"}
            style={{margin: `${tokens.spacing.s} 0 ${tokens.spacing.l}`}}
            onClick={() => setPersonModalOpen(true)}>
          Lisää perijä
        </ButtonSecondaryDark>

        <PersonEditModal
            isOpen={isPersonModalOpen}
            person={modalPerson}
            setPerson={setModalPerson}
            onClickSave={() => {
              insertParty({share: "0/1", person: modalPerson});
              setModalPerson(createNewPerson());
              setPersonModalOpen(false);
            }}
            onClickCancel={() => {
              setPersonModalOpen(false);
            }}/>

        <AssetEditModal
            isOpen={isAssetModalOpen}
            isEditMode={true}
            setEditMode={() => null}
            asset={modalAsset}
            setAsset={setModalAsset}
            onClickSave={() => {
              updateAsset(modalAsset);
              setAssetModalOpen(false);
            }}
            onClickCancel={() => {
              setAssetModalOpen(false);
            }}/>
      </li>
  );
};

interface PartyEditRowProps {
  party: Party,
  updateParty: (party: Party) => void,
  deleteParty: (personId: string) => void,
}

const PartyEditRow: React.FC<PartyEditRowProps> = ({party, updateParty, deleteParty}) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [modalPerson, setModalPerson] = useState<Person>(party.person);

  useEffect(() => {
    setModalPerson(party.person);
  }, [party.person]);

  const updateShare = (value: string) => {
    const percentage = value ? parseFloat(value) : 0;
    if (percentage >= 0 && percentage <= 100) {
      updateParty({
        ...party,
        share: percentageToFraction(percentage)
      });
    }
  };

  return (
      <li css={css`&:hover { background-color: ${tokens.colors.depthLight3}}`}>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <div>
            <InputNumber
                min="0" max="100"
                value={fractionToPercentage(party.share)}
                onChange={(e) => updateShare(e.currentTarget.value)}
            /> %
            &nbsp;
            <Text variant="bold">
              {party.person.firstName} {party.person.lastName}
            </Text> <HideOnSmallDisplay>({party.person.ssn})</HideOnSmallDisplay>
          </div>
          <div style={{display: "flex"}}>
            <ButtonIconOnly
                variant="secondaryNoBorder"
                icon={"edit"}
                style={{background: "none"}}
                onClick={() => setModalOpen(true)}/>
            <ButtonIconOnly
                variant="secondaryNoBorder"
                icon={"remove"}
                style={{background: "none"}}
                onClick={() => deleteParty(party.person.id)}/>
          </div>
        </div>
        <PersonEditModal
            isOpen={isModalOpen}
            person={modalPerson}
            setPerson={setModalPerson}
            onClickSave={() => {
              updateParty({...party, person: modalPerson});
              setModalOpen(false);
            }}
            onClickCancel={() => {
              setModalOpen(false);
            }}/>
      </li>
  );
};

interface PersonEditModalProps {
  isOpen: boolean,
  person: Person,
  setPerson: (person: Person) => void,
  onClickSave: () => void,
  onClickCancel: () => void,
}

const PersonEditModal: React.FC<PersonEditModalProps> = (
    {isOpen, person, setPerson, onClickSave, onClickCancel}) => {

  // known parties (parties to an estate - kuolinpesän osakkaat)
  const {parties} = useParties();

  const updateSsn = (ssn: string) => {
    const knownPerson = parties.filter(p => p.person.ssn === ssn)[0];

    if (knownPerson) {
      // keep the old id as it"s used to track changes
      setPerson({...knownPerson.person, id: person.id});
    } else {
      setPerson({...person, ssn});
    }
  };

  const updateFirstName = (firstName: string) => {
    setPerson({...person, firstName});
  };

  const updateLastName = (lastName: string) => {
    setPerson({...person, lastName});
  };

  return <Modal isOpen={isOpen} style={{
    content: {
      top: "60px",
      left: "20px",
      right: "20px",
      margin: "0 auto",
      maxWidth: "600px",
      height: "500px",
      padding: tokens.spacing.l,
      border: `1px solid ${tokens.colors.depthLight3}`,
      borderRadius: "2px",
    }
  }} contentLabel="Henkilö">
    <FlexColTight style={{height: "100%"}}>
      <Heading variant="h1">
        Henkilö
      </Heading>

      <label>
        Henkilötunnus
        <Input value={person.ssn}
               onChange={(e) => updateSsn(e.currentTarget.value)}/>
      </label>
      <label>
        Etunimi
        <Input value={person.firstName}
               onChange={(e) => updateFirstName(e.currentTarget.value)}/>
      </label>
      <label>
        Sukunimi
        <Input value={person.lastName}
               onChange={(e) => updateLastName(e.currentTarget.value)}/>
      </label>

      <div style={{marginTop: "auto"}}>
        <Button icon={"save"} onClick={onClickSave} style={{marginRight: tokens.spacing.s}}>
          Tallenna
        </Button>
        <Button variant="secondaryNoBorder" icon={"close"} onClick={onClickCancel}>
          Peruuta
        </Button>
      </div>
    </FlexColTight>
  </Modal>;
};

export default WillEdit;
