import { FormikProps } from "formik";
import { ObjectSchema } from "yup";
import { Relationship } from "../common/DomainTypes";

export interface GenericFormProps {
  defaultValues: any;
  onSubmit: (props: any) => Promise<any>;
  form: React.FC<FormikProps<any>>;
  validationSchema: ObjectSchema<any>;
}

export interface GenericFormParams {
  initialValues: object;
  validationSchema: ObjectSchema<any>;
  onSubmit: (props: any) => Promise<any>;
  setNotificationMessage: React.Dispatch<React.SetStateAction<string>>;
  setNotificationLevel: React.Dispatch<React.SetStateAction<string>>;
}

export enum GenericFormType {
  DEATH_REPORT = "death-report",
  CHILDS_BIRTH = "childs-birth",
  TERMINATION_OF_EMPLOYMENT = "termination-of-employment",
  MARRIAGE = "marriage",
  DIVORCE = "divorce",
  EMPLOYMENT = "employment",
  START_OF_STUDIES = "start-of-studies",
  RETIREMENT = "retirement",
  OTHER = "other",
}

export const CustomizedGenericFormTypes: string[] = [
  GenericFormType.DEATH_REPORT,
  GenericFormType.CHILDS_BIRTH,
  GenericFormType.TERMINATION_OF_EMPLOYMENT,
  GenericFormType.MARRIAGE
];

export const GenericFormTypeByValue = (value: string) => {
  const enumKey = Object.entries(GenericFormType)
    .filter( ([key, val]) => val === value)
    .map( ([key, val]) => key);
  if (enumKey.length > 0) {
    return GenericFormTypeTranslations[enumKey[0]] ? GenericFormTypeTranslations[enumKey[0]].fi : value
  }
  return value;
}

export const GenericFormTypeTranslations: {[key: string]: {fi: string}} = {
  DEATH_REPORT: {
    fi: "Kuoleman raportointi"
  },
  CHILDS_BIRTH: {
    fi: "Lapsen syntymä"
  },
  TERMINATION_OF_EMPLOYMENT: {
    fi: "Työsuhteen päättyminen"
  },
  MARRIAGE: {
    fi: "Avioliitto"
  },
  DIVORCE: {
    fi: "Avioero"
  },
  EMPLOYMENT: {
    fi: "Palkkatyön aloitus"
  },
  START_OF_STUDIES: {
    fi: "Opintojen aloitus"
  },
  RETIREMENT: {
    fi: "Eläkkeelle jääminen"
  },
  OTHER: {
    fi: "Muu"
  }
}

export interface GenericFormPayload {
  type: string,
  payload: any
}

export interface GenericEventPayload {
  person: Person;
  eventData: GenericEvent;
  notifier?: Notifier;
}

export interface Person {
  firstName?: string;
  lastName?: string;
  personalIdentityCode?: string;
  relations?: PersonRelation[]; 
}

export interface PersonRelation {
  personalIdentityCode?: string;
  role?: Relationship;
  description?: string;
}

export interface DeceasedPerson extends Person {
  dateOfDeath?: Date;
}

export interface GenericEvent {
  eventTime?: Date;
  description?: string;
  tapahtumatunnus?: string;
  viranomaiskoodi?: string;
  arkistonumero?: string;
}

export interface Notifier {
  id?: string;
  type?: NotifierType | "";
}

export enum NotifierType {
  SSN = "ssn",
  ORGANIZATION = "organization",
  DOCTOR = "doctor",
  OTHER = "muu",
}

export const NotifierTypeTranslations: {[key: string]: {fi: string}} = {
  SSN: {
    fi: "Sosiaaliturvatunnus"
  },
  ORGANIZATION: {
    fi: "Organisaatio/yritys"
  },
  DOCTOR: {
    fi: "Lääkäri"
  },
  OTHER: {
    fi: "Muu"
  }
}

export interface HospitalNotifier extends Notifier {
  sairaalanidtunnus?: string;
}

export interface OrganizationNotifier extends Notifier {
  organisaationidtunnus?: string;
}


