/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { FlexCol, FlexRow } from "../common/CommonStyles";
import { TextField } from "@mui/material";
import { contactPerson, decedentDetails, person } from "./model/person";
import _ from "lodash";

class PersonEditor extends React.Component<any, person> {
  constructor(props: any, data : any) {
      super(props);
      this.state = props.data;
      this.showPhone = props.data instanceof contactPerson;
      this.showSSN = props.data instanceof person;
      this.showDecedent = props.data instanceof decedentDetails;
  }

  showPhone : boolean = false;
  showSSN : boolean = false;
  showDecedent: boolean = false;

  handleChange(e: any) {
    let change = this.state;
    _.set(change, e.target.name, e.target.value)
    this.setState(change)
    this.props.refreshProperties(this.state, this.props.entity);
  }

  render() {
    
      return (
            <FlexCol>
              <FlexRow>
                <TextField name="name" label="Nimi" value={this.state.name} onChange={this.handleChange.bind(this)} variant="outlined" />
                {this.showSSN && <TextField name="personalId" label="Henkilötunnus" value={this.state.personalId} onChange={this.handleChange.bind(this)} variant="outlined" />}
                {this.showPhone && <TextField name="phoneNumber" label="Puhelin numero" value={(this.state as unknown as contactPerson).phoneNumber} onChange={this.handleChange.bind(this)} variant="outlined" />}
              </FlexRow>

              {this.showDecedent && 
              <FlexRow>
                <TextField type="date" InputLabelProps={{ shrink: true }} name="dateOfDeath" label="Kuolinpäivä" value={(this.state as decedentDetails).dateOfDeath} onChange={this.handleChange.bind(this)} variant="outlined" />
                <TextField type="date" InputLabelProps={{ shrink: true }} name="estateInventoryDate" label="Perunkirjoituspäivä" value={(this.state as decedentDetails).estateInventoryDate} onChange={this.handleChange.bind(this)} variant="outlined" />
              </FlexRow>}
            </FlexCol>
      );
  }
}

export default PersonEditor;
