/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useContext, useRef } from "react";
import { FlexColTight, panelWithShadowCss } from "../common/CommonStyles";
import { Heading, Link } from "suomifi-ui-components";
import Tree from "../common/Tree";
import { suomifiDesignTokens as sdt } from "suomifi-design-tokens";
import { AppContext } from "../../App";
import Spinner from "../common/Spinner";
import { useFamilyTree } from "./useFamilyTree";
import { useWidth } from "../common/useWidth";

const FamilyTreeWidget: React.FC = () => {
  const [appState] = useContext(AppContext);
  const person = appState.person;
  const {familyTree, loadingFamilyTree} = useFamilyTree();
  const ref = useRef<HTMLDivElement>(null);
  const width = useWidth(ref);

  // const exampleChildren = [
  //   {
  //     "name": "Jari",
  //     "children": [
  //       {
  //         "name": "Elias"
  //       },
  //       {
  //         "name": "Juhani"
  //       },
  //       {
  //         "name": "Simo"
  //       },
  //       {
  //         "name": "Jessika"
  //       }
  //     ]
  //   },
  //   {
  //     "name": "Sari",
  //     "children": [
  //       {
  //         "name": "Matias"
  //       }, {
  //         "name": "Sirkka"
  //       }
  //     ]
  //   },
  //   {
  //     "name": "Lauri"
  //   }
  // ];

  return (
      <FlexColTight css={panelWithShadowCss}>
        <Heading variant="h2" smallScreen>
          Sukulaisuussuhteet
        </Heading>

        {loadingFamilyTree ? <Spinner style={{height: "100%"}}/> :
            <div ref={ref}>
              <Tree data={{name: person.firstName, children: familyTree}} width={width}/>
            </div>
        }

        <div style={{marginTop: "auto"}}>
          <Link href="/family">
            <span style={{color: sdt.colors.accentBase}}>&rsaquo; </span>
            Katso tarkemmat sukulaisuustiedot
          </Link>
        </div>
      </FlexColTight>
  );
};

export default FamilyTreeWidget;
