/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { ButtonLinkSmall } from "./InputStyles";
import { AppContext } from "../../App";
import { NULL_PERSON } from "./DefaultValues";
import { logout } from "../../api/omaelama-functions";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const [appState, setAppState] = useContext(AppContext);
  const person = appState.person;

  return (
      <header>
        <div style={{
          display: 'flex',
          fontWeight: 600,
          justifyContent: "space-between",
        }}>
          <div style={{
            color: tokens.colors.brandBase,
            fontSize: '28px',
          }}>
            Omaelämä
          </div>

          <div style={{
            textAlign: "right",
            lineHeight: 1
          }}>
            {person === NULL_PERSON ? '' :
                <span>
                  {person.firstName} {person.lastName}
                  <br/>
                  <ButtonLinkSmall
                      style={{textTransform: "uppercase", lineHeight: 1.2}}
                      onClick={() => {
                        logout()
                        .then(res => {
                          setAppState(appState => ({...appState, person: NULL_PERSON}));
                          navigate('/kirjaudu');
                        });
                      }}>
                    Kirjaudu ulos
                  </ButtonLinkSmall>
                </span>
            }
          </div>
        </div>
      </header>
  );
};

export default Header;
