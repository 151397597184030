import React, { PropsWithChildren } from "react";
import Modal from "react-modal";
import { Heading, suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { FlexColTight } from "../common/CommonStyles";

interface Props {
  isOpen: boolean,
  title: string
}

const DataModal: React.FC<PropsWithChildren<Props>> = ({ isOpen, title, children }) => (
  <Modal isOpen={isOpen} contentLabel="Luo uusi työtodistus" style={{
    content: {
      height: "80%",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 800,
      padding: tokens.spacing.l,
    }
  }}>
    <FlexColTight style={{ height: "100%" }}>
      <Heading variant="h1">
        {title}
      </Heading>
      <hr />
      {children}
    </FlexColTight>
  </Modal>
)

export default DataModal