/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import Card from "../common/Card";
import { TableSmall } from "../common/CommonStyles";
import Spinner from "../common/Spinner";
import { useTransactions } from "./useTransactions";

const IncomeCard: React.FC = () => {
  const { groupedTransactions, isLoading } = useTransactions()
  return (
    <Card
      title="Viimeisimmät tulot"
      additionalInfo={{ text: "Katso tarkemmat tiedot", path: "/tulot" }}
    >
      {
        isLoading 
        ? <Spinner style={{ height: "100%" }} /> 
        : (
          <TableSmall>
            <tbody>
              {groupedTransactions?.byMonth.map((item, i) =>
                  <tr key={i}>
                      <th>{item.month}</th>
                      <td className="right">+{item.total} €</td>
                  </tr>
              )}
            </tbody>
          </TableSmall>
        )
      }
    </Card>
  )
};

export default IncomeCard;
